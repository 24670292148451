import { action, makeObservable, observable } from 'mobx';
import { orderDetAPI, orderDetModAPI, orderStateModAPI } from '../api/receive';

import { ERROR_505_MSG } from '../lib/message';

export default class Receive {
  root;
  alert;
  orderDet = null;

  constructor(root) {
    this.root = root;
    this.alert = this.root.alertStore;
    makeObservable(this, {
      orderDet: observable,
      orderDetSel: action,
      receiveModCall: action,
    })
  }

  orderDetInit = () => {
    this.orderDet = null;
  }

  orderDetSel = async(id) => {
    try {
      const resp = await orderDetAPI(id);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.orderDet = data;
      
    } catch (error) {
      console.log("##### Error-orderDetSel: ", error);
    }
  }

  receiveModCall = (params) => {
    const { alertStore } = this.root;

    for (const [key, value] of Object.entries(params)) {
      if (value === "" || value === null) {
        params[key] = null;
      }
    }

    alertStore.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtntitle: '저장',
      title: '접수정보를 저장하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await orderDetModAPI(params);
          const { status } = resp;
          if (status !== 200) throw resp;

          alertStore.defaultAlertClose();
        } catch (error) {
          alertStore.defaultAlertClose();
          this.alert.showAlert(ERROR_505_MSG.title, ERROR_505_MSG.content);
          console.log('%c##### Error-receiveModeCall: ', 'color: red', error);
        }
        
      }
    });
  }

  receiveStateModCall = async (params) => {
    const { alertStore } = this.root;

    alertStore.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtnTitle: '변경',
      title: '접수상태를 변경하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await orderStateModAPI(params);
          const { status } = resp;
          if (status !== 200) throw resp;

          alertStore.defaultAlertClose();
        } catch (error) {
          alertStore.defaultAlertClose();
          this.alert.showAlert(ERROR_505_MSG.title, ERROR_505_MSG.content);
          console.log('%c##### Error-receiveStateModCall: ', 'color: red', error);
        }

      }
    })
  }


}