export const ERROR_505_MSG = {
  title: "요청하신 서비스가 정상적으로 처리되지 않았습니다.",
  content: "일시적 오류 또는 업데이트 중입니다.<br/>빠른 시간 내에 정상 서비스 이용이 가능하도록 최선을 다하겠습니다."
}

export const CARDS = [
  { title: "삼성카드", value: "Samsung"},
  { title: "농협", value: "NH" },
]

export const TEST_TOCKE = "eyJhbGciOiJIUzUxMiJ9.eyJuYW1lIjoi7ZmN6ri464-ZIiwibWFuYWdlcktleSI6MSwidHlwZSI6Ik0iLCJpYXQiOjE2MTA5Mjg5Mjh9.ngmJkr13Fh0J87c1xzjtCYipKKa8zXHm3ePbLe8ofy9172Rqslr_OWqTd-s-TraplKPrB5ph4VwFviQhOLlQew";