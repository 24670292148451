import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';

import { Title } from '../components/common';
import ManagerList from '../components/CompanyDetPage/ManagerList';
import CompanyRegForm from '../components/CompanyRegPage/CompanyRegForm';

const CompanyDetPage = ({ match, cmStore }) => {
  const { companyManagerSelCall, companyDetSel } = cmStore;
  const [id, setId] = useState("");

  useEffect(() => {
    try {
      const { id } = match.params;
      setId(id);
      companyManagerSelCall(id);
      companyDetSel(id);
    } catch (error) {
      console.log('%c##### Error-CompanyDetPage: ', 'color: red', error);
    }
  }, []);


  return (
    <React.Fragment>
      <Title title="기업상세정보"/>
      <CompanyRegForm type="det"/>
      <ManagerList id={id} />
    </React.Fragment>
  );
};

export default inject('cmStore')(CompanyDetPage);