import { action, makeObservable, observable } from 'mobx';
import { ERROR_505_MSG } from '../lib/message';
import { 
  companyManagerSel, companyReg, memberSel,
  memberDetSel, companySel, companySearchAPI,
  memberUseAPI, companyUserRegAPI, compnayUserDetAPI,
  compnayUserModAPI,
  compnayUserDelAPI,
  companyDetAPI,
} from '../api/customerManagement';
import { EmailRegExp, PhoneRegExp } from '../lib/util';

export default class CustomerManagement {
  root;
  alert;

  cmParams = {page:1, size: 20};
  mbParams = {page:1, size: 20};
  mbuParams = {page:1, size: 20};
  memberList = { content: [], total: 0 };
  compParams = {page:1, size: 20};
  companyList = { content: [], total: 0 };
  companySearch = [];
  memberUse = { content: [], total: 0};
  companyDet = null;
  memberData = null;
  companyUserData = null;
  thePersonInCharge = { content: [], total: 0 };

  constructor(root) {
    this.root = root;
    this.alert = this.root.alertStore;
    makeObservable(this, {
      memberList: observable,
      memberUse: observable,
      memberData: observable,
      companyList: observable,
      companySearch: observable,
      thePersonInCharge: observable,
      companyUserData: observable,
      companyDet: observable,
      companyManagerRegCall: action,
      companySearchSel: action,
      memberSelCall: action,
      memberDetSelCall: action,
      companySelCall: action,
      companyManagerSelCall: action,
    })
  }

  memberSelCall = async(params) => {

    const p = {
      ...this.mbParams,
      ...params,
    }

    try {
      const resp = await memberSel(p);
      const { data, status } = resp;
      console.log('%c##### debug-resp: ', 'color: #058FD7', resp);
      if (status !== 200) throw status;
      this.memberList = { content: data.content, total: data.totalElements};
    } catch (error) {
      console.log('%c##### Error-memberSel: ', 'color: red', error);
    }
  }

  // 회원상세
  memberDetSelCall = async(id) => {
    try {
      const resp = await memberDetSel(id);
      const { data, status } = resp;
      if (status !== 200) throw status;

      this.memberData = data;
    } catch (error) {
      console.log('%c##### Error-memberDetSelCall: ', 'color: red', error);
    }
  }

  // 회원 서비스 이용
  memberUseSel = async (id, params) => {
    const p = {
      ...this.mbuParams,
      ...params
    }
    try {
      const resp = await memberUseAPI(p, id);
      const { status, data } = resp;

      if (status !== 200) throw resp;
      this.memberUse = { content: data.content, total: data.totalElements };

    } catch (error) {
      console.log('%c##### Error-memberUseSel: ', 'color: red', error);
    }
  }

  // 기업검색
  companySearchSel = async(params) => {

    try {
      const resp = await companySearchAPI(params);
      const { status, data } = resp;
      if (status !== 200) throw resp;
      console.log({data});
      this.companySearch = data;
    } catch (error) {
      console.log('%c##### Error-companySearchSel: ', 'color: red', error);
    }
  }

  // 기업 조회
  companySelCall = async (params) => {
    const p = {
      ...this.compParams,
      ...params
    }
    try {
      const resp = await companySel(p);
      const { data, status } = resp;
      if (status !== 200) throw status;
      
      this.companyList = { content: data.content, total: data.totalElements };
    } catch (error) {
      console.log('%c##### Error-companySelCall: ', 'color: red', error);
    }
  }

  // 기업담당자 등록
  companyManagerRegCall = async (params, history) => {

    let msg = "";
    if (!params.companyKey) msg = "기업을를 선택하세요.";
    else if (!params.name) msg = "이름을 입력하세요.";
    else if (!params.userId) msg = "아이디를 입력하세요.";
    else if (!params.password) msg = "비밀번호을 입력하세요.";
    else if (!params.email) msg = "이메일을 입력하세요.";
    else if (!EmailRegExp(params.email)) msg = "이메일을 정확히 입력하세요."
    // else if (!PhoneRegExp(params.extensionNumber)) msg = "내선번호를 정확히 입력하세요."
    
    if (msg) {
      this.alert.showAlert(msg);
      return;
    }


    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtntitle: '등록',
      title: '기업담당자를 등록하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await companyUserRegAPI(params);
          const { status } = resp;

          this.alert.defaultAlertClose();
          if (status !== 201) throw resp;
          
          history.push('/member');
        } catch (error) {
          if (error && error.data) {
            this.alert.showAlert(error.data.message);
          }
          console.log("#### companyUserRegAPI error: ", error);
        }
      }
    });
  }

  // 기업상세
  companyDetSel = async (id) => {
    try {
      const resp = await companyDetAPI(id);
      const { status, data } = resp;
      if (status !== 200) throw resp;
      this.companyDet = data;
    } catch (error) {
      
    }
  }
  

  // 기업담당자 계정 수정
  compnayUserModCall = (params) => {

    let msg = "";
    if (!params.name) msg = "이름을 입력하세요.";
    else if (!params.userId) msg = "아이디를 입력하세요.";
    else if (!params.password) msg = "비밀번호을 입력하세요.";
    else if (!params.email) msg = "이메일을 입력하세요.";
    else if (!EmailRegExp(params.email)) msg = "이메일을 정확히 입력하세요.";
    else if (!PhoneRegExp(params.extensionNumber)) msg = "내선번호를 정확히 입력하세요.";

    
    if (msg) {
      this.alert.showAlert(msg);
      return;
    }

    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtntitle: '삭제',
      title: '기업담당자를 수정하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await compnayUserModAPI(params);
          const { status } = resp;
          if (status !== 201) throw resp;
          this.alert.defaultAlertClose();
        } catch (error) {
          console.log("#### compnayUserModAPI error: ", error);
        }
      }
    });
  }

  // 기업담당자 계정삭제
  companyManagerDelCall = (id, history) => {

    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtntitle: '삭제',
      title: '기업담당자를 삭제하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await compnayUserDelAPI(id);
          const { status } = resp;
          if (status !== 200) throw resp;
          this.alert.defaultAlertClose();
          history.push('/member');
        } catch (error) {
          console.log("#### compnayUserModAPI error: ", error);
        }
      }
    });
  }

  // 기업담당자 조회
  companyManagerSelCall = async (id, params) => {
    const p = {
      ...this.cmParams,
      ...params,
    }

    try {
      const resp = await companyManagerSel(id, p);
      const { status, data } = resp;
      if (status !== 200) throw status;
      this.thePersonInCharge = { content: data.content, total: data.totalElements };
    } catch (error) {
      this.alert.showAlert(ERROR_505_MSG.title, ERROR_505_MSG.content);
      console.log('%c##### Error-companyManagerSelCall: ', 'color: red', error);
    }
  }

  compnayUserDetSel = async (id) => {
    try {
      const resp = await compnayUserDetAPI(id);
      const { status, data } = resp;
      if (status !== 200) throw resp;
      this.companyUserData = data;
    } catch (error) {
      console.log(error);
    }
  }

  // 기업 등록
  companyRegCall = (params, history) => {
    let msg = "";
    console.log({ params });
    if (!params.companyType) msg = "기업형태를 선택하세요.";
    else if (!params.companyName) msg = "기업명 이름을 입력하세요.";
    else if (!params.phone) msg = "대표번호를 입력하세요.";
    else if (!params.representativeName) msg = "대표자명을 입력하세요.";
    else if (!params.businessNumber) msg = "사업자번호를 입력하세요.";
    else if (!params.address) msg = "주소를 입력하세요.";
    else if (!EmailRegExp(params.email)) msg = "이메일을 정확히 입력하세요.";
    // else if (!PhoneRegExp(params.representativeName)) msg = "대표자번호를 정확히 입력하세요.";

    
    if (msg) {
      this.alert.showAlert(msg);
      return;
    }

    
    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtntitle: '등록',
      title: '기업을 등록하시겠습니까?',
      handleOk: async () => {
        try {
          this.alert.defaultAlertClose();
          const resp = await companyReg(params);
          const { status } = resp;
          if (status !== 201) throw status;

          history.push('/company');

        } catch (error) {
          this.alert.showAlert(ERROR_505_MSG.title, ERROR_505_MSG.content);
          console.log('%c##### Error-companyRegCall: ', 'color: red', error);
        }
      }
    })
  }

} 