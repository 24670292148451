import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Dialog, DialogTitle, Typography, Button, Grid,
  IconButton, DialogContent, DialogActions, TextField, FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { BtnCloseDialog } from '../../static/images';
import { CustomSelectbox } from '../common/input';
import { text } from '../../lib/util';

function BannerDialog({ open, type, bannerKey, handleClose, commonStore, bannerStore }) {
  const history = useHistory();
  const { fileUpload } = commonStore;
  const { bannerRegSel, bannerModSel, bannerDetSel, bannerData, bannerDelSel } = bannerStore;
  const [data, setData] = useState({});
  const [fileName, setFileName] = useState("");
  const [fileNameM, setFileNameM] = useState("");

  const classes = useStyles();
  const isReg = (type === 'reg');

  useEffect(() => {
    if (type === 'reg') {
      return;
    }

    if (!bannerData) return;
    setData(bannerData);
    setFileName(text(bannerData.imageUrl))
    setFileNameM(text(bannerData.imageMobileUrl))
  }, [bannerData])

  useEffect(() => {
    if (bannerKey) {
      bannerDetSel(bannerKey);
    }
  }, [bannerKey])
  
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value
    })
  }

  const handleFile = async (e) => {
    e.preventDefault();
    const name = e.target.name;
    console.log({ name });
    if (!e.target.files || e.target.files.length === 0) return;
    const file = e.target.files[0];

    try {
      const formData = new FormData();
      formData.append('sourceFile', file);

      const result = await fileUpload(formData);

      if (result) {
        if (name === "web") {
          setFileName(file.name);
          data["imageUrl"] = result;
        } else {
          setFileNameM(file.name);
          data["imageMobileUrl"] = result;
        }
      } else {
        if (name === "web") {
          setFileName("");
          data["imageUrl"] = "";
        } else {
          setFileNameM("");
          data["imageMobileUrl"] = "";
        }
        
      }

    } catch (error) {
      console.log('%c##### Error-handleFile: ', 'color: red', error);
    }
  }

  const handleLinkDel = () => {
    setData({
      ...data,
      "linkUrl": '',
    });
  }

  const handleDelBtn = (id) => {
    bannerDelSel(id, handleClose);
  }

  const onSubmit = () => {
    if (isReg) bannerRegSel(data, handleClose);
    else bannerModSel(data, handleClose);
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="dialogTitle"
    >
      <DialogTitle id="dialogTitle" disableTypography>
        <Typography component="h2" variant="h1">
          {isReg ? '배너등록' : '배너 상세정보'}
        </Typography>
        <IconButton
          aria-label="close"
          className="closeButton"
          onClick={handleClose}
        >
          <img src={BtnCloseDialog} alt="닫기" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography component="h2" variant="h2">등록정보</Typography>
        <Grid container className="listSearch">
          <Grid item>
            <Grid container direction="row" alignItems="center" className="schFormGroup">
              <Grid item>
                <Typography className="formLabel">노출순서</Typography>
              </Grid>
              {/* <Grid item>
                <FormControl>
                  <CustomSelectbox inputProps={{ 'title': '노출순서 선택' }}>
                    <option>선택</option>
                    <option>1</option>
                  </CustomSelectbox>
                </FormControl>
              </Grid> */}
              <Grid item>
                <Typography className="formLabel">상태</Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <CustomSelectbox 
                    inputProps={{ 'title': '상태 선택' }}
                    name="useYn"
                    onChange={handleChange}
                    value={text(data.useYn)}
                  >
                    <option value="">선택</option>
                    <option value="Y">노출</option>
                    <option value="N">대기</option>
                  </CustomSelectbox>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="listSearch">
          <Grid item>
            <Grid container direction="row" alignItems="center" className="schFormGroup">
              <Grid item>
                <Typography className="formLabel">배너명&nbsp;&nbsp;&nbsp;</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  placeholder="이미지 등록"
                  className={classes.w500}
                  name="title"
                  onChange={handleChange}
                  value={text(data.title)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="listSearch">
          <Grid item>
            <Grid container direction="row" alignItems="flex-start" className="schFormGroup">
              <Grid item>
                <Typography className="formLabel">이미지&nbsp;&nbsp;&nbsp;</Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <div className={classes.formFileUpload}>
                    <input 
                      type="file" 
                      id="fileUpload"
                      name="web"
                      title="GA조직 파일 업로드" 
                      className={classes.inputFile} 
                      onChange={handleFile}
                    />{/* 단계에 따라 title 값 변경 바랍니다 */}
                    <TextField
                      aria-hidden="true"
                      placeholder="웹페이지 권장 사이즈는 OOOxOOO 입니다."
                      InputProps={{
                        readOnly: true,
                        classes: { notchedOutline: 'outline' },
                        inputProps: { tabIndex: -1 },
                      }}
                      style={{ width: 330 }}
                      value={fileName}
                    />
                    <label htmlFor="fileUpload" className={classes.btnFile}>이미지 등록</label>
                  </div>
                  <div className={classes.formFileUpload} style={{marginTop: 10}}>
                    <input 
                      type="file" 
                      id="fileUpload2"
                      name="mobile"
                      title="GA조직 파일 업로드" 
                      className={classes.inputFile} 
                      onChange={handleFile}
                    />{/* 단계에 따라 title 값 변경 바랍니다 */}
                    <TextField
                      aria-hidden="true"
                      placeholder="모바일 권장 사이즈는 OOOxOOO 입니다."
                      InputProps={{
                        readOnly: true,
                        classes: { notchedOutline: 'outline' },
                        inputProps: { tabIndex: -1 },
                      }}
                      style={{ width: 330 }}
                      value={fileNameM}
                    />
                    <label htmlFor="fileUpload2" className={classes.btnFile}>이미지 등록</label>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="listSearch">
          <Grid item>
            <Grid container direction="row" alignItems="center" className="schFormGroup">
              <Grid item>
                <Typography className="formLabel">링크주소</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  placeholder="주소를 입력하세요"
                  className={classes.w306}
                  name="linkUrl"
                  value={text(data.linkUrl)}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  style={{ width: 76, marginLeft: 12 }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleLinkDel}
                >
                  삭제
              </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className="pageMainButtons">
          {!isReg && <Button variant="contained" color="secondary" onClick={() => handleDelBtn(data.bannerKey)}>삭제</Button>}
          <Button variant="contained" color="primary" onClick={onSubmit}>
            { isReg ? "등록" : "변경" }
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
const useStyles = makeStyles((theme) => ({
  w500: {
    '& input': {
      width: '400px !important',
    }
  },
  w306: {
    '& input': {
      width: '306px !important',
    }
  },
  formFileUpload: {
    position: 'relative',
    width: '100%'
  },
  inputFile: {
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: 330,
    height: 32,
    opacity: 0,
    padding: 0,
    zIndex: 10,
    background: 'pink',
    boxSizing: 'border-box',
    '&:focus': {
      '& ~ .MuiTextField-root .outline': {
        borderColor: theme.palette.border[300],
      },
      '& ~ label': {
        backgroundColor: theme.palette.bgColor[400],
      }
    }
  },
  btnFile: {
    display: 'inline-block',
    width: 76,
    height: 32,
    // marginLeft: 10,
    textAlign: 'center',
    paddingTop: 8,
    border: `1px solid ${theme.palette.border[100]}`,
    borderRadius: 2,
    backgroundColor: theme.palette.bgColor[200],
    lineHeight: 1,
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}));

export default inject("commonStore", "bannerStore")(observer(BannerDialog));