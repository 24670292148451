import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogActions, Box, Button } from '@material-ui/core';

function DefaultAlert({ alertStore }) {
  const classes = useStyles();
  const { defaultAlert, defaultAlertClose } = alertStore;
  const { useEffect } = React;
  const { cancel = false, open = false, leftBtnTitle = '취소', rightBtnTitle = '확인', content, title, handleOk } = defaultAlert;

  useEffect(() => {
    return () => defaultAlertClose();
  }, []);
  
  const close = () => {
    if (handleOk) {
      handleOk();
    }
    defaultAlertClose();
  }
  return (
    <React.Fragment>
      <Dialog open={open} classes={{ paper: classes.alertPaper }} className={classes.alertDialog}>
        <DialogContent className={classes.alertContent}>
          <span className={classes.icoAlert}>
            <span className={classes.bubble}></span>
            <span className={classes.tail}></span>
          </span>
          <DialogContentText color="textPrimary" className={classes.text}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </DialogContentText>
          {
            content && (
              <div className={classes.subDescArea}>
                <DialogContentText color="textPrimary" className={classes.subDesc}>
                  <span dangerouslySetInnerHTML={{ __html: content }} />
                  {/* [이전 결과보기] 버튼을 클릭하시면 이전 보장분석 결과를 확인할 수 있습니다.<br /><br />
                  [보장분석 다시 하기] 버튼 클릭 시 새로 보장분석을 진행합니다.<br />보장분석을 위해 '내보험다보여' 계정이 필요합니다. */}
                </DialogContentText>
              </div>
            )
          }
        </DialogContent>
        {
          cancel
            ? (
              <DialogActions>
                <Box className="pageMainButtons">
                  <Button variant="contained" color="secondary" className={classes.button} onClick={defaultAlertClose}>
                    {leftBtnTitle}
                  </Button>
                  <Button variant="contained" color="primary" className={classes.button} onClick={handleOk}>
                    {rightBtnTitle}
                  </Button>
                </Box>
              </DialogActions>
            )
            : (
              <DialogActions>
                <Box className="pageMainButtons">
                  <Button variant="contained" color="primary" className={classes.button} onClick={close}>
                    {leftBtnTitle}
                  </Button>
                </Box>
              </DialogActions>
            )
        }
      </Dialog>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  alertPaper: {
    width: 420,
    padding: '40px 30px',
    border: `1px solid ${theme.palette.tertiary.main}`,
    borderRadius: 4,
    boxShadow: '0 0 7px rgba(0, 0, 0, 0.15)',
  },
  alertContent: {
    paddingBottom: 15,
    textAlign: 'center',
    '&:first-child': {
      paddingTop: 0,
    }
  },
  text: {
    marginBottom: 0,
    fontWeight: 700,
    fontSize: 16,
  },
  subDescArea: {
    marginTop: 30,
    textAlign: 'left',
    '& p:last-child': {
      marginBottom: 0,
    },
  },
  button: {
    minWidth: 100,
  },

  icoAlert: {
    display: 'block',
    position: 'relative',
    marginBottom: 22,
    paddingBottom: 5,
    fontSize: 0,
    lineHeight: 0,
    textAlign: 'center',
    '&:before, &:after': {
      display: 'block',
      position: 'absolute',
      left: '50%',
      borderRadius: 1,
      background: theme.palette.primary.main,
      content: '""',
      transform: 'translateX(-50%)',
    },
    '&:before': {
      top: 6,
      width: 3,
      height: 6,
    },
    '&:after': {
      top: 14,
      width: 3,
      height: 3,
    },
  },
  bubble: {
    display: 'inline-block',
    width: 29,
    height: 23,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
    opacity: 0.5,
  },
  tail: {
    display: 'block',
    position: 'absolute',
    left: '50%',
    bottom: 0,
    borderLeft: '7px solid #fff',
    borderBottom: '7px solid transparent',
    transform: 'translateX(-50%)',
    '&:before, &:after': {
      display: 'block',
      position: 'absolute',
      content: '""',
    },
    '&:before': {
      left: 0,
      top: 0,
      borderLeft: `7px solid ${theme.palette.primary.main}`,
      borderBottom: '7px solid transparent',
      opacity: 0.5,
      transform: 'translateX(-100%)',
    },
    '&:after': {
      left: -6,
      top: 0,
      borderBottom: '4px solid transparent',
      borderLeft: '4px solid #fff',
    },
  },
}));

export default inject('alertStore')(observer(DefaultAlert));
