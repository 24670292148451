import React from 'react';
import { Grid, Typography } from '@material-ui/core';

function Title({ title }) {
  return (
    <Grid container justify="space-between" alignItems="center" className="titleArea">
      <Grid item><Typography variant="h1">{title}</Typography></Grid>
      {/* <Grid item className="buttonArea">
        <ButtonDownXls>관리자 목록 엑셀 다운로드</ButtonDownXls>
      </Grid> */}
    </Grid>
  );
}

export default Title;