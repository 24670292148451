import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { Pagination } from '../common';
import { codeTitle, pagingRowNum } from '../../lib/util';

function CompanyList({ history, cmStore }) {
  const {
    compParams: params,
    companyList: list,
    companySelCall,
  } = cmStore;

  const handleClick = (id) => {
    history.push(`/company/${id}`);
  };

  const handlePaging = (value, name) => {
    if (name === 'page') params.page = value;
    else if (name === 'size') params.size = value;

    companySelCall(params);
  }


  return (
    <React.Fragment>
      <Table className="bbsListTable">
        <caption>GA 관리 목록</caption>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '*%' }} />
        </colgroup>
        <TableHead className="bbsListTableHead">
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">구분</TableCell>
            <TableCell align="center">기업명</TableCell>
            <TableCell align="center">사업자등록번호</TableCell>
            <TableCell align="center">대표번호</TableCell>
            <TableCell align="center">대표자명</TableCell>
            <TableCell align="center">결제방식</TableCell>
            <TableCell align="center">상세정보</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.content.length > 0 
              ? (
                list.content.map((item, index) => (
                  <TableRow key={item.companyKey}>
                    <TableCell align="center">{pagingRowNum(list.total, params.size, params.page, index)}</TableCell>
                    <TableCell align="center">{codeTitle("companyType", item.companyType)}</TableCell>
                    <TableCell align="center">{item.companyName}</TableCell>
                    <TableCell align="center">{item.businessNumber}</TableCell>
                    <TableCell align="center">{item.phone}</TableCell>
                    <TableCell align="center">{item.representativeName}</TableCell>
                    <TableCell align="center">{codeTitle("companyPaymentType", item.companyPaymentType)}</TableCell>
                    <TableCell align="center">
                      <Button
                        fullWidth
                        style={{ width: 200 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleClick(item.companyKey)}
                      >
                        자세히
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )
              : (
                <TableRow>
                  <TableCell colSpan={8} align="center">조회결과가 없습니다.</TableCell>
                </TableRow>
              )
          }
        </TableBody>
      </Table>
      <Pagination
        total={list.total}
        page={params.page}
        rowPage={params.size}
        handlePage={handlePaging}
      />
    </React.Fragment>
  );
}

export default inject('cmStore')(withRouter(observer(CompanyList)));