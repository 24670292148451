import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { inject } from 'mobx-react';

import { Title } from '../components/common';
import SearchForm from '../components/ChargeSelPage/SearchForm';
import ButtonAddIcon from '../components/common/button/ButtonAddIcon';
import ChargeSelList from '../components/ChargeSelPage/ChargeSelList';

function ChargeSelPage({ history, chargeStore }) {
  const { priceOptionsSel, priceListSel } = chargeStore;

  useEffect(() => {
    priceOptionsSel();
    
  }, []);

  return (
    <React.Fragment>
      <Title title="요금조회"/>
      <SearchForm />
    </React.Fragment>
  );
}

export default inject("chargeStore")(ChargeSelPage);