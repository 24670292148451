import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Button, ButtonGroup, Grid, Typography } from '@material-ui/core';

import Calendar from '../common/calendar/Calendar';
import { ButtonSearchList } from '../common/button';

function SearchForm({ commonStore, oprtnStore }) {
  const params = {};
  const [month, setMonth] = useState('1d');
  const { setFromPastToPresent } = commonStore;
  const { visitListSel } = oprtnStore;

  useEffect(() => {
    setFromPastToPresent('1d')
    return(() => {
      setFromPastToPresent('1M')
    })
  }, [])

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    params[name] = value;
  };

  const onSubmit = () => {
    visitListSel(params);
  }

  const handleClick = (value) => {
    setMonth(value);
    setFromPastToPresent(value);
  }

  return (
    <React.Fragment>
      <div className="listSearch">
        <Grid container direction="row" alignItems="center" className="schFormGroup">
          <Grid item>
            <Typography className="formLabel">조회기간</Typography>
          </Grid>
          <Grid item>
            <Calendar name="sDate" />
          </Grid>
          <Grid item className="formGap">~</Grid>
          <Grid item>
            <Calendar name="eDate" />
          </Grid>
          <Grid item>
            <ButtonSearchList className="btnSchList" onClick={onSubmit} />
          </Grid>
          <Grid item>
            <ButtonGroup variant="outlined" className="rounded btnSchListGroup">
              <Button
                className={month === '1M' && 'on'}
                onClick={() => handleClick('1d')}
              >일</Button>
              <Button
                className={month === '1M' && 'on'}
                onClick={() => handleClick('1w')}
              >주</Button>
              <Button
                className={month === '1M' && 'on'}
                onClick={() => handleClick('1M')}
              >월</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default inject('oprtnStore', 'commonStore')(observer(SearchForm));