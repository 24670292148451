import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { Title, TitleButtons } from '../components/common';
import ChargeSelList from '../components/ChargeSelPage/ChargeSelList';
import { Grid } from '@material-ui/core';
import ButtonAddIcon from '../components/common/button/ButtonAddIcon';
import FileUploadForm from '../components/ChargeDetPage/FileUploadForm';

function PriceModPage({ chargeStore, history }) {
  const { priceListSel } = chargeStore;

  useEffect(() => {
    priceListSel();
  }, []);

  const handleClick = (path) => {
    history.push(path);
  };

  const priceExcelDown = () => {
    window.location.href = "http://quinors-env.eba-grz63hu5.ap-northeast-2.elasticbeanstalk.com/v1/admin/download/excel?Authorization=eyJhbGciOiJIUzUxMiJ9.eyJuYW1lIjoi7ZmN6ri464-ZIiwibWFuYWdlcktleSI6MSwidHlwZSI6Ik0iLCJpYXQiOjE2MTExMzI1OTd9.3XwCgjAzkdQT9WBIU4bbOcwKYvRqrEbWTSb9XNVykWUGubIuSTUykMqT4fh8YV_5DpHfAgNHfI7qINGQf8DIag"
  }

  return (
    <React.Fragment>
      <Title title="요금조회" />
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ padding: '10px 0' }}
      >
        <TitleButtons title="구간 요금수정">
          <ButtonAddIcon
            color="primary"
            style={{ width: 200 }}
            onClick={priceExcelDown}
          >다운로드</ButtonAddIcon>
          <ButtonAddIcon
            color="primary"
            style={{ width: 200 }}
            onClick={() => handleClick('/option')}
          >옵션 요금설정</ButtonAddIcon>
        </TitleButtons>
        <FileUploadForm />
      </Grid>
      <ChargeSelList />
    </React.Fragment>
  );
}

export default inject('chargeStore')(PriceModPage);