import { InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ButtonCalendar } from '../button';

import ko from 'date-fns/locale/ko';

function CalendarTime({ handleChange, value, name, isDate }) {
  const [startDate, setStartDate] = useState(value);

  useEffect(() => {
    setStartDate(value);
  }, [value]);

  const handleTime = (date) => {
    setStartDate(date);
    handleChange({target: { name, value: date}})
  };


  return (
    <ReactDatePicker
      locale={ko}
      selected={startDate}
      dateFormat="yyyy-MM-dd HH:mm"
      onChange={(date) => handleTime(date)}
      showTimeInput
      timeInputLabel="Time:"
      customInput={
        <TextField
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ButtonCalendar disabled={isDate} />
              </InputAdornment>
            ),
            inputProps: { 'title': '시작일 입력' },
          }}
        // defaultValue="2019.08.10"
        />
      }
    />
  );
}

export default CalendarTime;