import React from 'react';
import { Typography, Grid } from '@material-ui/core';

function TitleButtons({ title, children }) {

  return (
    <Grid container justify="space-between" alignItems="center" className="titleArea">
      <Grid item><Typography variant="h1">{title}</Typography></Grid>
      <Grid item className="buttonArea">
        {children}
      </Grid>
    </Grid>
  );
}

export default TitleButtons;