import { MainLayout, LoginLayout } from '../components/layouts';
import { 
  LoginPage, 
  OperateStatePage, ReceiveDetPage, 
  MemberPage, MemberDetPage,
  CompanyManagerRegPage, CompanyRegPage,
  CompanyPage, CompanyDetPage,
  DeliveryHistoryPage,
  ChargeSelPage, ChargeDetPage, OptionPayPage,
  ManagementOfStaffPage, BannerPage,
  VisitorSelPage, PriceModPage
} from '../pages';
import CompanyManagerRegForm from '../components/CompanyManagerRegPage/CompanyManagerRegForm';

export default [
  { path: `/login`, layout: LoginLayout, component: LoginPage, exact: true },
  { path: `/operatestate`, layout: MainLayout, component: OperateStatePage, exact: true },
  { path: `/receive/:id`, layout: MainLayout, component: ReceiveDetPage, exact: true },
  { path: `/member`, layout: MainLayout, component: MemberPage, exact: true },
  { path: `/member/:id`, layout: MainLayout, component: MemberDetPage, exact: true },
  { path: `/companymanagerreg`, layout: MainLayout, component: CompanyManagerRegPage, exact: true },
  { path: `/companyreg`, layout: MainLayout, component: CompanyRegPage, exact: true },
  { path: `/company`, layout: MainLayout, component: CompanyPage, exact: true },
  { path: `/company/:id`, layout: MainLayout, component: CompanyDetPage, exact: true },
  { path: `/manager/:id`, layout: MainLayout, component: CompanyManagerRegForm, exact: true },
  { path: `/deliberyhistory`, layout: MainLayout, component: DeliveryHistoryPage, exact: true },
  { path: `/deliberydet/:id`, layout: MainLayout, component: ReceiveDetPage, exact: true },
  { path: `/chargesel`, layout: MainLayout, component: ChargeSelPage, exact: true },
  { path: `/chargedet`, layout: MainLayout, component: ChargeDetPage, exact: true },
  { path: `/option`, layout: MainLayout, component: OptionPayPage, exact: true },
  { path: `/mangementofstaff`, layout: MainLayout, component: ManagementOfStaffPage, exact: true },
  { path: `/banner`, layout: MainLayout, component: BannerPage, exact: true },
  { path: `/visitor`, layout: MainLayout, component: VisitorSelPage, exact: true },
  { path: `/pricemod`, layout: MainLayout, component: PriceModPage, exact: true },
];
