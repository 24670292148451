import { makeObservable, action, observable } from 'mobx';
import { deliveryListAPI, useVisitListAPI as visitListAPI } from '../api/oprtnMgmt';

export default class OprtnMgmt {
  root;
  common;
  deliveryList = {content: [], total: 0};
  dParams = { 
    page: 1,
    size: 10,
  };
  visitList = {content: [], total: 0};
  vParams ={
    page: 1,
    size: 10,
  }

  constructor(root) {
    this.root = root;
    this.common = this.root.commonStore;
    makeObservable(
      this, {
        deliveryList: observable,
        dParams: observable,
        visitList: observable,
        vParams: observable,
      }
    )
  }

  deliveryListSel = async(params) => {
    const p = {
      ...this.dParams,
      ...params,
      startDate: new Date(this.common.sDate).getTime(),
      endDate: new Date(this.common.eDate).getTime()
    };

    try {
      const resp = await deliveryListAPI(p);
      const { status, data } = resp;

      if (status !== 200) throw resp;
      this.deliveryList = { content: data.content, total: data.totalElements };
    } catch (error) {
      console.log(error);
    }
  }

  visitListSel = async(params) => {
    const p = {
      ...this.vParams,
      ...params,
      startDate: new Date(this.common.sDate).getTime(),
      endDate: new Date(this.common.eDate).getTime()
    };

    try {
      const resp = await visitListAPI(p);
      const { status, data } = resp;

      if (status !== 200) throw resp;
      this.visitList = { content: data.content, total: data.totalElements };
    } catch (error) {
      console.log(error);
    }
  }
}