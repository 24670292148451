import React from 'react';
import { Button } from '@material-ui/core';
import { IconCalendar, IconCalendarDisabled } from '../../../static/images';

const ButtonCalendar = props => {
  const { disabled } = props;
  return (
    <Button
      disabled={(disabled ? true : false)}
      aria-label="날짜선택"
    >
      { disabled === true ? <img src={IconCalendarDisabled} alt="" /> : <img src={IconCalendar} alt="" />}
    </Button>
  );
};

export default ButtonCalendar;