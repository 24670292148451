import React, { useEffect } from 'react';
import {
  Box, Button, Typography,
  Dialog, DialogTitle, DialogContent,
  DialogActions, IconButton
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import CompanyList from './CompanyList';
import { BtnCloseDialog } from '../../../../static/images';

function CompanySelDialog({ open, handleClose, cmStore }) {
  const params = {};
  const { companySearchSel } = cmStore;

  useEffect(() => {
    if (open) companySearchSel({q: "-1"});
  }, [open])

  return (
    <React.Fragment>
      <Dialog 
        fullWidth
        maxWidth="sm"
        open={open} 
        onClose={handleClose}
        aria-labelledby="dialogTitle" 
      >
        <DialogTitle id="dialogTitle" disableTypography>
          <Typography component="h2" variant="h1">기업 검색</Typography>
          <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
            <img src={BtnCloseDialog} alt="닫기" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CompanyList handleClose={handleClose}/>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default inject('cmStore')(observer(CompanySelDialog));