import { action, makeObservable, observable } from 'mobx';
import { bannerListAPI, bannerDetAPI, bannerRegAPI, bannerModAPI, bannerDelAPI } from '../api/banner';

export default class Banner {
  root;
  alert;
  showAlert;

  bParams = { page: 1, size: 20 };
  bannerList = { content: [], total: 0 };
  bannerData = null;

  constructor(root) {
    this.root = root;
    this.alert = this.root.alertStore;
    this.showAlert = this.root.alertStore.showAlert;
    makeObservable(this, {
      bannerList: observable,
      bannerData: observable
    });
  }

  bannerListSel = async (params) => {
    const p = {
      ...this.bParams,
      ...params
    }
    try {
      const resp = await bannerListAPI(p);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.bannerList = { content: data.content, total: data.totalElements };
    } catch (error) {
      console.log('%c##### Error-bannerListSel: ', 'color: red', error);
    }
  }

  bannerDetSel = async (id) => {
    try {
      const resp = await bannerDetAPI(id);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.bannerData = data;

    } catch (error) {
      console.log('%c##### Error-bannerDetSel: ', 'color: red', error);
    }
  }
  
  bannerModSel = async (data, func) => {
    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtnTitle: '수정',
      title: '수정 하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await bannerModAPI(data);
          const { status } = resp;
          if (status !== 200) throw resp;
          this.bannerListSel();
          this.alert.defaultAlertClose();
          func();
        } catch (error) {
          console.log('%c##### Error-bannerModSel: ', 'color: red', error);
        }
      }
    });
  }

  bannerRegSel = async (data, func) => {
    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtnTitle: '등록',
      title: '등록 하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await bannerRegAPI(data);
          const { status } = resp;
          if (status !== 200) throw resp;
          this.bannerListSel();
          this.alert.defaultAlertClose();
          func();
        } catch (error) {
          console.log('%c##### Error-bannerModSel: ', 'color: red', error);
        }
      }
    });
  }

  bannerDelSel = async (id, func) => {
    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtnTitle: '삭제',
      title: '삭제 하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await bannerDelAPI(id);
          const { status } = resp;
          if (status !== 200) throw resp;
          this.bannerListSel();
          this.alert.defaultAlertClose();
          func();
        } catch (error) {
          console.log('%c##### Error-bannerModSel: ', 'color: red', error);
        }
      }
    });
  }

}