import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import { inject } from 'mobx-react';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { BtnCloseDialog } from '../../../static/images';

function DaumPostcodeDialog({ name, open, handleClose, handleChange, commonStore }) {
  const { setDaumAddress } = commonStore;

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let bcode = data.bcode; // 법정동코드
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    
    // 공통 store 안쓰고 직접 주소를 받을 때
    if (name && handleChange) {
      handleChange(name, fullAddress, bcode);
    } else {
      setDaumAddress(fullAddress);
    }

    handleClose();
  }

  return (
    <React.Fragment>
      <Dialog aria-labelledby="dialogTitle" open={open} onClose={handleClose}>
        <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
          <img src={BtnCloseDialog} alt="닫기" />
        </IconButton>
        <DialogContent>
          <DaumPostcode
            onComplete={handleComplete}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default inject('commonStore')(DaumPostcodeDialog);