import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { 
  Box, Typography, Table, TableHead, TableBody, TextField, InputAdornment,
  TableRow, TableCell, Button, FormControl, FormControlLabel, Grid 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { CustomCheckbox } from '../components/common/input';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.option-input': {
      background: '#ffffff'
    }
  }
}));

function OptionPayPage({history, chargeStore }) {
  useStyles();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);
  const [isChecked, setChecked] = useState({ isNationalHoliday: 'N', isSnowRain: 'N' });
  const { priceOptionsSel, priceOptions, priceOptionsModCall, activationCall, activationModCall, activation } = chargeStore;

  useEffect(() => {
    priceOptionsSel();
    activationCall();
  }, [])

  useEffect(() => {
    if (!priceOptions) return;
    setData(priceOptions);
  }, [priceOptions]);

  useEffect(() => {
    setChecked(activation);
  }, [activation]);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    data[name] = [value, data[name][1]];
    console.log({ data });
    setData({
      ...data
    });
    
    const findIdx = _.findIndex(options, (o) => o.cd === name);
    
    if (findIdx > -1) {
      options[findIdx] = { cd: name, value };
    } else {
      options.push({ cd: name, value });
    }
  }

  const handleChecked  = (e, checked) => {
    const name = e.target.name;
    const temp = {
      ...isChecked,
      [name]: checked ? 'Y' : 'N'
    }

    setChecked(temp);
    activationModCall(temp);
  }

  const onSubmit = () => {
    console.log('%c##### debug: ', 'color: #058FD7', { options });
    priceOptionsModCall({ options })
  }
  if (!data) return null;

  return (
    <React.Fragment>
      <Typography variant="h1">옵션 요금수정</Typography>
      <Box className="section">
        <Typography component="h2" variant="h2">결제 옵션</Typography>
        <Table className="dataTable horizontal">
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '*%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">조조</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined" 
                    name="Morning"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.Morning[0]}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">1박</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    name="LateNight2"
                    onChange={handleChange}
                    value={data.LateNight2[0]}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">야간</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    name="Night1"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.Night1[0]}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">
                명절
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox
                    name="isNationalHoliday"
                    checked={isChecked.isNationalHoliday === 'Y'}
                    onChange={handleChecked}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    name="NationalHoliday"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.NationalHoliday[0]}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">심야</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.LateNight1[0]}
                    name="LateNight1"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">
                할증(눈비 등)
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox 
                    name="isSnowRain"
                    checked={isChecked.isSnowRain === 'Y'}
                    onChange={handleChecked}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    name="SnowRain"
                    onChange={handleChange}
                    value={data.SnowRain[0]}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">휴일</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.Holiday[0]}
                    name="Holiday"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th"></TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">급송</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.Express1[0]}
                    name="Express1"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th"></TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box className="section">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography component="h2" variant="h2">차량옵션</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="h2" variant="h2">추가옵션</Typography>
          </Grid>
        </Grid>
        <Table className="dataTable horizontal">
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '*%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">리프트</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.Lift[0]}
                    name="Lift"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">차량동승(명)</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.Passenger[0]}
                    name="Passenger"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">탑</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    value={data.Top[0]}
                    name="Top"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">인부추가(명)</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.Worker[0]}
                    name="Worker"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">원탑</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    name="OneTop"
                    value={data.OneTop[0]}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">기사 혼자 상하차</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.Alone[0]}
                    name="Alone"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">냉동탑</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.FreezingTower[0]}
                    name="FreezingTower"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">기사 함께 상하차</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    name="WithC"
                    value={data.WithC[0]}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">장축</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    name="LongAxis"
                    value={data.LongAxis[0]}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">계단이용</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    className="option-input"
                    placeholder=""
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+</InputAdornment>,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                    }}
                    value={data.Stairs[0]}
                    name="Stairs"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box className="pageMainButtons">
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >저장</Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            history.goBack();
          }}
        >뒤로</Button>
        </Box>
    </React.Fragment>
  );
}

export default inject('chargeStore')(observer(OptionPayPage));