import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Table, TableHead, TableRow, TableCell, TableBody, FormControl, Divider } from '@material-ui/core';
import { CustomCheckbox } from '../common/input';
import { Pagination } from '../common';
import { pagingRowNum } from '../../lib/util';

function VisitorList({ oprtnStore }) {
  const { visitListSel, vParams: params, visitList: list  } = oprtnStore;

  const handlePaging = (value, name) => {
    if (name === 'page') params.page = value;
    else if (name === 'size') params.size = value;

    visitListSel(params);
  }

  return (
    <React.Fragment>
      <Divider style={{ margin: '20px 0' }} />
      <Table className="bbsListTable">
        <caption>GA 관리 목록</caption>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '5%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
        </colgroup>
        <TableHead className="bbsListTableHead">
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">회원구분</TableCell>
            <TableCell align="center">접속일시</TableCell>
            <TableCell align="center">기업회원</TableCell>
            <TableCell align="center">기업명</TableCell>
            <TableCell align="center">아이디</TableCell>
            <TableCell align="center">회원명</TableCell>
            <TableCell align="center">연락처</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.content.length > 0
              ? (
                list.content.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {pagingRowNum(list.total, params.size, params.page, index)}
                    </TableCell>
                    <TableCell align="center">
                      {
                        item.isUser === 'Y' ? "회원" : "비회원"
                      }
                    </TableCell>
                    <TableCell align="center">
                      {moment(item.createDate).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                    <TableCell align="center">
                      <FormControl className="tableFormControl checkbox">
                        <CustomCheckbox
                          disabled
                          checked={item.isCompanyUser === 'Y'}
                          className="invert"
                          aria-label="선택"
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">{item.companyName}</TableCell>
                    <TableCell align="center">{item.userId}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.phone}</TableCell>
                  </TableRow>
                ))
              )
              : (
                <TableRow>
                  <TableCell colSpan={8} align="center">조회결과가 없습니다.</TableCell>
                </TableRow>
              )
          }
        </TableBody>
      </Table>
      <Pagination
        total={list.total}
        page={params.page}
        rowPage={params.size}
        handlePage={handlePaging}
      />
    </React.Fragment>
  );
}

export default inject('oprtnStore')(observer(VisitorList));
