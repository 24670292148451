import React, { useState } from 'react';
import moment from 'moment';
import { Box, Table, TableBody, Typography, TableRow, TableCell, FormControl, TextField } from '@material-ui/core';

import { CustomCheckbox } from '../common/input';
import { text } from '../../lib/util';

function AddInfo({ orderDet }) {
  const [data, setData] = useState(orderDet);
  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">추가요청</Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '12%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">
                하차 예약
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox 
                    checked={data && data.reservationDownTime != 0}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={
                      data && data.reservationDownTime != 0
                        ? moment(new Date(data.reservationDownTime)).format('YYYY-MM-DD HH:mm')
                        : ""
                    }
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">요청사항</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={text(data.requests)}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
}

export default AddInfo;
