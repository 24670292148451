import { action, makeObservable, observable } from 'mobx';
import { ERROR_505_MSG } from '../lib/message';
import { adminSel, adminReg, adminMod, adminDet } from '../api/admin';

export default class Admin {
  root;
  alert;
  showAlert;

  adminParams = { page:1, size: 20 };
  adminList = { content: [], total: 0 };
  adminData = null;

  constructor(root) {
    this.root = root;
    this.alert = this.root.alertStore;
    this.showAlert = this.root.alertStore.showAlert;
    makeObservable(this, {
      adminList: observable,
      adminData: observable,
      adminSelCall: action,
    });
  }

  // 관리자 조회
  adminSelCall = async(params) => {
    this.adminParams = {
      ...this.adminParams,
      ...params
    }

    try {
      const resp = await adminSel(this.adminParams);
      const { data, status } = resp;
      if (status !== 200) throw resp;

      this.adminList = { content: data.content, total: data.totalElements };
    } catch (error) {
      console.log('%c##### Error-adminSelCall: ', 'color: red', error);
    }
  }

  // 관리자 조회
  adminDetCall = async (id) => {

    try {
      const resp = await adminDet(id);
      const { data, status } = resp;
      if (status !== 200) throw resp;

      this.adminData = data;
    } catch (error) {
      console.log('%c##### Error-adminDetCall: ', 'color: red', error);
    }
  }

  // 관리자 등록
  adminRegCall = async (params, func) => {
    if (!params.adminId) {
      this.showAlert('아이디를 입력하세요');
      return;
    } else if (!params.password) {
      this.showAlert('비밀번호를 입력하세요.');
      return;
    }

    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtnTitle: '등록',
      title: '관리자를 등록하시겠습니까?',
      handleOk: async() => {
        console.log('%c##### debug-params: ', 'color: #058FD7', params);
        try {
          const resp = await adminReg(params);
          const { data, status } = resp;
          if (status !== 201) throw resp;
          this.adminSelCall();
          this.alert.defaultAlertClose();
          func();
        } catch (error) {
          console.log('%c##### Error-adminRegCall: ', 'color: red', error);
        }
      }
    })
  }

  // 관리자 수정
  adminModCall = async (params, func) => {

    if (!params.adminId) {
      this.showAlert('아이디를 입력하세요');
      return;
    } 

    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtnTitle: '수정',
      title: '관리자를 수정하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await adminMod(params);
          const { data, status } = resp;
          if (status !== 200) throw resp;
          this.adminSelCall()
          this.alert.defaultAlertClose();
          func();
        } catch (error) {
          console.log('%c##### Error-adminRegCall: ', 'color: red', error);
        }
      }
    })
  }
}