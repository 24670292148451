import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { Pagination } from '../common';
import { pagingRowNum } from '../../lib/util';

function StaffList({ handleDialog, adminStore }) {
  const {
    adminParams: params,
    adminList: list,
    adminSelCall
  } = adminStore;

  React.useEffect(() => {
    console.log('%c##### debug-params: ', 'color: #058FD7', params);
  }, [])


  const handleClick = (id) => {
    handleDialog(id);
  };

  const handlePaging = (value, name) => {
    if (name === 'page') params.page = value;
    else if (name === 'size') params.size = value;

    adminSelCall(params);
  }


  return (
    <React.Fragment>
      <Table className="bbsListTable">
        <caption>GA 관리 목록</caption>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '*%' }} />
        </colgroup>
        <TableHead className="bbsListTableHead">
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">아이디</TableCell>
            <TableCell align="center">이름</TableCell>
            <TableCell align="center">직급</TableCell>
            <TableCell align="center">등록일</TableCell>
            <TableCell align="center">연락처</TableCell>
            <TableCell align="center">상세정보</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.content.length > 0
              ? (
                list.content.map((item, index) => (
                  <TableRow key={item.adminKey}>
                    <TableCell align="center">{pagingRowNum(list.total, params.size, params.page, index)}</TableCell>
                    <TableCell align="center">{item.adminId}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.position}</TableCell>
                    <TableCell align="center">
                      {moment(item.createdDate).format('YYYY-MM-DD')}
                    </TableCell>
                    <TableCell align="center">{ item.phone }</TableCell>
                    <TableCell align="center">
                      <Button
                        fullWidth
                        style={{ width: 200 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleClick(item.adminKey)}
                      >
                        자세히
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )
              : (
                <TableRow>
                  <TableCell colSpan={7} align="center">조회결과가 없습니다.</TableCell>
                </TableRow>
              )
          }
          
        </TableBody>
      </Table>
      <Pagination
        total={list.total}
        page={params.page}
        rowPage={params.size}
        handlePage={handlePaging}
      />
    </React.Fragment>
  );
}

export default inject('adminStore')(observer(StaffList));