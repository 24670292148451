import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, Typography } from '@material-ui/core';

function FileUploadForm({ chargeStore }) {
  const { priceExcelUploadCall } = chargeStore
  const classes = useStyles();
  const [fileName, setFileName] = useState("");

  const handleFile = async (e) => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length === 0) return;
    const file = e.target.files[0];

    try {
      const formData = new FormData();
      formData.append('sourceFile', file);

      const result = await priceExcelUploadCall(formData);

      if (result) {
        setFileName(file.name);
      } else {
        setFileName("");
      }


    } catch (error) {
      console.log('%c##### Error-handleFile: ', 'color: red', error);
    }
  }

  return (
    <Grid container className="listSearch">
      <Grid container direction="row" alignItems="center" className="schFormGroup">
        <Grid item>
          <Typography className="formLabel">파일 업로드</Typography>
        </Grid>
        <Grid item>
          <div>
            <input 
              type="file" 
              id="fileUpload" 
              title="GA조직 파일 업로드" 
              className={classes.inputFile} 
              onChange={handleFile}
            />{/* 단계에 따라 title 값 변경 바랍니다 */}
            <TextField
              aria-hidden="true"
              InputProps={{
                readOnly: true,
                classes: { notchedOutline: 'outline' },
                inputProps: { tabIndex: -1 },
              }}
              style={{ width: 240 }}
            />
            <label htmlFor="fileUpload" className={classes.btnFile}>파일찾기</label>
          </div>
        </Grid>
      </Grid>
    </Grid>
    
  );
}

const useStyles = makeStyles((theme) => ({
  inputFile: {
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: 240,
    height: 32,
    opacity: 0,
    padding: 0,
    zIndex: 10,
    background: 'pink',
    boxSizing: 'border-box',
    '&:focus': {
      '& ~ .MuiTextField-root .outline': {
        borderColor: theme.palette.border[300],
      },
      '& ~ label': {
        backgroundColor: theme.palette.bgColor[400],
      }
    }
  },
  btnFile: {
    textAlign: 'center',
    display: 'inline-block',
    width: 76,
    height: 32,
    marginLeft: 10,
    paddingTop: 8,
    border: `1px solid ${theme.palette.border[100]}`,
    borderRadius: 2,
    backgroundColor: theme.palette.bgColor[200],
    lineHeight: 1,
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}));

export default inject('chargeStore')(FileUploadForm);