import React, { useState, useCallback, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { 
  Box, Table, TableBody, Typography, 
  TableRow, TableCell, FormControl, 
  TextField, InputAdornment } from '@material-ui/core';

import { CustomCheckbox, CustomSelectbox } from '../common/input';
import SearchIcon from '@material-ui/icons/Search';
import ButtonSearchList from '../common/button/ButtonSearchList';
import CompanySelDialog from '../common/dialog/Company/CompanySelDialog';
import { text } from '../../lib/util';

function PaymentInfo({ orderDet, handleParams, commonStore }) {
  const { codeList, companyName, companyId } = commonStore;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(orderDet);

  useEffect(() => {
    if (!orderDet) return;
    setData(orderDet);

  }, [orderDet]);

  useEffect(() => {
    setData((old) => ({
      ...old,
      "companyId": companyId
    }));
  }, [companyId])

  const handleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleChange = (e, checked) => {
    let value = e.target.value;
    let name = e.target.name;


    setData((old) => ({
      ...old,
      [name]: value
    }));

    handleParams({
      [name]: value
    })
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">결제정보</Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '12%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">결제상태</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      inputProps={{ 'title': '결제상태 선택' }}
                      name="paymentStatus"
                      value={text(data.paymentStatus)}
                      onChange={handleChange}
                    >
                      <option value="">선택</option>
                      {
                        codeList && (
                          codeList.paymentStatus.map((item) => (
                            <option key={item.code} value={item.code}>{item.title}</option>
                          ))
                        )
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">결제수단</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      inputProps={{ 'title': '결제상태 선택' }}
                      name="paymentType"
                      value={text(data.paymentType)}
                      onChange={handleChange}
                    >
                      <option>선택</option>
                      {
                        codeList && (
                          codeList.paymentType.map((item) => (
                            <option key={item.code} value={item.code}>{item.title}</option>
                          ))
                        )
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
              {/* <TableCell scope="row" variant="head" component="th">
                법인후불
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox />
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  placeholder="기업을 검색하세요"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"><SearchIcon /></InputAdornment>
                    )
                  }}
                  onClick={handleDialog}
                  value={companyName}
                  className="searchKeyword"
                />
              </TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">결제금액</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="totalValue"
                    value={data.totalValue}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell colSpan={4} scope="row" variant="head" component="th"></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Typography component="h2" variant="h2"></Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">담당자 의견</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="opinion"
                    value={text(data.opinion)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <CompanySelDialog open={open} handleClose={handleDialog} />
    </React.Fragment>
  );
}

export default inject('commonStore')(observer(PaymentInfo));