import React from 'react';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { inject } from 'mobx-react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Box, TextField, FormControlLabel, Button } from '@material-ui/core';

import { Logo, IconUserId, IconUserPw } from '../static/images';
import CustomCheckbox from '../components/common/input/CustomCheckbox';

const CustomButton = withStyles((theme) => ({
  root: {
    height: 56,
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 28,
    fontSize: 20,
    color: '#fff',
  },
  containedPrimary: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.white,
    },
  },
}))(Button);

function LoginPage({ history, accountStore }) {
  const classes = useStyles();
  const { loginCall } = accountStore;
  const saveId = localStorage.getItem('qadmid');
  
  const params = {
    adminId: saveId,
    password: '',
    saveId: Boolean(saveId)
  };

  const handleChange = (e, checked) => {
    const name = e.target.name;
    const value = e.target.value;
    
    if (name === 'saveId') params[name] = checked;
    else params[name] = value;
  };

  const onSubmit = () => {
    // history.push('/operatestate');
    loginCall(params, history);
  }

  return (
    <React.Fragment>
      <div className={classes.logo}>
        <img src={Logo} alt="Quinors" />
      </div>
      <Grid container direction="row" justify="center" wrap="nowrap">
        <Grid item className={classes.loginForm}>
          <Grid container direction="column" justify="center" className="narrowFormArea">
            <Grid item>
              <Box className={clsx(classes.formUserInfoWrap, "mainFormGroup")}>
                <TextField
                  id="inpUserID"
                  placeholder="아이디 입력"
                  name="adminId"
                  InputProps={{
                    inputProps: { 'title': '아이디 입력' },
                    className: clsx(classes.formInput, classes.formInputID)
                  }}
                  variant="outlined"
                  fullWidth
                  className={clsx("formArea")}
                  defaultValue={saveId}
                  onChange={handleChange}
                />
                <TextField
                  type="password"
                  id="inpUserPW"
                  name="password"
                  placeholder="비밀번호 입력"
                  InputProps={{
                    inputProps: { 'title': '비밀번호 입력' },
                    className: clsx(classes.formInput, classes.formInputPW)
                  }}
                  variant="outlined"
                  fullWidth
                  className={clsx("formArea")}
                  onChange={handleChange}
                />
              </Box>
              <Grid container direction="row" justify="space-between" alignItems="center" className={classes.saveID}>
                <Grid item>
                  <FormControlLabel 
                    name="saveId"
                    control={
                      <CustomCheckbox  
                        onChange={handleChange}
                        defaultChecked={Boolean(saveId)}
                      />
                    } 
                    label="아이디 저장" 
                  />
                </Grid>
              </Grid>
              <CustomButton 
                variant="contained" 
                fullWidth 
                color="primary" 
                onClick={onSubmit}
              >로그인
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  logo: {
    marginTop: 135,
    // marginBottom: 57,
    textAlign: 'center',
    '& img': {
      //maxWidth: 210,
      maxWidth: 180,
    },
  },
  loginForm: {
    width: 336,
    marginLeft: 20,
    marginRight: 20,
  },
  formUserInfoWrap: {
    position: 'relative',
  },
  formInput: {
    paddingLeft: 38,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '10px center',
  },
  formInputID: {
    backgroundImage: `url(${IconUserId})`,
  },
  formInputPW: {
    backgroundImage: `url(${IconUserPw})`,
    '& input': {
      paddingRight: 75,
    },
    '& .clearInput': {
      marginRight: 5,
    }
  },
  saveID: {
    marginTop: 15,
    marginBottom: 55,
  },
}));


export default inject('accountStore')(LoginPage);