import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { inject, observer } from 'mobx-react';
import { TextField, InputAdornment } from '@material-ui/core';

import { ButtonCalendar } from '../button';

import ko from 'date-fns/locale/ko';

function Calendar({ isDate, name, commonStore }) {
  const [vDate, setDate] = useState(null);
  const { 
    sDate, eDate,
    setFromPastToPresent,
    calendarDateChange } = commonStore;

  useEffect(() => {
    setFromPastToPresent('1M');
  }, [])

  useEffect(() => {
    if (name === 'sDate') setDate(sDate);
    else setDate(eDate);
  }, [sDate, eDate])

  useEffect(() => {
    if (isDate) {
      calendarDateChange(name, '');
      setDate(null);
    }
  }, [isDate])

  const handleChange = (date) => {
    calendarDateChange(name, date);
    setDate(date);
  };

  return (
    <ReactDatePicker
      locale={ko}
      selected={vDate}
      disabled={isDate}
      dateFormat="yyyy-MM-dd"
      onChange={(date) => handleChange(date)}
      customInput={
        <TextField

          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ButtonCalendar disabled={isDate} />
              </InputAdornment>
            ),
            inputProps: { 'title': '시작일 입력' },
            className: 'pickDate',
          }}
        // defaultValue="2019.08.10"
        />
      }
    />
  );
};

export default inject('commonStore')(observer(Calendar));