import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => (createStyles({
  root: {
    // display: 'none',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    // background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 99999
  },
  bottom: {
    color: '#6798e5',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
}));

const Progress = ({ classes }) => (
  <Grid
    className={classes.root}
    id="progress"
    container
    direction="column"
    justify="center"
    alignItems="center"
  >
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
);

export default withStyles(styles)(Progress);