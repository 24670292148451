import React from 'react';
import { TablePagination, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';


const paging = ({ total, dataPerPage, pageCount, page }) => {

  const totalPage = Math.ceil(total / dataPerPage);    // 총 페이지 수 8
  const pageGroup = Math.ceil(page / pageCount);    // 페이지 그룹 1

  let last = pageGroup * pageCount;    // 화면에 보여질 마지막 페이지 번호 1 * 10 = 20
  if (last > totalPage) last = totalPage; // 20 > 8  = 8
  const first = last - (pageCount - 1) > 0 ? last - (pageCount - 1) : 1;    // 화면에 보여질 첫번째 페이지 번호 8 (10 - 1) = -1
  const next = last + 1;
  const prev = first <= 11 ? 1 : first - 10;

  // console.log("totalPage : " + totalPage);    // 10
  // console.log("pageGroup : " + pageGroup);    // 10
  // console.log("last : " + last);    // 10
  // console.log("first : " + first);  // 1
  // console.log("next : " + next);    // 11
  // console.log("prev : " + prev);    // 0
  return {
    last, first, next, prev, totalPage
  }

}

const TablePaginationActions = (props) => {
  const { count: total, page, rowsPerPage, onChangePage } = props;
  const pagings = paging({ total, dataPerPage: rowsPerPage, pageCount: 10, page: page + 2 });

  let dom = [];
  if (pagings.first > 0) {
    for (let i = pagings.first; i <= pagings.last; i++) {
      dom.push(
        <Button className={i === page + 1 ? 'on' : ''} onClick={(e) => onChangePage(e, i)} key={i}>{i}</Button>
      )
    }
  } else {
    dom.push(<Button className={'on'} onClick={(e) => onChangePage(e, 1)} key={1}>{1}</Button>)
  }
  return (
    <React.Fragment>
      <div className="pagination">
        {
          pagings.first > 1 && (
            <React.Fragment>
              <Button aria-label="첫 페이지" onClick={(e) => onChangePage(e, 1)}>1</Button>
              <Button aria-label="이전 페이지" className="prevPage" onClick={(e) => onChangePage(e, pagings.prev)}><ArrowLeftRoundedIcon /></Button>
            </React.Fragment>
          )
        }
        {dom}
        {
          pagings.last < pagings.totalPage && (
            <React.Fragment>
              <Button aria-label="다음 페이지" className="nextPage" onClick={(e) => onChangePage(e, pagings.next)}><ArrowRightRoundedIcon /></Button>
              <Button aria-label="마지막 페이지" onClick={(e) => onChangePage(e, Math.ceil(total / rowsPerPage))}>{Math.ceil(total / rowsPerPage)}</Button>
            </React.Fragment>
          )
        }

      </div>
    </React.Fragment>
  );
}

const Pagination = ({ total, rowPage, page, queryParams, handlePage, handleClick }) => {

  const handleChangePage = (event, newPage) => {
    let params = queryParams;
    if (handlePage) {
      handlePage(newPage, 'page');
    } else if (handleClick) {
      params.page = newPage;
      handleClick(params);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    let params = queryParams;
    if (handlePage) {
      handlePage(Number(event.target.value), 'size');
    } else if (handleClick) {
      params.page = 1;
      params.size = event.target.value;
      handleClick(params);
    }
  };

  return (
    <TablePagination
      component="div"
      count={total}
      page={page === 0 ? page : page - 1}
      rowsPerPage={rowPage}
      rowsPerPageOptions={[10, 20, 30, 50, 100]}
      labelRowsPerPage={''}
      labelDisplayedRows={({ count }) => <span>총 <em style={{ fontWeight: 700 }}>{count}</em> 건</span>}
      SelectProps={{
        inputProps: { 'aria-label': '목록 개수' },
        fullWidth: false,
        native: true,
        className: 'selectRowsPerPage',
        IconComponent: ExpandMoreIcon,
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default Pagination;