import React from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Typography, Table, TableRow, TableHead, TableBody, TableCell, Link } from '@material-ui/core';
import { numberComma } from '../../lib/util';
import { Pagination } from '../common';

function ChargeSelList({ chargeStore }) {
  const { priceList: list, pParams: params, priceListSel } = chargeStore;

  const handlePaging = (value, name) => {
    if (name === 'page') params.page = value;
    else if (name === 'size') params.size = value;

    priceListSel(params);
  }

  return (
    <div className="scrollBody">
      <div className="w2000">
        <Table className="bbsListTable">
          <caption>요금조회</caption>
          <colgroup>
            <col style={{ width: ' 10%' }} />
            <col style={{ width: ' 10%' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
            <col style={{ width: ' %' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>출발지(시,도)</TableCell>
              <TableCell>도착지(시,도)</TableCell>
              <TableCell>오토바이</TableCell>
              <TableCell>다바스</TableCell>
              <TableCell>라보</TableCell>
              <TableCell>벤</TableCell>
              <TableCell>1t</TableCell>
              <TableCell>1.4t</TableCell>
              <TableCell>2.5t</TableCell>
              <TableCell>3.5t</TableCell>
              <TableCell>5t</TableCell>
              <TableCell>8t</TableCell>
              <TableCell>11t</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              list.content.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item.startPoint}</TableCell>
                  <TableCell align="center">{item.endPoint}</TableCell>
                  <TableCell align="center">{numberComma(item.bikePrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.damasPrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.laboPrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.benPrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.ontTPrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.onePointFourTPrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.twoPointFiveTPrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.threePointFiveTPrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.fiveTPrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.eightTPrice)}</TableCell>
                  <TableCell align="center">{numberComma(item.elevenTPrice)}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <Pagination
          total={list.total}
          page={params.page}
          rowPage={params.size}
          handlePage={handlePaging}
        />
      </div>
    </div>
  );
}

export default inject('chargeStore')(observer(ChargeSelList));