import React, { useState, useCallback, useEffect } from 'react';
import { inject } from 'mobx-react'

import { TitleButtons } from '../components/common';
import { ButtonAddIcon } from '../components/common/button';
import NewRegDialog from '../components/ManagementOfStaffPage/NewRegDialog';
import StaffList from '../components/ManagementOfStaffPage/StaffList';

function ManagementOfStaffPage({ adminStore }) {
  const [regOpen, setRegDialog] = useState(false);
  const [modOpen, setModDialog] = useState(false);
  const [id, setId] = useState(null);
  const { adminSelCall } = adminStore;

  useEffect(() => {
    adminSelCall();
  }, []);

  const handleRegDialogOnOff = useCallback(() => {
    setRegDialog(!regOpen);
  }, [regOpen])

  const handleModDialogOnOff = useCallback((id) => {
    if (!modOpen) setId(id);
    setModDialog(!modOpen);
  }, [modOpen])

  return (
    <React.Fragment>
      <TitleButtons title="직원관리">
        <ButtonAddIcon 
          style={{width: 150}}
          onClick={handleRegDialogOnOff}
        >신규등록</ButtonAddIcon>
      </TitleButtons>
      <StaffList handleDialog={handleModDialogOnOff} />
      <NewRegDialog open={regOpen} type="reg" handleClose={handleRegDialogOnOff} />
      <NewRegDialog open={modOpen} type="mod" handleClose={handleModDialogOnOff} id={id}/>
    </React.Fragment>
  );
}

export default inject('adminStore')(ManagementOfStaffPage);