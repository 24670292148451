import React, { useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { 
  Dialog, DialogTitle, Typography, Button,
  IconButton, DialogContent, Table, Box, DialogActions,
  TableBody, TableRow, TableCell, TextField, FormControlLabel 
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { BtnCloseDialog } from '../../static/images';
import CustomCheckbox from '../../components/common/input/CustomCheckbox';
import { text } from '../../lib/util';


function NewRegDialog({ open, type, handleClose, adminStore, id }) {
  const classes = useStyles();
  const history = useHistory();
  const isReg = type === 'reg';
  const { adminRegCall, adminDetCall, adminData, adminModCall } = adminStore;
  const [data, setData] = useState({});
  const [adminPages, setAdminPages] = useState([]);
  const params = {};

  // useEffect(() => {
  //   return () => {
  //     if(!isReg) setData({});
  //   }
  // }, [])

  useEffect(() => {
    if (!id) return;
    adminDetCall(id)
  }, [id]);

  useEffect(() => {
    console.log({ adminData });
    if (!adminData || !open) return;
    if (isReg) return;
    setData(adminData);
    setAdminPages(adminData.adminPages);
  }, [open, adminData])

  const handleChange = (e, checked) => {
    const value = e.target.value;
    const name = e.target.name;
    
    if (name === "adminPages") {
      const temp = adminPages;

      const findIdx = _.findIndex(temp, (o) => o === Number(value));
      // console.log({ name, value, findIdx, temp });
      if (findIdx === -1) {
        temp.push(Number(value));
      } else {
        temp.splice(findIdx, 1);
      }
      
      setAdminPages([...temp]);
      
    } else {
      setData({
        ...data,
        [name]: value
      })
    }
    
    
  }

  const handlePwdChange = () => {};

  const handleSave = () => {
    if (type === "reg") {
      adminRegCall({ ...data, adminPages }, () => {
        setData({});
        setAdminPages([]);
        handleClose();
      });
    } else if (type === "mod") {
      adminModCall({ ...data, adminPages }, () => {
        setData({});
        setAdminPages([]);
        handleClose();
      })
    }
  }

  return (
    <Dialog 
      fullWidth
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="dialogTitle" 
    >
      <DialogTitle id="dialogTitle" disableTypography>
        <Typography component="h2" variant="h1">
          {type === 'reg' ? '신규등록' : '상세정보'}
        </Typography>
        <IconButton 
          aria-label="close" 
          className="closeButton"
          onClick={handleClose}
        >
          <img src={BtnCloseDialog} alt="닫기" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography component="h2" variant="h2">등록정보</Typography>
        <Box className="section">
          <Table className="dataTable horizontal">
            <caption>신규등록</caption>
            <colgroup>
              <col style={{ width: '10%' }} />
              <col style={{ width: '*%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '*%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '*%' }} />
            </colgroup>
            <TableBody>
              <TableRow>
                <TableCell scope="row" variant="head" component="th">이름</TableCell>
                <TableCell>
                  <div className="tableFormControl">
                    <TextField
                      fullWidth
                      placeholder=""
                      name="name"
                      onChange={handleChange}
                      value={text(data.name)}
                    />
                  </div>
                </TableCell>
                <TableCell scope="row" variant="head" component="th">직급</TableCell>
                <TableCell>
                  <div className="tableFormControl">
                    <TextField
                      fullWidth
                      placeholder=""
                      value={text(data.position)}
                      name="position"
                      onChange={handleChange}
                    />
                  </div>
                </TableCell>
                <TableCell scope="row" variant="head" component="th">연락처</TableCell>
                <TableCell>
                  <div className="tableFormControl">
                    <TextField
                      fullWidth
                      placeholder=""
                      value={text(data.phone)}
                      name="phone"
                      onChange={handleChange}
                    />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" variant="head" component="th">아이디</TableCell>
                <TableCell>
                  <div className="tableFormControl">
                    <TextField
                      fullWidth
                      placeholder=""
                      name="adminId"
                      value={text(data.adminId)}
                      onChange={handleChange}
                    />
                  </div>
                </TableCell>
                <TableCell scope="row" variant="head" component="th">비밀번호</TableCell>
                <TableCell colSpan={1}>
                  <div className="tableFormControl">
                    <TextField
                      type="password"
                      name="password"
                      fullWidth={isReg}
                      placeholder=""
                      onChange={handleChange}
                    />
                    {/* {!isReg && (
                      <Button
                        className={classes.changeBtn}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={handlePwdChange}
                      >
                        변경
                      </Button>
                    )} */}
                  </div>
                </TableCell>
                {/* <TableCell colSpan={ isReg ? 2 : 1}  scope="row" variant="head" component="th"></TableCell> */}
                <TableCell colSpan={2}  scope="row" variant="head" component="th"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box className="section">
          <Typography variant="h2">메뉴별 권한</Typography>
          <Box className="contsBox">
            <Table className={clsx(classes.menuSet, "dataTable")}>
              <caption>메뉴별 권한 설정 테이블</caption>
              <colgroup>
                <col span={6} style={{ width: '15%' }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <FormControlLabel 
                      value={"1"}
                      name="adminPages"
                      onChange={handleChange}
                      checked={_.includes(adminPages, 1)}
                      control={<CustomCheckbox/>} label="운영현황" />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel 
                      value={'2'}
                      name="adminPages"
                      onChange={handleChange}
                      checked={_.includes(adminPages, 2)}
                      control={<CustomCheckbox />} label="고객관리-회원" />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel 
                      value={"4"}
                      name="adminPages"
                      onChange={handleChange}
                      checked={_.includes(adminPages, 4)}
                      control={<CustomCheckbox />} label="운영관리-배송내역 조회" />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel 
                      value={"6"}
                      name="adminPages"
                      onChange={handleChange}
                      checked={_.includes(adminPages, 6)}
                      control={<CustomCheckbox />} label="요금관리-요금조회" />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel 
                      value={"9"}
                      name="adminPages"
                      onChange={handleChange}
                      checked={_.includes(adminPages, 9)}
                      control={<CustomCheckbox />} label="배너관리" />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel 
                      value={"8"}
                      name="adminPages"
                      onChange={handleChange}
                      checked={_.includes(adminPages, 8)}
                      control={<CustomCheckbox />} label="직원관리" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <FormControlLabel 
                      value={"3"}
                      name="adminPages"
                      onChange={handleChange}
                      checked={_.includes(adminPages, 3)}
                      control={<CustomCheckbox />} label="고객관리-기업" />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel 
                      value={"5"}
                      name="adminPages"
                      onChange={handleChange}
                      checked={_.includes(adminPages, 5)}
                      control={<CustomCheckbox />} label="운영관리-방문자 조회" />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel 
                      value={"7"}
                      name="adminPages"
                      onChange={handleChange}
                      checked={_.includes(adminPages, 7)}
                      control={<CustomCheckbox />} label="요금관리-요금수정" />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <div className="pageMainButtons">
          {type === "mod" && <Button variant="contained" color="secondary">삭제</Button>}
          <Button variant="contained" color="primary" onClick={handleSave}>저장</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  menuSet: {
    '&.dataTable thead th': {
      paddingLeft: 20,
      textAlign: 'left',
    }
  },
  changeBtn: {
    marginLeft: 5,
    marginTop: 2,
    width: 70
  }
}));

export default inject('adminStore')(observer(NewRegDialog));