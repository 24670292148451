import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Table, TableHead, TableRow, TableCell, TableBody, Button, FormControl } from '@material-ui/core';

import { CustomSelectbox } from '../../components/common/input';
import { Pagination } from '../common';

function BannerList({ handleModDialogOnOff, bannerStore }) {
  const { bannerListSel, bannerList: list, bParams: params } = bannerStore;
  
  const handleClick = (bannerKey) => {
    handleModDialogOnOff(bannerKey);
  };

  const handlePaging = (value, name) => {
    if (name === 'page') params.page = value;
    else if (name === 'size') params.size = value;

    bannerListSel(params);
  }

  return (
    <React.Fragment>
      <Table className="bbsListTable">
        <caption>GA 관리 목록</caption>
        <colgroup>
          <col style={{ width: '18%' }} />
          {/* <col style={{ width: '10%' }} /> */}
          <col style={{ width: '18%' }} />
          <col style={{ width: '18%' }} />
          <col style={{ width: '*%' }} />
        </colgroup>
        <TableHead className="bbsListTableHead">
          <TableRow>
            <TableCell align="center">상태</TableCell>
            {/* <TableCell align="center">번호</TableCell> */}
            <TableCell align="center">등록일</TableCell>
            <TableCell align="center">배너명</TableCell>
            <TableCell align="center">상세정보</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list && list.content.length > 0
              ? (
                list.content.map((item, index) => (
                  <TableRow key={index}>
                    {/* <TableCell align="center">
                      <FormControl fullWidth={false}>
                      <CustomSelectbox 
                        inputProps={{ 'title': '상태 선택' }}
                      >
                        <option>노출</option>
                        <option>대기</option>
                      </CustomSelectbox>
                    </FormControl>
                    </TableCell> */}
                    <TableCell align="center">
                      {item.useYn === "Y" ? "노출" : "대기"}
                    </TableCell>
                    {/* <TableCell align="center">??</TableCell> */}
                    <TableCell align="center">
                      {/* {moment(new Date()).format('YYYY-MM-DD')} */}
                      
                    </TableCell>
                    <TableCell align="center">{item.title}</TableCell>
                    <TableCell align="center">
                      <Button
                        fullWidth
                        style={{ width: 180 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleClick(item.bannerKey)}
                      >
                        자세히
                    </Button>
                    </TableCell>
                  </TableRow>
                ))
              )
              : (
                <TableRow>
                  <TableCell colSpan={5} align="center">조회결과가 없습니다.</TableCell>
                </TableRow>
              )
          }
        </TableBody>
      </Table>
      <Pagination
        total={list.total}
        page={params.page}
        rowPage={params.size}
        handlePage={handlePaging}
      />
    </React.Fragment>
  );
}

export default inject('bannerStore')(observer(BannerList));