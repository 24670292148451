import React, { useState, useCallback, useEffect } from 'react';
import {
  Box, Table, TableBody,
  Typography, TableRow, TableCell, Button,
  TextField, FormControl,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { CustomCheckbox, CustomSelectbox } from '../common/input';
import DaumPostcodeDialog from '../common/dialog/DaumPostcodeDialog';
import { CARDS } from '../../lib/message';
import CardFormMask from '../common/input/CardFormMask';
import { text } from '../../lib/util';

// const params = {};

function CompanyRegForm({ history, type, commonStore, cmStore }) {
  const classes = useStyles();
  const { daumAddress, codeList, fileUpload } = commonStore;
  const { companyRegCall, companyDet } = cmStore;
  const [addrOpen, setAddrOpen] = useState(false);
  const [cardCheck, setCardCheck] = useState(false);
  const [fileName, setFileName] = useState("");
  const [addr, setAddr] = useState('');
  const [params, setParams] = useState({});

  useEffect(() => {
    if (type !== "det" || !companyDet) return;
    setParams(companyDet);
  }, [companyDet])

  useEffect(() => {
    if (codeList) {
      params["companyPaymentType"] = codeList.companyPaymentType[0].code;
    }
  }, [codeList]);

  useEffect(() => {
    setParams((old) => ({
      ...old,
      address: daumAddress
    }))
    setAddr(daumAddress)
  }, [daumAddress])


  const handlePage = () => {
    history.push('/company');
  };

  const handleChange = (e) => {
    if (type === "det") return;
    const { name, value, checked } = e.target;
    
    if (name === 'cardCheck') {  // 카드등록 체크박스 check, uncheck
      setCardCheck(checked);
      params.cardCode = "";
      params.cardNumber = "";
      document.getElementsByName("cardCode")[0].value = "";
      document.getElementsByName("cardNumber")[0].value = "";

    } else {
      setParams((old) => ({
        ...old,
        [name]: value
      }))
    }
  };
  const handleAddrOnOff = useCallback(() => setAddrOpen(!addrOpen), [addrOpen]);
  const handleFile = async (e) => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length === 0) return;
    const file = e.target.files[0];

    try {
      const formData = new FormData();
      formData.append('sourceFile', file);

      const result = await fileUpload(formData);

      if (result) {
        setFileName(file.name);
        params["businessRegistration"] = result;
      } else {
        setFileName("");
      }

    } catch (error) {
      console.log('%c##### Error-handleFile: ', 'color: red', error);
    }

  }

  const onSubmit = () => {
    companyRegCall(params, history);
  }

  return (
    <React.Fragment>
      <DaumPostcodeDialog open={addrOpen} handleClose={handleAddrOnOff} />
      <Typography component="h2" variant="h2">등록정보</Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '12%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '12%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">기업형태</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      inputProps={{ 'title': '기업형태 선택' }}
                      name="companyType"
                      value={text(params.companyType)}
                      onChange={handleChange}
                    >
                      <option value="">선택</option>
                      {
                        codeList && (
                          codeList.companyType.map((item) => (
                            <option key={item.code} value={item.code}>
                              {item.title}
                            </option>
                          ))
                        )
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">기업명</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="companyName"
                    value={text(params.companyName)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">대표번호</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="phone"
                    value={text(params.phone)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">대표자명</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="representativeName"
                    value={text(params.representativeName)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">사업자번호</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="businessNumber"
                    value={text(params.businessNumber)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">업종</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="sector"
                    value={text(params.sector)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">주소</TableCell>
              <TableCell colSpan={3}>
                <div className="tableFormControl">
                  <TextField
                    disabled
                    placeholder="주소를 입력하세요."
                    className="addrDisabled"
                    style={{width: '50%'}}
                    value={addr}
                    onClick={handleAddrOnOff}
                  />
                  <TextField
                    // fullWidth
                    placeholder=""
                    style={{ width: '50%', paddingLeft: 10}}
                    name="addressDtl"
                    value={params.addressDtl}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">사업자등록증</TableCell>
              <TableCell>
                <div className={classes.formFileUpload}>
                  <input 
                    type="file" 
                    id="fileUpload" 
                    title="" 
                    className={classes.inputFile} 
                    onChange={handleFile}
                  />
                  <label htmlFor="fileUpload" className={classes.btnFile}>등록하기</label>
                </div>
                <div>
                  {fileName}
                </div>
                {
                  (type === "det" && params.businessRegistration) && (
                    <Button
                      fullWidth
                      style={{ marginTop: 5, width: 200}}
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        window.open(params.businessRegistration)
                      }}
                    >
                      자세히
                    </Button>
                  )
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">결제방식</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      inputProps={{ 'title': '결제방식 선택' }}
                      name="companyPaymentType"
                      value={text(params.companyPaymentType)}
                      onChange={handleChange}
                    >
                      {
                        codeList && (
                          codeList.companyPaymentType.map((item) => (
                            <option key={item.code} value={item.code}>{item.title}</option>
                          ))
                        )
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">결제일</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="paymentDay"
                    value={text(params.paymentDay)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">이메일</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="email"
                    value={text(params.email)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">
                카드등록
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox name="cardCheck" onChange={handleChange}/>
                </FormControl>
              </TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      disabled={!cardCheck}
                      inputProps={{ 'title': '결제방식 선택' }}
                      name="cardCode"
                      value={text(params.cardCode)}
                      onChange={handleChange}
                    >
                      <option value="">카드사 선택</option>
                      {
                        codeList && (
                          codeList.cardCode.map((item) => (
                            <option key={item.code} value={item.code}>{item.title}</option>
                          ))
                        )
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">카드번호</TableCell>
              <TableCell colSpan={3}>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    disabled={!cardCheck}
                    name="cardNumber"
                    value={text(params.cardNumber)}
                    onChange={handleChange}
                    InputProps={{
                      inputComponent: CardFormMask,
                    }}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">계약정보</TableCell>
              <TableCell colSpan={5}>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="contactInformation"
                    value={text(params.contactInformation)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      {
        type === 'reg' &&  (
          <Box className="pageMainButtons">
            <Button 
              variant="contained" 
              color="primary"
              onClick={onSubmit}
            >등록</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePage}
            >목록</Button>
          </Box>
        )
      }
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  formFileUpload: {
    position: 'relative',
    display: 'inline-block',
  },
  inputFile: {
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 32,
    opacity: 0,
    padding: 0,
    zIndex: 10,
    background: 'pink',
    boxSizing: 'border-box',
    '&:focus': {
      '& ~ .MuiTextField-root .outline': {
        borderColor: theme.palette.border[300],
      },
      '& ~ label': {
        backgroundColor: theme.palette.bgColor[400],
      }
    }
  },
  btnFile: {
    display: 'inline-block',
    width: 200,
    height: 32,
    paddingTop: 8,
    border: `1px solid ${theme.palette.border[100]}`,
    borderRadius: 2,
    backgroundColor: theme.palette.bgColor[200],
    lineHeight: 1,
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}));

export default inject('commonStore', 'cmStore')(withRouter(observer(CompanyRegForm)));