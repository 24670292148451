import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Table, TableHead, TableRow, TableCell, TableBody, Button, Box } from '@material-ui/core';
import { Pagination } from '../common';
import { pagingRowNum } from '../../lib/util';

function ManagerList({ id, cmStore }) {
  const history = useHistory();
  const { 
    cmParams: params,
    thePersonInCharge: list,
    companyManagerSelCall
  } = cmStore;

  const handleClick = (path) => {
    history.push(path);
  };

  const handlePaging = (value, name) => {
    if (name === 'page') params.page = value;
    else if (name === 'size') params.size = value;

    companyManagerSelCall(id, params);
  }

  return (
    <React.Fragment>
      <Table className="bbsListTable">
        <caption>GA 관리 목록</caption>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '*%' }} />
        </colgroup>
        <TableHead className="bbsListTableHead">
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">담당자</TableCell>
            <TableCell align="center">이메일</TableCell>
            <TableCell align="center">부서</TableCell>
            <TableCell align="center">직급</TableCell>
            <TableCell align="center">연락처</TableCell>
            <TableCell align="center">상세정보</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.content.length > 0
              ? (
                list.content.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {pagingRowNum(list.total, params.size, params.page, index)}
                    </TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.email}</TableCell>
                    <TableCell align="center">{item.department}</TableCell>
                    <TableCell align="center">{item.position}</TableCell>
                    <TableCell align="center">{item.phone}</TableCell>
                    <TableCell align="center">
                      <Button
                        fullWidth
                        style={{ width: 200 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleClick(`/manager/${item.userKey}`)}
                      >
                        자세히
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )
              : (
                <TableRow>
                  <TableCell colSpan={7} align="center">조회결과가 없습니다.</TableCell>
                </TableRow>
              )
          }
          
        </TableBody>
      </Table>
      <Pagination
        total={list.total}
        page={params.page}
        rowPage={params.size}
        handlePage={handlePaging}
      />
      <Box className="pageMainButtons">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClick('/company')}
        >목록</Button>
      </Box>
    </React.Fragment>
  );
}

export default inject('cmStore')(observer(ManagerList));