import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { 
  Title, ReceiveInfo, PlaceInfo, 
  AddInfo, DeliverInfo, PaymentInfo
} from '../components/ReceiveDetPage/';


let params = {};
function ReceiveDetPage({ history, receiveStore }) {
  const classes = useStyles();
  const { id } = useParams();
  const { receiveModCall, orderDetSel, orderDet, orderDetInit } = receiveStore;

  const handleSaveBtn = () => {
    
    receiveModCall({
      ...orderDet,
      ...params,
      orderKey: id,
    });
  }

  useEffect(() => {
    if (id) {
      orderDetSel(id);
    }
    return () => {
      orderDetInit()
    }
  }, []);

  const handleParams = (data) => {
    params = {
      ...params,
      ...data,
    }
  };


  if (!orderDet) return null;

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Title orderDet={orderDet}/>
        <ReceiveInfo orderDet={orderDet} handleParams={handleParams} />
        <PlaceInfo orderDet={orderDet} handleParams={handleParams} />
        <AddInfo orderDet={orderDet} handleParams={handleParams} />
        <DeliverInfo data={orderDet} handleParams={handleParams} />
        <PaymentInfo orderDet={orderDet} handleParams={handleParams} />
        <Box className="pageMainButtons">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveBtn}
          >저장</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              history.goBack();
            }}
          >뒤로</Button>
        </Box>
      </div>
    </React.Fragment>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormControl-root .MuiInputBase-root': {
      // width: 215,
    },
    '& .MuiFormGroup-row .MuiFormControlLabel-root': {
      width: 100,
      marginRight: 0,
    }
  },
}));

export default inject('receiveStore')(observer(ReceiveDetPage));