import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, InputAdornment } from '@material-ui/core';

import { ButtonClearInput } from '../../../../components/common/button';

const useStyles = makeStyles(theme => ({
  small: { width: 250 }
}));

const FormResetPassword = props => {
  const classes = useStyles();

  const { className } = props;

  const test = false;

  return (
    <Box className={clsx({ [classes[className]]: className }, "narrowFormArea mainFormGroup")}>
      <TextField
        error={test}
        type="password"
        id="inpPw"
        label="현재 비밀번호"
        InputLabelProps={{
          className: "horizontalLabel",
        }}
        placeholder="현재 비밀번호 입력"
        helperText={test ? "현재 비밀번호를 입력해 주세요." : false}
        variant="outlined"
        fullWidth
        className="formArea"
      />
      <TextField
        error={test}
        type="password"
        id="inpNewPw"
        label="신규 비밀번호"
        InputLabelProps={{
          className: "horizontalLabel",
        }}
        placeholder="신규 비밀번호 입력"
        helperText={test ? "신규 비밀번호를 입력해 주세요." : false}
        variant="outlined"
        fullWidth
        className="formArea"
      />
      <TextField
        error={test}
        type="password"
        id="inpNewPwChk"
        label="신규 비밀번호 확인"
        InputLabelProps={{
          className: "horizontalLabel",
        }}
        placeholder="신규 비밀번호 재입력"
        helperText={test ? "신규 비밀번호 확인을 입력해 주세요." : false}
        variant="outlined"
        fullWidth
        className="formArea"
      />
    </Box>
  );
};

export default FormResetPassword;