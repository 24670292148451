import React, { useState, useCallback, useEffect } from 'react';
import { inject } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { Title } from '../components/common';
import MemberInfo from '../components/MemberDetPage/MemberInfo';
import ServiceUseList from '../components/MemberDetPage/ServiceUseList';
import PwdChangeDialog from '../components/common/dialog/PwdChangeDialog/PwdChangeDialog';

function MemberDetPage({ cmStore, match }) {
  const location = useParams();
  const [open, setOpen] = useState(false);
  const { memberDetSelCall, memberUseSel } = cmStore;

  useEffect(() => {
    if (Object.keys(match.params).length !== 0) {
      const { id } = match.params;
      memberDetSelCall(id);
    }
  }, []);  
  
  const handleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <React.Fragment>
      <Title title="회원정보" />
      <MemberInfo />
      <ServiceUseList handleDialog={handleDialog}/>

      { open && <PwdChangeDialog open={open} handleClose={handleDialog} />}
    </React.Fragment>
  );
}

export default inject('cmStore')(MemberDetPage);