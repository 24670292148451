import React from 'react';
import { inject, observer } from 'mobx-react';
import { 
  Grid, Typography, FormControl, TextField 
} from '@material-ui/core';

import { CustomSelectbox } from '../common/input';
import ButtonSearchList from '../common/button/ButtonSearchList';

function SearchForm({ commonStore, onSubmit }) {
  const { codeList } = commonStore;
  const params = {};

  const handleChange = (e) => {
    const v = e.target.value;
    const n = e.target.name;
    params[n] = v;
  }

  const onSearch = () => {
    onSubmit(params);
  }

  return (
    <React.Fragment>
      <Grid container className="listSearch">
        <Grid item>
          <Grid container direction="row" alignItems="center" className="schFormGroup">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Typography className="formLabel">구분</Typography>
                </Grid>
                <Grid item>
                  <FormControl fullWidth={false}>
                    <CustomSelectbox 
                      inputProps={{ 'title': '구분 선택' }}
                      name="userType"
                      onChange={handleChange}
                    >
                      <option value="">전체</option>
                      {
                        codeList && (
                          codeList.userType.map((item) => (
                            <option key={item.code} value={item.code}>{item.title}</option>
                          ))
                        )
                      }
                    </CustomSelectbox>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="기업명, 이름, 아이디, 휴대폰번호 입력"
                    className="searchKeyword"
                    name="q"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ButtonSearchList className="btnSchList" onClick={onSearch}/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject("cmStore", "commonStore")(observer(SearchForm));