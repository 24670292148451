export const testRow = [
  { startPoint: '',
    endPoint: '',
    bikePrice: 0,
    damasPrice: 0,
    laboPrice: 0,
    benPrice: 0,
    ontTPrice: 0,
    onePointFourTPrice: 0,
    twoPointFiveTPrice: 0,
    threePointFiveTPrice: 0,
    fiveTPrice: 0,
    eightTPrice: 0,
    elevenTPrice: 0,
  },
]