import { client } from '../lib/client';

// 접수 정보 상세
export const orderDetAPI = (id) => (
  client({
    url: `/v1/admin/order/detail/${id}`,
  }, true)
);

// 접수 상태 변경
export const orderStateModAPI = (data) => (
  client({
    url: '/v1/admin/order/receipt/status ',
    method: 'put',
    data,
  }, true)
);

// 접수 정보 수정
export const orderDetModAPI = (data) => (
  client({
    url: '/v1/admin/order/detail',
    method: 'put',
    data
  }, true)
);