import { client } from '../lib/client';

// 관리자 조회
export const adminSel = (params) => (
  client({
    url: '/v1/admin/list',
    params,
  }, true)
);

// 관리자 등록
export const adminReg = (data) => (
  client({
    url: '/v1/admin/join',
    method: 'post',
    data,
  }, true)
);

// 관리자 수정
export const adminMod = (data) => (
  client({
    url: '/v1/admin',
    method: 'put',
    data
  }, true)
);

// 관리자 상세
export const adminDet = (id) => (
  client({
    url: `/v1/admin/${id}`
  }, true)
);