import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import { Box, Button, ButtonGroup } from '@material-ui/core';

import { Logo } from '../../static/images';
import { PwdChangeDialog } from '../common/dialog/PwdChangeDialog'

const Profile = ({ history, accountStore }) => {
  const classes = useStyles();
  const { adminInfo, alertOnOff, alertSountOnOff } = accountStore;
  const [open, setOpen] = useState(false);
  
  const handleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const hancleLogout = () => {
    history.push('/');
    localStorage.removeItem('qadmtk');
  }

  const handleAlert = () => {
    alertOnOff();
  };
  
  return (
    <React.Fragment>
      <Box className={classes.profileArea}>
        <div className={classes.logo}><img src={Logo} alt="GLOBAL FM" /></div>
        {/* <span className={classes.user}>공지철님 <span className={classes.welcome}>반갑습니다 : D</span></span> */}
        <span className={classes.user}><a href="#" className="link">{adminInfo ? adminInfo.name : 'OOO'}님</a> <span className={classes.welcome}>반갑습니다 : D</span></span>
        <ButtonGroup variant="contained" size="small" className={classes.buttons}>
          <Button onClick={hancleLogout}>로그아웃</Button>
          <Button onClick={handleAlert}>
            { alertSountOnOff ? '알림 끄기' : '알림 켜기'}
          </Button>
        </ButtonGroup>
      </Box>
      {/* <PwdChangeDialog open={open} handleClose={handleDialog} /> */}
    </React.Fragment>
  );
};

const useStyles = makeStyles(() => ({
  profileArea: {
    paddingTop: 25,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center'
  },
  logo: {
    // marginBottom: 32,
    '& img': {
      //maxWidth: 156,
      maxWidth: 210,
    },
  },
  buttons: {
    width: '100%',
    marginTop: 13,
    marginBottom: 18,
    boxShadow: '5px 0 20px rgba(151, 227, 251, 1)',
    '& button': {
      width: '50%',
      height: 34,
      borderColor: 'rgba(0, 0, 0, 0.04) !important',
      borderRadius: 5,
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      fontWeight: '700',
      fontSize: 12,
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
      }
    }
  },
  user: {
    display: 'inline-block',
    paddingLeft: 30,
    fontWeight: 300,
    fontSize: 20,
    lineHeight: 1.5,
    textAlign: 'left',
    color: '#000',
    '& .link': {
      borderBottomColor: 'rgba(0, 0, 0, 0.3)',
      fontSize: 'inherit',
      color: 'inherit',
    },
  },
  welcome: {
    display: 'inline-block',
    wordBreak: 'keep-all'
  }
}));

export default inject('accountStore')(withRouter(observer(Profile)));