import React from 'react';
import clsx from 'clsx'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Group, Person, EventNote, FormatListBulleted, Dashboard, ListAlt } from '@material-ui/icons';
import { List, ListItem, ListItemText, Collapse, Button } from '@material-ui/core';

import { lnbNaviMark } from '../../lib/util';

const SidebarNav = ({ history, match, adminInfo }) => {
  const classes = useStyles();
  const test = JSON.parse(JSON.stringify(adminInfo.adminPages));
  
  const handleClick = (path) => {
    history.push(path);
  }

  return (
    <List component="nav" disablePadding className={classes.globalNav} >
      <ListItem
        component="div"
        style={{display: _.includes(test, 1) ? '' : "none"}}
        className={clsx(classes.nav, 'navOpen')}
        selected={
          lnbNaviMark(match.path, ['/operatestate', '/receive/:id'])
        }
        onClick={() => handleClick('/operatestate')}
      >
        <Button
          fullWidth
          className={clsx(
            classes.btnNav,
            'btnNavOpen',
          )}
        >
          <Dashboard />
          <ListItemText disableTypography primary="운영현황" />
        </Button>
      </ListItem>
      <ListItem
        component="div"
        className={clsx(classes.nav, 'navOpen')}
      >
        <Button
          fullWidth
          className={clsx(classes.btnNav, 'btnNavOpen',)}
          onClick={() => handleClick('/member')}
          style={{
            display: (_.includes(test, 2) || _.includes(test, 3)) ? '' : "none"
          }}
        >
          <Person />
          <ListItemText disableTypography primary="고객관리" />
        </Button>
        <Collapse in timeout="auto" unmountOnExit>
          <List dense disablePadding className={classes.subNavWrap}>
            <ListItem
              style={{
                display: _.includes(test, 2)  ? '' : "none"
              }}
              className={
                clsx(
                  classes.subNav, 
                  lnbNaviMark(
                    match.path, 
                    ['/member', '/companymanagerreg', '/member/:id']
                  ) ? 'current' : '')
              }
            >
              <Button 
                fullWidth 
                size="small" 
                className={classes.btnSubNav}
                onClick={() => handleClick('/member')}
              >회원조회
              </Button>
            </ListItem>
            <ListItem
              style={{
                display: _.includes(test, 3)  ? '' : "none"
              }}
              className={
                clsx(
                  classes.subNav,
                  lnbNaviMark(
                    match.path,
                    ['/company', '/company/:id', '/manager/:id', '/companyreg']
                  ) ? 'current' : '')
              }
            >
              <Button 
                size="small" 
                fullWidth 
                className={classes.btnSubNav}
                onClick={() => handleClick('/company')}
              >기업조회</Button>
            </ListItem>
          </List>
        </Collapse>
      </ListItem>
      <ListItem
        component="div"
        className={clsx(classes.nav, 'navOpen')}
      >
        <Button
          fullWidth
          className={clsx(
            classes.btnNav,
            'btnNavOpen',
          )}
          onClick={() => handleClick('/deliberyhistory')}
          style={{
            display: (_.includes(test, 4) || _.includes(test, 5)) ? '' : "none"
          }}
        >
          <FormatListBulleted />
          <ListItemText disableTypography primary="운영관리" />
        </Button>
        <Collapse in timeout="auto" unmountOnExit>
          <List dense disablePadding className={classes.subNavWrap}>
            <ListItem
              className={
                clsx(
                  classes.subNav,
                  lnbNaviMark(
                    match.path,
                    ['/deliberyhistory', '/deliberydet/:id']
                  ) ? 'current' : '')
              }
              style={{
                display: _.includes(test, 4)  ? '' : "none"
              }}
            >
              <Button
                fullWidth
                size="small"
                className={classes.btnSubNav}
                onClick={() => handleClick('/deliberyhistory')}
              >배송내역 조회
              </Button>
            </ListItem>
            <ListItem
              className={
                clsx(
                  classes.subNav,
                  lnbNaviMark(
                    match.path,
                    ['/visitor']
                  ) ? 'current' : '')
              }
              style={{
                display: _.includes(test, 5)  ? '' : "none"
              }}
            >
              <Button
                fullWidth
                size="small"
                className={classes.btnSubNav}
                onClick={() => handleClick('/visitor')}
              >방문자 조회
              </Button>
            </ListItem>
          </List>
        </Collapse>
      </ListItem>
      <ListItem
        component="div"
        className={clsx(classes.nav, 'navOpen')}
      >
        <Button
          fullWidth
          className={clsx(classes.btnNav,'btnNavOpen',)}
          onClick={() => handleClick('/chargesel')}
          style={{
            display: (_.includes(test, 6) || _.includes(test, 7)) ? '' : "none"
          }}
        >
          <ListAlt />
          <ListItemText disableTypography primary="요금관리" />
        </Button>
        <Collapse in timeout="auto" unmountOnExit>
          <List dense disablePadding className={classes.subNavWrap}>
            <ListItem
              className={
                clsx(
                  classes.subNav,
                  lnbNaviMark(
                    match.path,
                    ['/chargesel', '/chargedet', '/option']
                  ) ? 'current' : '')
              }
              style={{
                display: _.includes(test, 6)  ? '' : "none"
              }}
            >
              <Button
                fullWidth
                size="small"
                className={classes.btnSubNav}
                onClick={() => handleClick('/chargesel')}
              >요금조회
              </Button>
            </ListItem>
            <ListItem
              className={
                clsx(
                  classes.subNav,
                  lnbNaviMark(
                    match.path,
                    ['/pricemod']
                  ) ? 'current' : '')
              }
              style={{
                display: _.includes(test, 7)  ? '' : "none"
              }}
            >
              <Button
                fullWidth
                size="small"
                className={classes.btnSubNav}
                onClick={() => handleClick('/pricemod')}
              >요금수정
              </Button>
            </ListItem>
          </List>
        </Collapse>
      </ListItem>
      <ListItem
        component="div"
        className={clsx(classes.nav, 'navOpen')}
        selected={
          lnbNaviMark(match.path, ['/mangementofstaff'])
        }
        onClick={() => handleClick('/mangementofstaff')}
        style={{
          display: _.includes(test, 8)  ? '' : "none"
        }}
      >
        <Button
          fullWidth
          className={clsx(classes.btnNav, 'btnNavOpen',)}
        >
          <Group />
          <ListItemText disableTypography primary="직원관리" />
        </Button>
      </ListItem>
      <ListItem
        component="div"
        className={clsx(classes.nav, 'navOpen')}
        selected={
          lnbNaviMark(match.path, ['/banner'])
        }
        onClick={() => handleClick('/banner')}
        style={{
          display: _.includes(test, 9)  ? '' : "none"
        }}
      >
        <Button
          fullWidth
          className={clsx(classes.btnNav, 'btnNavOpen',)}
        >
          <EventNote />
          <ListItemText disableTypography primary="배너관리" />
        </Button>
      </ListItem>
    </List>
  );
};

const useStyles = makeStyles(() => ({
  globalNav: {
    paddingBottom: 30,
    paddingLeft: 16,
    '& .MuiListItem-root.Mui-selected': {
      borderRadius: 5
    },
  },
  nav: {
    display: 'block',
    position: 'relative',
    // padding: '0 20px 0 4px',
    transition: 'all 0.1s',
    '&::after': {
      display: 'block',
      position: 'absolute',
      left: 4,
      right: 20,
      bottom: 0,
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      content: '""',
    },
    '&.navOpen': {
      /* paddingTop: 10,
      paddingBottom: 20,
      paddingLeft: 14,
      borderRadius: '20px 0 0 20px',
      background: 'rgba(255, 255, 255, 0.06)',
      boxShadow: '5px 0 12px rgba(151, 128, 243, 0.12)',
      '&::after': {
          display: 'none',
      }, */
    },
    '&.hideLine::after': {
      display: 'none',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0
    }
  },
  btnNav: {
    position: 'relative',
    padding: 0,
    paddingTop: 7,
    paddingBottom: 8,
    // paddingLeft: 32,
    fontWeight: 700,
    fontSize: 15,
    textAlign: 'left',
    // color: '#000',
    color: 'rgba(0, 0, 0, 0.9)',
    '& svg': {
      marginRight: 10
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
      opacity: 0.65,
    },
    '&::before': {
      display: 'block',
      position: 'absolute',
      left: 4,
      top: 12,
      width: 16,
      height: 16,
      // background: `url(${IconMenu}) no-repeat 0 0`,
      opacity: 0.3,
      content: '""',
    },
    '&.categorySystem::before': {
      // backgroundPositionY: 0,
      background: `url(${Dashboard}) no-repeat 0 0`,
    },
    '&.categoryMember::before': {
      backgroundPositionY: -26,
    },
    '&.categoryService::before': {
      backgroundPositionY: -52,
    },
    '&.categoryInsuData::before': {
      backgroundPositionY: -78,
    },
    '&.categoryPrivate::before': {
      backgroundPositionY: -104,
    },
    '&.categoryStatData::before': {
      backgroundPositionY: -130,
    },
    '&.categoryCs::before': {
      backgroundPositionY: -156,
    },
    '&.categorySales:before': {
      backgroundPositionY: -182,
    },
    '&.btnNavOpen': {
      color: '#000',
    }
  },
  subNavWrap: {
    marginTop: -4,
    paddingBottom: 14,
  },
  subNav: {
    display: 'block',
    padding: 0,
    paddingLeft: 20,
    paddingRight: 20,
    '&.current': {
      position: 'relative',
      opacity: 1,
      '&::before': {
        display: 'block',
        position: 'absolute',
        left: 15,
        top: 10,
        width: 4,
        height: 4,
        borderRadius: '50%',
        background: 'rgba(0, 0, 0, 0.5)',
        content: '""',
      },
      '& > button': {
        fontWeight: '700',
        opacity: 1,
        color: '#000',
      }
    }
  },
  btnSubNav: {
    display: 'block',
    minWidth: 0,
    padding: 0,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 12,
    color: 'rgba(51, 51, 51, 0.9)',
    textAlign: 'left',
    //opacity: 0.8,
    '-ms-flex-pack': 'start',
  },
  depth3Wrap: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
  },
  depth3: {
    position: 'relative',
    marginTop: 6,
    padding: 0,
    paddingLeft: 8,
    '&::before': {
      display: 'block',
      position: 'absolute',
      top: 7,
      left: 0,
      width: 4,
      height: 4,
      border: '1px solid rgba(0, 0, 0, 0.5)',
      borderRadius: '50%',
      content: '""',
    },
    '&:first-child': {
      marginTop: 0,
    },
    '& > button [class*="-label"]': {
      display: 'inline-block',
      width: 'auto',
      lineHeight: '14px',
    },
    '&.current > button [class*="-label"]': {
      borderBottom: '1px solid rgba(51, 51, 51, 0.3)',
      fontWeight: 700,
    }
  },
  btnDepth3: {
    //display: 'inline-block',
    //width: 'auto',
    paddingLeft: 3,
    paddingRight: 3,
    lineHeight: 1,
  },
}));

export default withRouter(SidebarNav);