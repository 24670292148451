import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { TitleButtons } from '../components/common';
import ChargeDet from '../components/ChargeDetPage/ChargeDet';
import FileUploadForm from '../components/ChargeDetPage/FileUploadForm';
import ButtonAddIcon from '../components/common/button/ButtonAddIcon';

function ChargeDetPage({ chargeStore }) {
  const { priceListSel, priceListInitial } = chargeStore;
  useEffect(() => {
    priceListSel({ size: 41092 });
    return () => {
      priceListInitial();
    }
  }, [])
  return (
    <React.Fragment>
      <TitleButtons title="구간 요금수정">
        <ButtonAddIcon
          color="primary"
          style={{ width: 200 }}
        >다운로드</ButtonAddIcon>
        <ButtonAddIcon 
          color="primary"
          style={{width: 200}} 
        >저장</ButtonAddIcon>
      </TitleButtons>
      <FileUploadForm />
      <ChargeDet />
    </React.Fragment>
  );
}

export default inject('chargeStore')(ChargeDetPage);