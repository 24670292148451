import { client } from '../lib/client';

export const fileUploadAPI = (data) => (
  client({
    url: '/v1/file/upload',
    method: 'post',
    file: true,
    data,
  }, true)
);

export const codeListAPI = () => (
  client({
    url: '/v1/code/list',
  })
)