import React, { useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { 
  Box, Table, TableBody, 
  Typography, TableRow, TableCell, Button,
  InputAdornment, TextField, FormControl, Grid, 
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { ButtonSearchList, ButtonClearInput } from '../common/button';
import { CustomCheckbox, CustomSelectbox } from '../common/input';
import CompanySelDialog from '../common/dialog/Company/CompanySelDialog';
import CardFormMask from '../common/input/CardFormMask';

function CompanyManagerRegForm({ cmStore, commonStore, history, match }) {
  const { companyName, companyId, codeList, setCompanyName } = commonStore;
  const { 
    companyManagerRegCall, companyManagerDelCall, 
    compnayUserDetSel, companyUserData: userData,
    compnayUserModCall,
  } = cmStore;
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [cardCheck, setCardCheck] = useState(false);
  const [params, setParams] = useState({});

  useEffect(() => {
    return () => {
      setCompanyName({
        companyName: '',
        companyKey: '',
      })
    }
  }, [])

  useEffect(() => {
    if (Object.keys(match.params).length !== 0) {
      const { id } = match.params;
      setId(id);
      console.log('%c##### debug-id: ', 'color: #058FD7', id);
      compnayUserDetSel(id);
    } 
  }, []);

  useEffect(() => {
    if (id && userData) {
      setParams({
        ...userData
      })
    }
  }, [id, userData])

  const handlePage = (path) => {
    history.push(path);
  };

  //계정등록 
  const handleRegister = () => {
    params["companyKey"] = companyId;
    companyManagerRegCall(params, history);
  };

  // 계정삭제
  const handleDelete = (history) => {
    if (!id) return;
    companyManagerDelCall(id, history);
  };

  const handleModBtn = () => {
    if (!id) return;
    compnayUserModCall(params);
  }

  // 기업검색 팝업
  const handleCompanySelDialogOnOff = useCallback(() => {
    if (id) return;
    
    setOpen(!open);
  }, [open, id]);

  const handleChange = (e, checked) => {
    const value = e.target.value;
    const name = e.target.name;
    const temp = params;

    if (name === "separate" || name === "existing") {
      temp[name] = checked;
    } else if (name === "isCade") {
      setCardCheck(checked);
      temp.cardCode = "";
      temp.cardNumber = "";
      document.getElementsByName("cardCode")[0].value = "";
      document.getElementsByName("cardNumber")[0].value = "";
    }else {
      temp[name] = value;
    }

    setParams({...temp});
  }

  if (id && !userData) return null;
  return (
    <React.Fragment>
      <CompanySelDialog open={open} handleClose={handleCompanySelDialogOnOff} />
      <Typography component="h2" variant="h2">등록정보</Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '12%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">기업검색</TableCell>
              <TableCell colSpan={5}>
                <div className="tableFormControl">
                  <TextField
                    disabled
                    style={{ width: '30%', marginRight: 10 }}
                    placeholder=""
                    value={(id && userData) ? userData.companyName : companyName}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end"><ButtonClearInput /></InputAdornment>
                      ),
                      inputProps: { 'title': '기업검색' },
                    }}
                  />
                  <ButtonSearchList 
                    title="검색" 
                    className="btnSchList" 
                    style={{ width: 150 }} 
                    onClick={handleCompanySelDialogOnOff}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">이름</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    name="name"
                    placeholder=""
                    onChange={handleChange}
                    value={params.name}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">직급</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="position"
                    onChange={handleChange}
                    value={params.position}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">부서</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="department"
                    onChange={handleChange}
                    value={params.department}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">내선번호</TableCell>
              <TableCell >
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="extensionNumber"
                    onChange={handleChange}
                    value={params.extensionNumber}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">특이사항</TableCell>
              <TableCell colSpan={3}>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name=""
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">아이디</TableCell>
              <TableCell >
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="userId"
                    onChange={handleChange}
                    value={params.userId}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">비밀번호</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={params.password}
                  />
                </div>
              </TableCell>
              <TableCell colSpan={2} scope="row" variant="head" component="th"></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Typography style={{ fontWeight: 700 }}>기존 결재와 동일</Typography>
        <FormControl className="tableFormControl checkbox">
          <CustomCheckbox 
            name="existing"
            onChange={handleChange}
          />
        </FormControl>
        <Typography style={{ fontWeight: 700 }}>별도등록</Typography>
        <FormControl className="tableFormControl checkbox">
          <CustomCheckbox 
            name="separate"
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '12%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">결제방식</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      inputProps={{ 'title': '결제방식 선택' }}

                    >
                      <option>계산서 발급</option>
                      <option>계산서 미발급</option>
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">결제일</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="paymentDay"
                    onChange={handleChange}
                    value={params.paymentDay}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">이메일</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="email"
                    onChange={handleChange}
                    value={params.email}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">
                카드등록
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox 
                    name="isCade"
                    onChange={handleChange}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      disabled={!cardCheck}
                      inputProps={{ 'title': '결제방식 선택' }}
                      name="cardCode"
                      onChange={handleChange}
                      value={params.cardCode}
                    >
                      <option value="">카드사 선택</option>
                      {
                        codeList && (
                          codeList.cardCode.map((item) => (
                            <option key={item.code} value={item.code}>{item.title}</option>
                          ))
                        )
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">카드번호</TableCell>
              <TableCell colSpan={3}>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="cardNumber"
                    disabled={!cardCheck}
                    onChange={handleChange}
                    // InputProps={{
                    //   inputComponent: CardFormMask,
                    // }}
                    value={params.cardNumber}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">비고</TableCell>
              <TableCell colSpan={5}>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name=""
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      {
        id 
        ? (
          <Box className="pageMainButtons">
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleDelete}
            >계정 삭제</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleModBtn}
            >저장</Button>
          </Box>
        )
        : (
            <Box className="pageMainButtons">
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleRegister}
              >등록</Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handlePage('/member')}
              >목록</Button>
            </Box>
        )
      }
    </React.Fragment>
  );
}

export default inject('cmStore', 'commonStore')(withRouter(observer(CompanyManagerRegForm)));