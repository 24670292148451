import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography, Grid, FormControl } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import CustomSelectbox from '../common/input/CustomSelectbox';
import ButtonSearchList from '../common/button/ButtonSearchList';

function Title({ receiveStore, orderDet, commonStore }) {
  const { id } = useParams();
  const { receiveStateModCall } = receiveStore;
  const { codeList } = commonStore;
  const [status, setStatus] = useState("NotAccepted");

  const handleBtnClick = () => {
    console.log({ id, status });
    receiveStateModCall({
      orderKey: id,
      receiptStatus: status
    });
  };

  const handleChange = (e) => {
    setStatus(e.target.value);
  }

  useEffect(() => {
    setStatus(orderDet.receiptStatus ? orderDet.receiptStatus : "NotAccepted")
  }, [orderDet])


  return (
    <React.Fragment>
      <Grid container justify="space-between" alignItems="center" className="titleArea">
        <Grid item><Typography variant="h1">접수정보</Typography></Grid>
        <Grid item className="buttonArea">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item style={{marginRight: 10}}>
              <Typography className="formLabel">접수상태</Typography>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <CustomSelectbox 
                  inputProps={{ 'title': '등급 선택' }}
                  name="receiptStatus"
                  value={status}
                  onChange={handleChange}
                >
                  {
                    codeList && (codeList.receiptStatus.map((item) => (
                      <option key={item.code} value={item.code}>{item.title}</option>
                    )))
                  }
                </CustomSelectbox>
              </FormControl>
            </Grid>
            <Grid item>
              <ButtonSearchList 
                className="btnSchList" 
                title="변경"
                onClick={handleBtnClick}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject('receiveStore', 'commonStore')(observer(Title));