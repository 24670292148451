import React from 'react';
import { 
  Box, Button, Typography, 
  Dialog, DialogTitle, DialogContent, 
  DialogActions, IconButton
} from '@material-ui/core';

import FormResetPassword from './FormResetPassword';
import { BtnCloseDialog } from '../../../../static/images';


function PwdChangeDialog({ open, handleClose }) {
  return (
    <React.Fragment>
      <Dialog aria-labelledby="dialogTitle" open={open} onClose={handleClose}>
        <DialogTitle id="dialogTitle" disableTypography>
          <Typography component="h2" variant="h1">비밀번호 변경</Typography>
          <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
            <img src={BtnCloseDialog} alt="닫기" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormResetPassword className={"small"}/>
        </DialogContent>
        <DialogActions>
          <Box className="pageMainButtons">
            <Button variant="contained" color="primary">비밀번호 변경</Button>
            <Button variant="contained" color="secondary" onClick={handleClose}>취소</Button>
            {/* <Button variant="contained" color="secondary">확인</Button> */}
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default PwdChangeDialog;