import { client } from '../lib/client';

// 배송 내역 조회
export const deliveryListAPI = (params) => (
  client({
    url: '/v1/admin/delivery/list',
    params,
  }, true)
);

// 방문자 조회
export const useVisitListAPI = (params) => (
  client({
    url: '/v1/admin/user/visit/list',
    params,
  }, true)
);