import { makeObservable, action, observable } from 'mobx';
import { loginAPI, meAPI } from '../api/account';
import { ERROR_505_MSG, TEST_TOCKE } from '../lib/message';
export default class Account {
  root;
  showAlert;
  commons;
  adminInfo = null;
  alertSountOnOff = true;

  constructor(root) {
    this.root = root;
    this.commons = this.root.commonStore;
    this.showAlert = this.root.alertStore.showAlert;
    makeObservable(this, {
      adminInfo: observable,
      alertSountOnOff: observable,
      loginCall: action,
      meCall: action,
    });
  };

  meCall = async() => {
    try{
      const resp = await meAPI();
      const { data, status } = resp;
      this.commons.codeListSel();
      if (status !== 200) throw status;
      this.adminInfo = data;
    }catch(error) {
      console.log('%c##### Error-meCall: ', 'color: red', error);
    }
  };

  // 로그인 API 호출
  loginCall = async (params, history) =>  {

    if (!params.adminId) {
      this.showAlert('아이디를 입력하세요');
      return;
    } else if (!params.password) {
      this.showAlert('비밀번호를 입력하세요.');
      return;
    }

    try {
      const resp = await loginAPI(params);
      const { data, status } = resp;

      if (status !== 200) {
        if (!data.message) throw status;
        else {
          this.showAlert(data.message);
          return;
        }
      }

      // 아이디 체크박스가 true면 저장
      if (params.saveId) {
        localStorage.setItem('qadmid', params.adminId);
      } else {
        localStorage.removeItem('qadmid');
      }

      // token 저장
      await localStorage.setItem('qadmtk', data.token);
      await this.meCall();
      
      history.push('/operatestate');
      
    } catch (error) {
      this.showAlert(ERROR_505_MSG.title, ERROR_505_MSG.content);
      console.log('%c##### Error-loginCall: ', 'color: red', error);
    }
  }

  
  alertOnOff = () => {
    const thisAlert = localStorage.getItem('alertSountOnOff');
    this.alertSountOnOff = thisAlert === 'true' ? false : true;
    localStorage.setItem('alertSountOnOff', thisAlert === 'true' ? false : true);
  }
}