import { makeObservable, action, observable } from 'mobx';
import { dateGroupSet } from '../lib/util';
import { codeListAPI, fileUploadAPI } from '../api/commons';
import { setCodeList } from '../lib/util';
export default class CommonsStore {
  root;

  codeList = "";
  companyName = "";
  companyId = "";
  daumAddress = "";
  snackbar = {
    open: false,
    variant: 'info',
    time: 2000,
    vertical: 'bottom',
    horizontal: 'left',
    message: '',
  }

  sDate = new Date();
  eDate = new Date();

  constructor(root) {
    this.root = root;
    makeObservable(this, {
      companyName: observable,
      companyId: observable,
      daumAddress: observable,
      sDate: observable,
      eDate: observable,
      codeList: observable,
      snackbar: observable,
      setCompanyName: action,
      setDaumAddress: action,
      setFromPastToPresent: action,
      calendarDateChange: action,
      handleAlertClose: action,
    });
  };

  alertMsg = (message, time, vertical, horizontal, variant) => {
    this.snackbar = {
      message: message,
      vertical: vertical ? vertical : 'top',
      horizontal: horizontal ? horizontal : 'center',
      variant: variant ? variant : 'info',
      time: time ? time : 3000,
      open: true,
    };
  };

  handleAlertClose = () => {
    console.log("handleAlertClose");
    this.snackbar = {
      ...this.snackbar,
      open: false,
    };
  };

  // 회사명 검색
  setCompanyName = (item) => {
    this.companyName = item.companyName;
    this.companyId = item.companyKey;
  }

  // 주소 검색
  setDaumAddress = (address) => {
    this.daumAddress = address;
  }

  // 날짜 지정
  calendarDateChange = (name, value) => {
    if (name === 'sDate') this.sDate = value;
    else this.eDate = value;
  }
  
  // 현재날짜 기준 역순 날짜 조회
  setFromPastToPresent = (month) => {
    const result = dateGroupSet(month);
    this.sDate = result;
    this.eDate = new Date();
  }

  // 공통 코드 목록 조회
  codeListSel = async () => {
    try {
      const resp = await codeListAPI();
      const { status, data } = resp;
      if (status !== 200) throw resp;
      
      this.codeList = data;
      setCodeList(data)
    } catch (error) {
      console.log("codeList Sel Error: ", error);
    }
  }


  // 파일 업로드
  fileUpload = async (form) => {
    try {
      const resp = await fileUploadAPI(form);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      return data;
      
    } catch (error) {
      console.log('%c##### Error-fileUpload: ', 'color: red', error);
    }
  }
}