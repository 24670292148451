import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { IconSearch } from '../../../static/images';

const BtnSchList = withStyles(theme => ({
  root: {
    width: 66,
    height: 34,
    paddingLeft: 12,
    paddingRight: 11,
    border: `2px solid ${theme.palette.border[400]}`,
    borderRadius: 4,
    backgroundColor: '#fff',
    color: theme.palette.text.secondary,
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 2,
      color: theme.palette.border[100],
    },
    '&:hover': {
      backgroundColor: theme.palette.bgColor[100],
    },
  },
}))(Button);

const ButtonSearchList = props => {
  const { children, title = "조회", ...others } = props;
  return (
    <BtnSchList variant="outlined" startIcon={<img src={IconSearch} alt="" />} {...others}>{title}</BtnSchList>
  );
};

export default ButtonSearchList;