import React from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Typography, FormControl, TextField } from '@material-ui/core';

import { CustomSelectbox } from '../common/input';
import { ButtonSearchList } from '../common/button';
import FromPastToPresent from '../common/calendar/FromPastToPresent';

function SearchForm({ commonStore, oprtnStore }) {
  const params = {};
  const { codeList } = commonStore;
  const { deliveryListSel } = oprtnStore;

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    params[name] = value;
  };

  const onSubmit = () => {
    deliveryListSel(params);
  }
  return (
    <React.Fragment>
      <div className="listSearch">
        <FromPastToPresent />
      </div>
      <div className="listSearch">
        <Grid container direction="row" alignItems="center" className="schFormGroup">
          <Grid item>
            <Typography className="formLabel">서비스별 조회</Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <CustomSelectbox 
                inputProps={{ 'title': '서비스별조회 선택' }}
                name="orderType"
                onChange={handleChange}
              >
                <option value="">전체</option>
                {
                  codeList && (
                    codeList.orderType.map((item) => (
                      <option key={item.code} value={item.code}>{item.title}</option>
                    ))
                  )
                }
              </CustomSelectbox>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography className="formLabel">회원구분</Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <CustomSelectbox 
                inputProps={{ 'title': '회원구분 선택' }}
                name="isUser"
                onChange={handleChange}
              >
                <option value="">전체</option>
                <option value="Y">회원</option>
                <option value="N">비회원</option>
              </CustomSelectbox>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <CustomSelectbox 
                inputProps={{ 'title': '개인,기업 선택' }}
                name="userType"
                onChange={handleChange}
              >
                <option value="">전체</option>
                {
                  codeList && (
                    codeList.userType.map((item) => (
                      <option key={item.code} value={item.code}>{item.title}</option>
                    ))
                  )
                }
              </CustomSelectbox>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              placeholder="기업명, 이름, 아이디, 휴대폰번호 입력"
              className="searchKeyword"
            />
          </Grid>
          <Grid item>
            <ButtonSearchList 
              className="btnSchList" 
              onClick={onSubmit}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default inject('commonStore', 'oprtnStore')(observer(SearchForm));