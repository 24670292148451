import { client } from '../lib/client';

// 요금옵션 리스트
export const priceOptionsAPI = () => (
  client({
    url: '/v1/admin/price/options',
  }, true)
);

// 요금견적조회??
export const priceEstimateAPI = (data) => (
   client({
     url: '/v1/admin/price/estimate',
     method: 'post',
     data,
   }, true)
)

// 운임정보 엑셀 업로드
export const priceExcelUploadAPI = (data) => (
  client({
    url: '/v1/admin/prices',
    method: 'post',
    file: true,
    data,
  }, true)
);

// 운임정보 리스트 조회
export const priceListAPI = (params) => (
  client({
    url: '/v1/admin/price/list',
    params,
  }, true)
);

// 온오프 요금조회 리스트 조회
export const activationAPI = () => (
  client({
    url: '/v1/admin/price/option/activation',
  }, true)
);

// 온오프 요금조회 수정
export const activationModAPI = (data) => (
  client({
    url: '/v1/admin/price/option/activation',
    method: 'put',
    data,
  }, true)
);

// 운임정보 업데이트
export const priceModAPI = (data) =>  (
  client({
    url: '/v1/admin/price',
    method: 'put',
    data,
  }, true)
);

// 운임정보 옵션 업데이트
export const priceOptionsModAPI = (data) => (
  client({
    url: '/v1/admin/price/options',
    method: 'put',
    data
  }, true)
);

// 운임정보 엑셀 다운로드
export const priceExcelDown = () => (
  client({
    url: '/v1/admin/download/excel', 
    type: 'excel'
  }, true)
);