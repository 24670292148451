import React, { useEffect } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { 
  Typography, Table, TableHead, Box,
  TableRow, TableCell, Button, TableBody 
} from '@material-ui/core';
import { withRouter, useParams } from 'react-router-dom';

import { Pagination } from '../common';
import { codeTitle, pagingRowNum } from '../../lib/util';

function ServiceUseList({ history, handleDialog, cmStore }) {
  const uParams = useParams();
  const { memberUseSel, memberUse: list, mbuParams: params } = cmStore;

  useEffect(() => {
    memberUseSel(uParams.id);
  }, [])

  const handleClick = (path) => {
    history.push(path);
  };

  const handlePaging = (value, name) => {
    if (name === 'page') params.page = value;
    else if (name === 'size') params.size = value;

    memberUseSel(uParams.id, params);
  }


  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">서비스 이용내역</Typography>
      <Table className="bbsListTable">
        <caption>GA 관리 목록</caption>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '6%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '*%' }} />
        </colgroup>
        <TableHead className="bbsListTableHead">
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">이용일시</TableCell>
            <TableCell align="center">구분</TableCell>
            <TableCell align="center">출발지</TableCell>
            <TableCell align="center">도착지</TableCell>
            <TableCell align="center">이용내역</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.content.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {pagingRowNum(list.total, params.size, params.page, index)}
                </TableCell>
                <TableCell align="center">
                  {moment(item.createDate).format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell align="center">
                  {
                    codeTitle('orderType', item.orderType)
                  }
                </TableCell>
                <TableCell align="center">{item.startAddr}</TableCell>
                <TableCell align="center">{item.endAddr}</TableCell>
                <TableCell align="center">
                  <Button
                    fullWidth
                    style={{ width: 200 }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handleClick(`/receive/${item.orderKey}`)}
                  >
                    자세히
              </Button>
                </TableCell>
              </TableRow>
            ))
          }
          {
            list.content.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">조회결과가 없습니다.</TableCell>
              </TableRow>
            )
          }
          
        </TableBody>
      </Table>
      <Pagination
        total={list.total}
        page={params.page}
        rowPage={params.size}
        handlePage={handlePaging}
      />
      <Box className="pageMainButtons">
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialog}
        >비밀번호변경</Button>
        <Button 
          variant="contained"
          color="secondary"
          onClick={() => handleClick('/member')}
        >목록</Button>
      </Box>
    </React.Fragment>
  );
}

export default inject('cmStore')(withRouter(observer(ServiceUseList)));