import React, { useEffect } from 'react';
import { inject } from 'mobx-react';

import ButtonAddIcon from '../components/common/button/ButtonAddIcon';
import TitleButtons from '../components/common/TitleButtons';
import CompanyList from '../components/CompanyPage/CompanyList';
import SearchForm from '../components/CompanyPage/SearchForm';

function CompanyPage({ history, cmStore }) {
  const { companySelCall } = cmStore;

  useEffect(() => {
    companySelCall();
  }, []);
  
  const handleClick = (path) => {
    history.push(path)
  }
  return (
    <React.Fragment>
      <TitleButtons title="기업조회">
        <ButtonAddIcon onClick={() => handleClick('/companyreg')}>기업등록</ButtonAddIcon>
      </TitleButtons>
      <SearchForm />
      <CompanyList />
    </React.Fragment>
  );
}

export default inject('cmStore')(CompanyPage);