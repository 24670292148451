import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import {
  Grid, Typography, FormControl, TextField, InputAdornment, FormControlLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import { CustomCheckbox, CustomSelectbox } from '../common/input';
import DaumPostcodeDialog from '../common/dialog/DaumPostcodeDialog';
import { numberComma, text } from '../../lib/util';

function SearchForm({ chargeStore, commonStore }) {
  const classes = useStyles();
  const { codeList, } = commonStore;
  const { priceOptions, totalPrice, priceEstimateSel, priceEstimateAdd } = chargeStore;

  const [addrType, setAddrType] = useState("");
  const [address, setAddress] = useState({ start: '', end: '' });
  const [addressCode, setAddressCode] = useState({ start: '', end: '' });
  const [addrOpen, setAddrOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [params] = useState({
    startPointCode: '',
    endPointCode: '',
    orderType: '',
    vehicleType: '',
    vehicleOptionType: '',
  })

  const handleChange = (e, checked) => {
    const value = e.target.value;
    const name = e.target.name;
    params[name] = value;
    handlepriceEstimateSel()

  }

  const handlePostcodeOnoff = useCallback((name) => {
    setAddrType(name);
    setAddrOpen(!addrOpen);
  }, [addrOpen]);

  const handleChangeAddress = (name, addr, code) => {
    setAddress((old) => ({
      ...old,
      [name]: addr
    }));

    setAddressCode((old) => ({
      ...old,
      [name]: code
    }))

    if (name === "start") {
      params["startPointCode"] = code;
    } else {
      params["endPointCode"] = code;
    }
    handlepriceEstimateSel()
  };

  const handlepriceEstimateSel = () => {
    let isCall = true;
    for (const [key, value] of Object.entries(params)) {
      if (key !== "vehicleOptionType") {
        if (!value) {
          isCall = false;
          return;
        }
      }
    }
    if (params && !params.vehicleOptionType) delete params.vehicleOptionType;
    if (isCall) priceEstimateSel(params, options);
  }

  const handlePrice = (e, checked, value) => {

    let temp = options;
    if (checked) {
      temp.push(value);
    } else {
      temp = _.remove(temp, function (n) {
        return n !== value;
      });      
    }
    setOptions(temp);
    priceEstimateAdd(checked, value);
  }
  if (!priceOptions) return null;

  const payoption1 = priceOptions ? [
    { title: `조조 +${numberComma(priceOptions.Morning[0])}`, tip: text(priceOptions.Morning[1]), value: priceOptions.Morning[0] },
    { title: `야간 +${numberComma(priceOptions.Night1[0])}`, tip: text(priceOptions.Night1[1]), value: priceOptions.Night1[0] },
    { title: `심야 +${numberComma(priceOptions.LateNight1[0])}`, tip: text(priceOptions.LateNight1[1]), value: priceOptions.LateNight1[0] },
    { title: `휴일 +${numberComma(priceOptions.Holiday[0])}`, tip: text(priceOptions.Holiday[1]), value: priceOptions.Holiday[0] },
  ] : [];

  const payoption2 = priceOptions ? [
    { title: `급송 +${numberComma(priceOptions.Express1[0])}`, tip: text(priceOptions.Express1[1]), value: priceOptions.Express1[0] },
    { title: `눈비 +${numberComma(priceOptions.SnowRain[0])}`, tip: text(priceOptions.SnowRain[1]), value: priceOptions.SnowRain[0] },
    { title: `명절 +${numberComma(priceOptions.NationalHoliday[0])}`, tip: text(priceOptions.NationalHoliday[1]), value: priceOptions.NationalHoliday[0] },
    { title: `1박 +${numberComma(priceOptions.LateNight2[0])}`, tip: text(priceOptions.LateNight2[1]), value: priceOptions.LateNight2[0] },
  ] : [];

  const movepay = [
    { title: `계단 +${numberComma(priceOptions.Stairs[0])}`, value: priceOptions.Stairs[0] },
    { title: `수작업(같이) +${numberComma(priceOptions.WithC[0])}`, value: priceOptions.WithC[0] },
    { title: `수작업(기사만) +${numberComma(priceOptions.Alone[0])}`, value: priceOptions.Alone[0] },
  ];

  return (
    <React.Fragment>
      <DaumPostcodeDialog
        open={addrOpen}
        name={addrType}
        handleClose={handlePostcodeOnoff}
        handleChange={handleChangeAddress}
      />
      <Grid container className="listSearch">
        <Grid item>
          <Grid container direction="row" alignItems="center" className="schFormGroup">
            <Grid item>
              <Typography className={clsx("formLabel", classes.bold)}>구분</Typography>
            </Grid>
            <Grid item>
              <FormControl>
                <CustomSelectbox 
                  inputProps={{ 'title': '서비스별조회 선택' }}
                  name="orderType"
                  onChange={handleChange}
                >
                  <option value="">전체</option>
                  {
                    codeList && (codeList.orderType.map((item) => (
                    <option key={item.code} value={item.code}>{item.title}</option>
                  )))
                }
                </CustomSelectbox>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography className={clsx("formLabel", classes.bold)}>*예상요금</Typography>
            </Grid>
            <Grid item>
              <TextField
                placeholder="금액"
                className="searchKeyword"
                value={numberComma(totalPrice)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="listSearch">
        <Grid container direction="row" alignItems="center" className="schFormGroup">
          <Grid item>
            <Typography className={clsx("formLabel", classes.bold)}>배송물</Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <CustomSelectbox 
                inputProps={{ 'title': '배송물종류 선택' }}
              >
                <option value="">전체</option>
                {
                  codeList && (codeList.freightShipmentType.map((item) => (
                    <option key={item.code} value={item.code}>{item.title}</option>
                  )))
                }
                {
                  codeList &&  (codeList.quickShipmentType.map((item) => (
                    <option key={item.code} value={item.code}>{item.title}</option>
                  )))
                }
                {
                  codeList && (codeList.moveShipmentType.map((item) => (
                    <option key={item.code} value={item.code}>{item.title}</option>
                  )))
                }
              </CustomSelectbox>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              placeholder="중량기준노출"
              className="searchKeyword"
            />
          </Grid>
          <Grid item>
            <TextField
              type="number"
            />
          </Grid>
          <Grid item>
            <Typography className={clsx("formLabel", classes.bold)}>배송차량</Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <CustomSelectbox 
                inputProps={{ 'title': '배송물종류 선택' }}
                name="vehicleType"
                onChange={handleChange}
              >
                <option value="">차량</option>
                {
                  codeList && (codeList.deliveryVehicleType.map((item) => (
                    <option key={item.code} value={item.code}>
                      {item.title === "바이크" ? "오토바이" : item.title}
                    </option>
                  )))
                }
              </CustomSelectbox>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <CustomSelectbox 
                inputProps={{ 'title': '배송물종류 선택' }}
                name="vehicleOptionType"
                onChange={handleChange}
              >
                <option value="">옵션</option>
                {
                  codeList && (codeList.deliveryVehicleOptionType.map((item) => (
                    <option key={item.code} value={item.code}>{item.title}</option>
                  )))
                }
              </CustomSelectbox>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="listSearch">
        <Grid container direction="row" alignItems="center" className="schFormGroup">
          <Grid item>
            <Typography className={clsx("formLabel", classes.bold)}>출발지</Typography>
          </Grid>
          <Grid item>
            <Typography className="formLabel">주소</Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder="주소"
              value={address.start}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end"><SearchIcon /></InputAdornment>
                ),
              }}
              className={classes.addressKeyword}
              onClick={() => handlePostcodeOnoff('start')}
            />
          </Grid>
          <Grid item>
            <Typography className="formLabel">상세주소</Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder="상세주소"
              className={classes.addressKeyword}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="listSearch">
        <Grid container direction="row" alignItems="center" className="schFormGroup">
          <Grid item>
            <Typography className={clsx("formLabel", classes.bold)}>도착지</Typography>
          </Grid>
          <Grid item>
            <Typography className="formLabel">주소</Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder="주소"
              value={address.end}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end"><SearchIcon /></InputAdornment>
                ),
              }}
              onClick={() => handlePostcodeOnoff('end')}
              className={classes.addressKeyword}
            />
          </Grid>
          <Grid item>
            <Typography className="formLabel">상세주소</Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder="상세주소"
              className={classes.addressKeyword}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="listSearch">
        <Grid container direction="row" alignItems="center" className="schFormGroup">
          <Grid item>
            <Typography className={clsx("formLabel", classes.bold)}>상차</Typography>
          </Grid>
          {
            movepay.map((item, index) => (
              <Grid item key={index}>
                <FormControlLabel
                  label={item.title}
                  control={<CustomCheckbox />}
                  style={{ marginRight: 6 }}
                  name="options"
                  onChange={(e, checked) => handlePrice(e, checked, item.value)}
                />
              </Grid>
            ))
          }
        </Grid>
      </Grid>
      <Grid container className="listSearch">
        <Grid container direction="row" alignItems="center" className="schFormGroup">
          <Grid item>
            <Typography className={clsx("formLabel", classes.bold)}>하차</Typography>
          </Grid>
          {
            movepay.map((item, index) => (
              <Grid item key={index}>
                <FormControlLabel
                  label={item.title}
                  control={<CustomCheckbox />}
                  style={{ marginRight: 6 }}
                  name="options"
                  onChange={(e, checked) => handlePrice(e, checked, item.value)}
                />
              </Grid>
            ))
          }
        </Grid>
      </Grid>
      <Grid container className="listSearch">
        <Grid container direction="row" alignItems="center" className="schFormGroup">
          <Grid item>
            <Typography className={clsx("formLabel", classes.bold)}>결제옵션</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="center" className="schFormGroup">
        <Grid
          item
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          style={{ width: 380 }}
        >
          {
            payoption1.map((item, index) => (
              <Grid item key={index} style={{ paddingBottom: 10 }}>
                <FormControlLabel
                  label={
                    <span><b>{item.title}</b></span>
                  }
                  control={
                    <CustomCheckbox />
                  }
                  style={{ marginRight: 6 }}
                  name="options"
                  onChange={(e, checked) => handlePrice(e, checked, item.value)}
                />
                <Typography style={{ paddingLeft: 30 }} className="formLabel">
                  {item.tip}
                </Typography>
              </Grid>

            ))
          }
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          style={{ width: 380 }}
        >
          {
            payoption2.map((item, index) => (
              <Grid item key={index} style={{ paddingBottom: 10 }}>
                <FormControlLabel
                  label={
                    <span><b>{item.title}</b></span>
                  }
                  control={<CustomCheckbox />
                  }
                  style={{ marginRight: 6 }}
                  name="options"
                  onChange={(e, checked) => handlePrice(e, checked, item.value)}
                />
                <Typography style={{ paddingLeft: 30 }} className="formLabel">
                  {item.tip}
                </Typography>
              </Grid>

            ))
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  bold: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  addressKeyword: {
    '& input': {
      width: '385px !important',
    }
  }
}));

export default inject('chargeStore', 'commonStore')(observer(SearchForm));