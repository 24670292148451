import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { 
  Box, Table, TableBody, Typography, 
  TableRow, TableCell, FormControl, 
  TextField, TableHead, Grid, FormControlLabel 
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import { CustomCheckbox, CustomSelectbox } from '../common/input';
import CalendarTime from '../common/calendar/CalendarTime';

import { text } from '../../lib/util';

function DeliverInfo({ receiveStore, commonStore, handleParams }) {
  const { codeList } = commonStore;
  const { orderDet } = receiveStore;
  const [data, setData] = useState(orderDet);

  useEffect(() => {
    if (!orderDet) return;
    setData({
      ...JSON.parse(JSON.stringify(orderDet)),
      isMove: orderDet.orderType === 'Move' ? 'Y' : 'N'
    });
  }, [orderDet]);

  useEffect(() => {
    console.log({ data });
  }, [data])

  const updownOption = [
    { "code" : "Direct", "title" : "직접" },
    { "code": "WithC", "title": "고객과같이 상하차" },
    { "code": "Alone", "title": "기사님혼자 상하차" }
  ];

  const routeOptions = [
    { "code": "OneWay", "title": "편도" },
    { "code": "RoundTrip", "title": "왕복"},
    { "code": "LayOver",  "title": "경유" }
  ]

  const handleChange = (e, checked) => {
    let value = e.target.value;
    let name = e.target.name;

    if (name === 'isExpress' || name === 'isMove') {
      if (checked) value = 'Y'
      else value = 'N'
    }

    if (name === 'isPassengerCnt') {
      name = 'passengerCnt'
      if (!checked) {
        value = 0;
      } else {
        value = 1;
      }
    } 

    if (name === 'isWorkerCnt') {
      name = 'workerCnt'
      if (!checked) {
        value = 0;
      } else {
        value = 1;
      }
    }
    setData((old) => ({
      ...old,
      [name]: value
    }));

    handleParams({ [name]: value })

  }
  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">배송정보</Typography>
      <Box className="section">
        <Grid style={{marginBottom: 5}} container direction="row" justify="flex-end" alignItems="center">
          <FormControlLabel
            className="tableFormControl checkbox"
            label={
              <span style={{marginRight: 8}}>이사규모</span>
            }
            control={
              <CustomCheckbox 
                name="isMove"
                checked={data.isMove === 'Y'}
                onChange={handleChange}
              />
            }
          />
          <div className="tableFormControl">
            <FormControl fullWidth disabled={data.isMove !== 'Y'}>
              <CustomSelectbox 
                inputProps={{ 'title': '이사규모 선택' }}
                name="moveType"
                value={data.moveType}
                onChange={handleChange}
              >
                <option value="">규모를 선택하세요</option>
                {
                  codeList && (
                    codeList.moveShipmentType.map((item) => (
                      <option key={item.code} value={item.code}>{item.title}</option>
                    ))
                  )
                }
              </CustomSelectbox>
            </FormControl>
          </div>
        </Grid>
        {
          data.isMove !== 'Y' && (
            <Table className="dataTable horizontal">
              <colgroup>
                <col style={{ width: '%' }} />
                <col style={{ width: '%' }} />
                <col style={{ width: '%' }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">물품종류</TableCell>
                  <TableCell align="center">수량</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" rowSpan={8}>배송물</TableCell>
                  <TableCell align="center">서류</TableCell>
                  <TableCell align="center">{data.documentCnt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">소박스</TableCell>
                  <TableCell align="center">{data.smallBoxCnt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">중박스</TableCell>
                  <TableCell align="center">{data.heavyBoxCnt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">대박스</TableCell>
                  <TableCell align="center">{data.greateBoxCnt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">팔레트</TableCell>
                  <TableCell align="center">{data.paletteCnt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">박스</TableCell>
                  <TableCell align="center">{data.boxCnt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">가구/집기</TableCell>
                  <TableCell align="center">{data.fuCnt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">기타</TableCell>
                  <TableCell align="center">{data.etcCnt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )
        }
      </Box>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '18%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '12%' }} />
            <col style={{ width: '14%' }} />
            <col style={{ width: '12%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">배송차량</TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      inputProps={{ 'title': '배송차량 선택' }}
                      name="vehicleType"
                      value={text(data.vehicleType)}
                      onChange={handleChange}
                    >
                      <option value="">
                        {data.isMove ? "상담 후 선택" : "선택"}
                      </option>
                      {
                        codeList && (
                          codeList.deliveryVehicleType.map((item) => (
                            <option key={item.code} value={item.code}>
                              {
                                item.title === '바이크' ? '오토바이' :  item.title
                              }
                            </option>
                          ))
                        )
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">
                차량옵션
                {/* <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox />
                </FormControl> */}
              </TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      inputProps={{ 'title': '차량옵션 선택' }}
                      name="vehicleOptionType"
                      value={text(data.vehicleOptionType)}
                      onChange={handleChange}
                    >
                      <option value="">선택</option>
                      {
                        codeList && (
                          codeList.deliveryVehicleOptionType.map((item) => (
                            <option key={item.code} value={item.code}>
                              {
                                item.title === '냉동탑' ? '탑' : item.title
                              }
                            </option>
                          ))
                        )
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">상차시간</TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl" style={{width: '100%'}}>
                  <CalendarTime 
                    name="reservationTime"
                    value={
                      data && data.reservationTime !== 0
                      ? new Date(data.reservationTime)
                      :  ""
                    }
                    handleChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">상차방법</TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      inputProps={{ 'title': '상차방법 선택' }}
                      name="loadingMethod"
                      value={text(data.loadingMethod)}
                      onChange={handleChange}
                    >
                      <option value="">선택</option>
                      {
                        updownOption.map((item) => (
                          <option key={item.code} value={item.code}>{item.title}</option>
                        ))
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">하차시간</TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl" style={{ width: '100%' }}>
                  <CalendarTime
                    name="reservationDownTime"
                    value={
                      data && data.reservationDownTime !== 0
                        ? new Date(data.reservationDownTime)
                        : ""
                    }
                    handleChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">하차방법</TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox 
                      inputProps={{ 'title': '상차방법 선택' }}
                      name="dropMethod"
                      value={text(data.dropMethod)}
                      onChange={handleChange}
                    >
                      <option value="">선택</option>
                      {
                        updownOption.map((item) => (
                          <option key={item.code} value={item.code}>{item.title}</option>
                        ))
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">배송경로</TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl">
                  <FormControl fullWidth>
                    <CustomSelectbox
                      inputProps={{ 'title': '상차방법 선택' }}
                      name="route"
                      value={text(data.route)}
                      onChange={handleChange}
                    >
                      <option value="">선택</option>
                      {
                        routeOptions.map((item) => (
                          <option key={item.code} value={item.code}>{item.title}</option>
                        ))
                      }
                    </CustomSelectbox>
                  </FormControl>
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">
                경유
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox 
                  />
                </FormControl>
              </TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value=""
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">
                급송
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox 
                    name="isExpress"
                    checked={data.isExpress === 'Y'}
                    onChange={handleChange}
                  />
                </FormControl>
                차량동승
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox 
                    name="isPassengerCnt"
                    checked={data.passengerCnt > 0}
                    onChange={handleChange}
                  />
                </FormControl>
              </TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    name="passengerCnt"
                    value={data.passengerCnt}
                    type="number"
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">
                인부추가
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox 
                    name="isWorkerCnt"
                    checked={data.workerCnt > 0}
                    onChange={handleChange}
                  />
                </FormControl>
              </TableCell>
              <TableCell colSpan={2}>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    type="number"
                    name="workerCnt"
                    value={data.workerCnt}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
}

export default inject("receiveStore", "commonStore")(observer(DeliverInfo));