import { makeAutoObservable, action, observable } from 'mobx';
import { 
  priceEstimateAPI, priceExcelUploadAPI, activationAPI, activationModAPI,
  priceListAPI, priceModAPI, priceOptionsAPI, priceOptionsModAPI
} from '../api/charge';

export default class Charge {
  root;
  alert;
  showAlert;

  priceOptions = null;
  priceList = { content: [], total: 0 };
  pParams = { page: 1, size: 20 };
  activation = { isNationalHoliday: 'N', isSnowRain: 'N' };
  totalPrice = 0;
  constructor(root) {
    this.root = root;
    this.alert = this.root.alertStore;
    this.showAlert = this.alert.showAlert;

    makeAutoObservable(this, {
      priceList: observable,
      priceOptions: observable,
      pParams: observable,
      totalPrice: observable

    })
  };

  priceListInitial = () => {
    this.priceList = { content: [], total: 0 };
  }

  // 요금 옵션 리스트
  priceOptionsSel = async () => {
    try {
      const resp = await priceOptionsAPI();
      const { status, data } = resp;
      if (status !== 200) throw resp;

      // const optionList = {
      //   Morning: '',  //조조
      //   Night1: '',   // 야간
      //   LateNight1: '', // 심야
      //   Express1: '',   // 급송
      //   NationalHoliday: '',  //명정
      //   Holiday: '',  // 휴일
      //   Worker: '', // 인부
      //   Stairs: '', //계단
      //   Passenger: '', //동승 //
      //   Alone: '', // 기사님 혼자 상하차
      //   Lift: '', // 리프트
      //   FreezingTower: '', // 냉동탑

      // }
      let options = {}
      data.forEach((item, index) => {
        options[item.cd] = [item.value, item.desc];
      });

      this.priceOptions = options;

    } catch (error) {
      console.log("### priceOptionsSel error: ", error);
    }
  };

  // 운임정보 엑셀 업로드
  priceExcelUploadCall = async (form) => {
    try {
      const resp = await priceExcelUploadAPI(form);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.alert.showAlert("수정 되었습니다.");
      return data;
    } catch (error) {
      console.log("### priceExcelUploadCall error: ", error);
      this.alert.showAlert("잠시 후 다시 시도해 주세요.");
    }
  };

  // 운임정보 리스트
  priceListSel = async (parmas) => {
    const p = {
      ...this.pParams,
      ...parmas
    }
    console.log( {p });
    try {
      const resp = await priceListAPI(p);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.priceList = { content: data.content, total: data.totalElements };

    } catch (error) {
      console.log("### priceListSel error: ", error);
    }
  }

  // 운임정보 업데이트
  priceModCall = async (data) => {
    try {
      const resp = await priceModAPI(data);
      const { status } = resp;
      if (status !== 201) throw resp;
    } catch (error) {
      console.log("### priceModCall error: ", error);
    }
  };

  // 온오프 운임정보 조회
  activationCall = async () => {
    try {
      const resp = await activationAPI();
      const { status, data } = resp;
      if (status !== 200) throw resp;
      this.activation = data;
    } catch (error) {
      console.log("### activationAPI error: ", error);
    }
  };

  // 온오프 운임정보 수정
  activationModCall = async (data) => {
    try {
      const resp = await activationModAPI(data);
      const { status } = resp;
      if (status !== 200) throw resp;
    } catch (error) {
      console.log("### activationModCall error: ", error);
    }
  };

  //운임정보 옵션 업데이트
  priceOptionsModCall = async (data) => {
    this.alert.defaultAlertOpen({
      cancel: true,
      leftBtnTitle: '취소',
      rightBtnTitle: '수정',
      title: '수정 하시겠습니까?',
      handleOk: async () => {
        try {
          const resp = await priceOptionsModAPI(data);
          const { status } = resp;
          if (status !== 200) throw resp;
          this.alert.defaultAlertClose();
        } catch (error) {
          console.log("### priceOptionsModCall error: ", error);
        }
      }
    });
  }

  // 요금 조회 
  priceEstimateSel = async (params, options) => {
    try {
      const resp = await priceEstimateAPI(params);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.totalPrice = data.totalPrice;
      if (options.length === 0) return;

      options.forEach((item) => {
        this.totalPrice += item;
      });

    } catch (error) {
      console.log('%c##### Error-priceEstimateSel: ', 'color: red', error);
    }
  }

  // 요금 더하기
  priceEstimateAdd = async (checked, value) => {
    if (checked) this.totalPrice += Number(value);
    else this.totalPrice -= Number(value);
  }
}
