import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { AllInbox, LocalShipping, HowToReg, CalendarTodayTwoTone, People } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

function Situationboard({ opStore }) {
  const { dayCount } = opStore;
  const classes = useStyles();

  if (!dayCount) return null;

  return (
    <React.Fragment>
      <Typography className={classes.tableSubDesc}>
        <CalendarTodayTwoTone className="icon" />
        <span className="label">{moment(new Date()).format('YYYY/MM/DD')}</span>
      </Typography>
      
      <Grid
        container
        spacing={10}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <div className={classes.cardIcon}>
              <People className={classes.icon} />
            </div>
            <div className={classes.cardText}>
              <p>방문자수</p>
              <h3>{dayCount.visitCnt}</h3>
              <span>명</span>
              <p>&nbsp;</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <div className={classes.cardIcon}>
              <AllInbox className={classes.icon}/>
            </div>
            <div className={classes.cardText}>
              <p>전체</p>
              <h3>{dayCount.totalQuickCnt + dayCount.totalFreightCnt + dayCount.totalMoveCnt}</h3>
              <span>건</span>
              <p>(퀵:{dayCount.totalQuickCnt}/화물:{dayCount.totalFreightCnt}/이사{dayCount.totalMoveCnt})</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <div className={classes.cardIcon}>
              <LocalShipping className={classes.icon} />
            </div>
            <div className={classes.cardText}>
              <p>진행중</p>
              <h3>{dayCount.proceedingQuickCnt + dayCount.proceedingFreightCnt + dayCount.proceedingMoveCnt}</h3>
              <span>건</span>
              <p>(퀵:{dayCount.proceedingQuickCnt}/화물:{dayCount.proceedingFreightCnt}/이사{dayCount.proceedingMoveCnt})</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <div className={classes.cardIcon}>
              <HowToReg className={classes.icon} />
            </div>
            <div className={classes.cardText}>
              <p>완료</p>
              <h3>{dayCount.completeQuickCnt + dayCount.completeFreightCnt + dayCount.completeMoveCnt}</h3>
              <span>건</span>
              <p>(퀵:{dayCount.completeQuickCnt}/화물:{dayCount.completeFreightCnt}/이사{dayCount.completeMoveCnt})</p>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  tableSubDesc: {
    marginTop: 20,
    marginBottom: 10,
    // textAlign: 'right',
    '& .label': {
      marginRight: 10,
      paddingTop: 2,
      fontSize: 16,
      color: '#999'
    },
    '& .icon': {
      width: 18,
      marginRight: 5,
      paddingBottom: 5,
      color: theme.palette.border[300],
      verticalAlign: 'middle',
    }
  },
  paper: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: 8,
    padding: '1.25rem !important;'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 24,
  },
  cardText: {
    marginLeft: '1rem!important;',
    '& span': {
      fontWeight: 600
    },
    '& h3': {
      fontSize: '32px!important;',
      marginTop: '0.25rem!important;',
      margin: '0 0 0.5rem',
      display: '-webkit-inline-box',
    },
    '& p': {
      color: 'rgba(52, 49, 76, 0.54) !important',
      margin: 0,
      fontSize: 15,
      fontWeight: 600
    }
  }
}));

export default inject('opStore')(observer(Situationboard));