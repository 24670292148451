import { action, makeObservable, observable } from 'mobx'

export default class Alert {
  root;
  callback;
  show = false;
  message = "";
  defaultAlert = {
    cancel: false,
    content: '',
    title: '',
    handleClose: null,
    handleOk: null,
    open: false
  };

  constructor(root) {
    this.root = root;

    makeObservable(this, {
      show: observable,
      message: observable,
      defaultAlert: observable,
      showAlert: action,
      defaultAlertClose: action,
      defaultAlertOpen: action
    });
  };

  showAlert = (message, content) => {

    this.defaultAlertOpen({
      cancel: false,
      leftBtnTitle: '확인',
      title: message,
      content: content ? content : '',
      handleOk: () => {
        this.defaultAlertClose();
      }
    });
  }

  defaultAlertClose = () => {
    this.defaultAlert = {
      cancel: false,
      content: '',
      title: '',
      handleClose: null,
      handleOk: null,
      open: false
    }
  };

  defaultAlertOpen = (props) => {
    const { open } = this.defaultAlert;
    const { cancel, content, leftBtnTitle, rightBtnTitle, handleOk, title, handleClose } = props;
    this.defaultAlert = {
      open: true,
      cancel,
      title,
      content,
      leftBtnTitle,
      rightBtnTitle,
      handleOk: handleOk ? handleOk : this.defaultAlertClose,
    }
  };

}