import { client } from '../lib/client';

// 일주문카운트
export const dayCountAPI = () => (
  client({
    url: '/v1/admin/day/count',
  }, true)
);

// 주문현황리스트
export const orderListAPI = (params) => (
  client({
    url: '/v1/admin/order/list',
    params
  }, true)
);