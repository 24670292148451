import { client } from '../lib/client';

// 관리자 로그인
export const loginAPI = (data) => (
  client({
    url: '/v1/admin/login',
    method: 'post',
    data
  })
);

// 로그인 정보 me
export const meAPI = () => (
  client({
    url: '/v1/admin/me',
  }, true)
);