import palette from './palette';

export default {
  fontFamily: ['"Nanum Gothic"', '"Malgun Gothic"', '"Sans-serif"'].join(','),
  h1: {
    marginBottom: 16,
    fontWeight: '700',
    fontSize: 26,
  },
  h2: {
    marginBottom: 15,
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 1.375,
    color: palette.black,
    '& .small': {
      fontWeight: 400,
    },
  },
  subtitle1: {
    display: 'block',
    marginBottom: 15,
    fontWeight: 700,
    fontSize: 13,
    color: palette.tertiary.main,
  },
  subtitle2: {
    fontSize: 16,
  },
  body1: {
    fontSize: 13,
  },
  body2: {
    fontSize: 13,
  },
  caption: {
    fontWeight: '700',
    fontSize: 13,
    color: palette.black,
  },
}