import React from 'react';
import clsx from 'clsx';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

const useStyles = makeStyles(theme => ({
  root: {
    '& .icoCheckbox': {
      display: 'block',
      border: `1px solid ${theme.palette.border[100]}`,
      borderRadius: 2,
      backgroundColor: '#fff',
      width: 20,
      height: 20,
      boxShadow: 'none',
      '&.icoCheckboxChecked': {
        borderColor: theme.palette.primary.main,
        '& .icon': {
          width: 18,
          height: 18,
          verticalAlign: 'top',
          color: theme.palette.primary.main
        }
      },
    },
    '&.disabled .icoCheckbox': {
      backgroundColor: theme.palette.bgColor.disabled,
      '&.icoCheckboxChecked': {
        borderColor: theme.palette.border[100],
        '& .icon': {
          color: theme.palette.border[100],
        }
      }
    },
    '&.invert': {
      '& .icoCheckbox': {
        '&.icoCheckboxChecked': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          '& .icon': {
            color: theme.palette.white,
          }
        }
      },
      '&.disabled .icoCheckbox': {
        backgroundColor: theme.palette.bgColor.disabled,
        '&.icoCheckboxChecked': {
          borderColor: theme.palette.border[100],
          backgroundColor: theme.palette.border[100],
          '& .icon': {
            color: theme.palette.bgColor.disabled,
          }
        }
      },
    },
  },
}));

const CustomCheckbox = props => {
  const classes = useStyles();

  const { className, ...others } = props;

  return (
    <Checkbox
      icon={<span className="icoCheckbox"></span>}
      checkedIcon={<span className={clsx("icoCheckbox", "icoCheckboxChecked")}><CheckRoundedIcon className="icon" /></span>}
      color="default"
      classes={{ disabled: 'disabled' }}
      className={clsx(classes.root, className)}
      {...others}
    />
  );
};

export default CustomCheckbox;