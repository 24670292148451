import React, { useEffect } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Box, Table, TableBody, Typography, TableRow, TableCell, TextField } from '@material-ui/core';
import { codeTitle, text } from '../../lib/util';

function MemberInfo({ cmStore }) {
  const { memberData: item } = cmStore;

  if (!item) return null;
  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">가입정보</Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">회원구분</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={
                      item.userType ? codeTitle("userType", item.userType) : '일반'
                    }
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">가입/등록</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={moment(item.createdDate).format('YYYY-MM-DD HH:mm')}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">약관동의</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={moment(item.agreeDate).format('YYYY-MM-DD HH:mm')}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">아이디</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={text(item.userId)}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">전화번호</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={text(item.phone)}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">이름</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={text(item.name)}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">통신사</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={text(item.carrier)}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">성별</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={text(item.sex)}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">생년월일</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={text(item.birth)}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>

      {/* ################## 기업담당자 정보 ##################*/}

      <Typography component="h2" variant="h2">기업담당자 정보</Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">기업정보</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={item.companyName}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">직급</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={item.position}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">부서</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={item.department}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">내선번호</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={item.extensionNumber}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">특이사항</TableCell>
              <TableCell colSpan={3}>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={text(item.specialNote)}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
}

export default inject('cmStore')(observer(MemberInfo));