import React, { useEffect } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLocation, useHistory } from 'react-router-dom';

import SidebarNav from './SidebarNav';
import Profile from '../common/Profile';

const useStyles = makeStyles(theme => ({
  '@global': {
    'html, body, #root': {
      height: '100%',
      backgroundColor: '#84d7ff',
    },
  },
  root: {
    position: 'relative',
    //width: 1180,
    height: '100%',
    //margin: '0 auto',
    paddingTop: 12,
    backgroundColor: '#84d7ff',
    //boxShadow: '5px 0 30px rgba(29, 46, 66, 0.12)'
  },
  gnb: {
    width: 250,
  },
  contents: {
    position: 'relative',
    width: 'calc(100% - 250px)',
    //width: 'calc(100% - 330px)',
    minWidth: 930,
    minHeight: '100%',
    marginLeft: 250,
    paddingTop: 25,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 180,
    borderRadius: '20px 0 0 0',
    background: '#fafafa',
  },
  skipNav: {
    position: 'fixed',
    top: -42,
    left: 16,
    padding: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: '#fff',
    fontWeight: '700',
    color: theme.palette.primary.main,
    opacity: 0,
    transition: '0.3s',
    zIndex: 1000,
    '&:focus': {
      top: 16,
      opacity: 1,
    }
  },
  sidebar: {
    position: 'fixed',
    top: 0,
    //left: '50%',
    width: 250,
    height: '100%',
    overflowY: 'auto',
    //marginLeft: -590,
    paddingTop: 12,
    background: theme.palette.bgColor.gradient,
    zIndex: 100,
    '-ms-overflow-style': 'none',
    /* '@media (max-width: 1180px)' : {
        left: 0,
        marginLeft: 0,
    } */
  },
}));

const Default = props => {
  const { children, accountStore } = props;
  const { adminInfo } = accountStore;
  const classes = useStyles();
  const loaction = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!adminInfo) return;
    const { pathname } = loaction;
    const test = JSON.parse(JSON.stringify(adminInfo.adminPages));
    switch (pathname) {
      case "/operatestate":
        if (!_.includes(test, 1)) {
          alert("권한이 없습니다");
          history.push("/login");
        }
        break;
      case "/member":
        if (!_.includes(test, 2)) {
          alert("권한이 없습니다");
          history.push("/login");
        }
        break;
      case "/company":
        if (!_.includes(test, 3)) {
          alert("권한이 없습니다");
          history.push("/login");
        }
        break;
      case "/deliberyhistory":
        if (!_.includes(test, 4)) {
          alert("권한이 없습니다");
          history.push("/login");
        }
        break;
      case "/visitor":
        if (!_.includes(test, 5)) {
          alert("권한이 없습니다");
          history.push("/login");
        }
        break;
      case "/chargesel":
        if (!_.includes(test, 6)) {
          alert("권한이 없습니다");
          history.push("/login");
        }
        break;
      case "/pricemod":
        if (!_.includes(test, 7)) {
          alert("권한이 없습니다");
          history.push("/login");
        }
        break;
      case "/mangementofstaff":
        if (!_.includes(test, 8)) {
          alert("권한이 없습니다");
          history.push("/login");
        }
        break;
      case "/banner":
        if (!_.includes(test, 9)) {
          alert("권한이 없습니다");
          history.push("/login");
        }
        break;
    
      default:
        break;
    }
  }, [adminInfo])

  if (!adminInfo) return null;

  return (
    <React.Fragment>
      <Link href="#content" className={classes.skipNav}>본문 영역으로 바로가기</Link>
      <div className={classes.root}>
        <div className={classes.gnb}>
          <div className={classes.sidebar}>
            <Profile/>
            <SidebarNav adminInfo={adminInfo}/>
          </div>
        </div>
        <div id="content" className={classes.contents}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}

export default inject('accountStore')(observer(Default));