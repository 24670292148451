import React, { useState, useCallback, useEffect } from 'react';
import { inject } from 'mobx-react';
import { TitleButtons } from '../components/common';
import { ButtonAddIcon } from '../components/common/button';
import BannerList from '../components/BannerPage/BannerList';
import BannerDialog from '../components/BannerPage/BannerDialog';

function BannerPage({ bannerStore }) {
  const { bannerListSel } = bannerStore;
  const [regOpen, setRegDialog] = useState(false);
  const [modOpen, setModDialog] = useState(false);
  const [bannerKey, setBannerKey] = useState(null);

  useEffect(() => {
    bannerListSel();
  }, [])
  const handleRegDialogOnOff = useCallback(() => {
    setRegDialog(!regOpen);
  }, [regOpen])

  const handleModDialogOnOff = useCallback((key) => {
    if (!modOpen) setBannerKey(key);
    setModDialog(!modOpen);
  }, [modOpen]);

  return (
    <React.Fragment>
      <TitleButtons title="배너관리">
        <ButtonAddIcon
          style={{ width: 150 }}
          onClick={handleRegDialogOnOff}
        >배너등록</ButtonAddIcon>
      </TitleButtons>
      <BannerList handleModDialogOnOff={handleModDialogOnOff}/>
      <BannerDialog type="reg" open={regOpen} handleClose={handleRegDialogOnOff} />
      <BannerDialog type="mod" open={modOpen} handleClose={handleModDialogOnOff} bannerKey={bannerKey}/>
    </React.Fragment>
  );
}

export default inject('bannerStore')(BannerPage);