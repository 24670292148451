import React, { useEffect } from 'react';
import clsx from 'clsx';
import { observer, inject } from 'mobx-react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function SnackbarAlert({ commonStore }) {
  const cs = useStyles();
  const { snackbar, handleAlertClose } = commonStore;
  const { message, vertical, horizontal, variant, time, open } = snackbar;
  const Icon = variantIcon[variant];

  useEffect(() => {
    console.log(open);
  }, [open])
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={time}
      onClose={handleAlertClose}
    >
      <SnackbarContent
        className={cs[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={cs.message}>
            <Icon className={clsx(cs.icon, cs.iconVariant)} style={{ marginRight: 10 }} />
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleAlertClose}>
            <CloseIcon className={cs.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

export default inject('commonStore')(observer(SnackbarAlert));