import { createMuiTheme } from '@material-ui/core';

import palette from './palette.js';
import typography from './typography.js';
import overrides from './overrides.js';

const theme = createMuiTheme({
    spacing: 1,
    palette,
    typography,
    overrides,
    props: {
        MuiTableHead: {
            align: 'center',
        },
        MuiSelect: {
            native: true,
            variant: 'outlined',
        },
        MuiNativeSelect: {
            variant: 'outlined',
        },
        MuiInputBase: {
            fullWidth: true,
        },
        MuiInput: {
            disableUnderline: true,
        },
        MuiInputLabel: {
            shrink: true,
        },
        MuiTextField: {
            /* variant: 'filled', */
            variant: 'outlined',
        },
        MuiDialog: {
            maxWidth: false,
        },
        MuiDialogActions: {
            disableSpacing: true,
        },
        MuiLink: {
            underline: 'always',
        },
        MuiTypography: {
            variantMapping: {
                caption: 'strong',
            },
        },
        MuiExpansionPanel: {
            square: true,
            elevation: 0,
        },
        MuiPaper: {
            square: true,
        },
        MuiSwitch: {
            color: 'primary',
        },
    }
});

export default theme;