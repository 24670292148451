import { client } from '../lib/client';

export const bannerListAPI = (params) => (
  client({
    url: '/v1/admin/banner/list',
    params
  }, true)
);

export const bannerDetAPI = (id) => (
  client({
    url: `/v1/admin/banner/${id}`,
  }, true)
);

export const bannerRegAPI = (data) => (
  client({
    url: '/v1/admin/banner',
    method: 'post',
    data
  }, true)
);

export const bannerModAPI = (data) => (
  client({
    url: '/v1/admin/banner',
    method: 'post',
    data
  }, true)
);

// 배너 삭제
export const bannerDelAPI = (id) => (
  client({
    url: `/v1/admin/banner/${id}`,
    method: 'delete',
  }, true)
);