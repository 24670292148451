import React from 'react';
import EnhancedTable from './EnhancedTable';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import { testRow } from '../../lib/text';
import { isLoadingBar } from '../../lib/util';

function ChargeDet({ chargeStore }) {
  useStyles();
  const { priceList } = chargeStore;
  const row = testRow; 
  const [data, setData] = React.useState(React.useMemo(() => row, []))
  const [skipPageReset, setSkipPageReset] = React.useState(false)

  React.useEffect(() => {
    const temp = [];
    isLoadingBar(true);
    
    priceList.content.forEach((item) => {
      temp.push({
        startPoint: item.startPoint,
        endPoint: item.endPoint,
        bikePrice: item.bikePrice,
        damasPrice: item.damasPrice,
        laboPrice: item.laboPrice,
        benPrice: item.benPrice,
        ontTPrice: item.ontTPrice,
        onePointFourTPrice: item.onePointFourTPrice,
        twoPointFiveTPrice: item.twoPointFiveTPrice,
        threePointFiveTPrice: item.threePointFiveTPrice,
        fiveTPrice: item.fiveTPrice,
        eightTPrice: item.eightTPrice,
        elevenTPrice: item.elevenTPrice,
      })
    });
    if (temp.length > 0) isLoadingBar(false);
    console.log('%c##### debug-temp: ', 'color: #058FD7', );
    setData(temp);

  }, [priceList]);
  
  const columns = React.useMemo(
    () => [
      { Header: '출발지', accessor: 'startPoint' },
      { Header: '도착지', accessor: 'endPoint' },
      { Header: '오토바이', accessor: 'bikePrice' },
      { Header: '다바스', accessor: 'damasPrice' },
      { Header: '라보', accessor: 'laboPrice' },
      { Header: '벤', accessor: 'benPrice' },
      { Header: '1t', accessor: 'ontTPrice' },
      { Header: '1.4t', accessor: 'onePointFourTPrice' },
      { Header: '2.5t', accessor: 'twoPointFiveTPrice' },
      { Header: '3.5t', accessor: 'threePointFiveTPrice' },
      { Header: '5t', accessor: 'fiveTPrice' },
      { Header: '8t', accessor: 'eightTPrice' },
      { Header: '11t', accessor: 'elevenTPrice' },
    ],
    []
  )
  
  const updateMyData = async (rowIndex, columnId, value) => {
    setSkipPageReset(true)
    console.log({ rowIndex, columnId, value});
    await setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    ); 
  }


  return (
    <React.Fragment>
      {
        data && (
          <EnhancedTable 
            columns={columns}
            data={data}
            setData={setData}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
          />
        )
      }
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.mau-table': {
      width: 'initial'
    },
    '.MuiTablePagination-caption': {
      position: 'unset',
    },
    '.MuiTablePagination-input': {
      width: 100
    },
    '.MuiTableCell-footer': {
      border: 'none'
    },
  },
}));

export default inject('chargeStore')(observer(ChargeDet));