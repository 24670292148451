import React from 'react';
import { Title } from '../components/common';
import CompanyManagerRegForm from '../components/CompanyManagerRegPage/CompanyManagerRegForm';

function CompanyManagerRegPage(props) {
  return (
    <React.Fragment>
      <Title title="기업담당자 등록"/>
      <CompanyManagerRegForm />
    </React.Fragment>
  );
}

export default CompanyManagerRegPage;