import React, { useEffect } from 'react';
import { inject } from 'mobx-react';

import SearchForm from '../components/MemberPage/SearchForm';
import Title from '../components/MemberPage/Title';
import MemberList from '../components/MemberPage/MemberList';

function MemberPage({ cmStore }) {
  const { memberSelCall } = cmStore;
  useEffect(() => {
    memberSelCall();
  }, []);

  const onSubmit = (params) => {
    memberSelCall(params)
  }
  return (
    <React.Fragment>
      <Title />
      <SearchForm onSubmit={onSubmit}/>
      <MemberList />
    </React.Fragment>
  );
}

export default inject('cmStore')(MemberPage);