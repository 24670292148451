import React, { useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Button, ButtonGroup, Typography } from '@material-ui/core';

import Calendar from './Calendar';

function FromPastToPresent({ commonStore }) {
  const { setFromPastToPresent } = commonStore;
  const [month, setMonth] = useState('1M');

  const handleClick = useCallback((value) => {
    setMonth(value);
    setFromPastToPresent(value);
  }, [month]);
  
  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center" className="schFormGroup">
        <Grid item>
          <Typography className="formLabel">조회기간</Typography>
        </Grid>
        <Grid item>
          <Calendar name="sDate" />
        </Grid>
        <Grid item className="formGap">~</Grid>
        <Grid item>
          <Calendar name="eDate" />
        </Grid>
        <Grid item>
          <ButtonGroup variant="outlined" className="rounded btnSchListGroup">
            <Button 
              className={month === '1M' && 'on'}
              onClick={() => handleClick('1M')}
            >1개월</Button>
            <Button
              className={month === '2M' && 'on'}
              onClick={() => handleClick('2M')}
            >2개월</Button>
            <Button
              className={month === '3M' && 'on'}
              onClick={() => handleClick('3M')}
            >3개월</Button>
            <Button
              className={month === '6M' && 'on'}
              onClick={() => handleClick('6M')}
            >6개월</Button>
            <Button
              className={month === '1y' && 'on'}
              onClick={() => handleClick('1y')}
            >1년</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject('commonStore')(observer(FromPastToPresent));