import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, RadioGroup, FormControlLabel, TextField } from '@material-ui/core';

import CustomRadio from '../common/input/CustomRadio';
import ButtonSearchList from '../common/button/ButtonSearchList';

function SearchForm({ opStore }) {
  const params = {};
  const {
    orderListSel,
  } = opStore;
  const classes = useStyles();

  const handleChange = (e) => {
    const v = e.target.value;
    const n = e.target.name;
    params[n] = v;
  }

  const onSubmit = () => {
    orderListSel(params);
  }

  return (
    <React.Fragment>
      <Grid container className="listSearch">
        <Grid item>
          <Grid container direction="row" alignItems="center" className="schFormGroup">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Typography className="formLabel">검색필터</Typography>
                </Grid>
                <Grid item>
                  <RadioGroup defaultValue="1" row>
                    <FormControlLabel value="1" control={<CustomRadio />} label="전체" />
                    <FormControlLabel value="2" control={<CustomRadio />} label="미확인" style={{ marginLeft: 10, marginRight: 10 }}/>
                    <FormControlLabel value="3" control={<CustomRadio />} label="상담필요" />
                  </RadioGroup>
                </Grid>
                <Grid item>
                  <TextField
                    name="phone"
                    placeholder="이름, 아이디, 휴대폰번호 입력"
                    className={classes.searchKeyword}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ButtonSearchList className="btnSchList" onClick={onSubmit}/>
        </Grid>
      </Grid>
      <Grid container className="listSearch" direction="row" alignItems="center" justify="flex-end">
        <Grid item>
          <RadioGroup defaultValue="1" row>
            <FormControlLabel value="1" control={<CustomRadio />} label="전체" />
            <FormControlLabel value="2" control={<CustomRadio />} label="퀵서비스" style={{ marginLeft: 10, marginRight: 10 }} />
            <FormControlLabel value="3" control={<CustomRadio />} label="화물서비스" style={{ marginRight: 10 }}/>
            <FormControlLabel value="4" control={<CustomRadio />} label="이사서비스" />
          </RadioGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  searchKeyword: {
    '& input': {
      width: '220px !important',
    }
  }
}));

export default inject('opStore')(SearchForm);