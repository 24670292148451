import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Table, TableHead, TableRow, TableCell, TableBody, Button, FormControl } from '@material-ui/core';
import { Pagination } from '../common';
import { CustomCheckbox } from '../common/input';
import { codeTitle, pagingRowNum } from '../../lib/util';

function DeliveryHistoryList({ history, oprtnStore }) {
  const { deliveryListSel, dParams: params, deliveryList: list } = oprtnStore;
  const handleClick = (orderKey) => {
    history.push(`/deliberydet/${orderKey}`);
  }
  const handlePaging = (value, name) => {
    if (name === 'page') params.page = value;
    else if (name === 'size') params.size = value;

    deliveryListSel(params);
  }
  return (
    <React.Fragment>
      <Table className="bbsListTable">
        <caption>GA 관리 목록</caption>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '8%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '5%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '*%' }} />
        </colgroup>
        <TableHead className="bbsListTableHead">
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">구분</TableCell>
            <TableCell align="center">요청일시</TableCell>
            <TableCell align="center">회원구분</TableCell>
            <TableCell align="center">기업회원</TableCell>
            <TableCell align="center">기업명</TableCell>
            <TableCell align="center">아이디</TableCell>
            <TableCell align="center">회원명</TableCell>
            <TableCell align="center">연락처</TableCell>
            <TableCell align="center">접수정보</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.content.length > 0 
              ? (
                list.content.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {pagingRowNum(list.total, params.size, params.page, index)}
                    </TableCell>
                    <TableCell align="center">
                      {
                        codeTitle('orderType', item.orderType)
                      }
                    </TableCell>
                    <TableCell align="center">
                      {moment(item.createDate).format('YYYY.MM.DD HH:mm')}
                    </TableCell>
                    <TableCell align="center">
                      {
                        item.isUser === 'Y' ? "회원" : "비회원"
                      }
                    </TableCell>
                    <TableCell align="center">
                      <FormControl className="tableFormControl checkbox">
                        <CustomCheckbox 
                          disabled 
                          checked={item.isCompanyUser === 'Y'}
                          className="invert" 
                          aria-label="선택" 
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">{item.companyName}</TableCell>
                    <TableCell align="center">{item.userId}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.phone}</TableCell>
                    <TableCell align="center">
                      <Button
                        fullWidth
                        style={{ width: 200 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleClick(item.orderKey)}
                      >
                        자세히
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )
              : (
                <TableRow>
                  <TableCell colSpan={10} align="center">조회결과가 없습니다.</TableCell>
                </TableRow>
              )
          }
          
        </TableBody>
      </Table>
      <Pagination
        total={list.total}
        page={params.page}
        rowPage={params.size}
        handlePage={handlePaging}
      />
    </React.Fragment>
  );
}

export default inject('oprtnStore')(withRouter(observer(DeliveryHistoryList)));