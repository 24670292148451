import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { Title } from '../components/common';
import SearchForm from '../components/VisitorSelPage/SearchForm';
import VisitorBoard from '../components/VisitorSelPage/VisitorBoard';
import VisitorList from '../components/VisitorSelPage/VisitorList';

function VisitorSelPage({ oprtnStore, opStore }) {

  const { visitListSel } = oprtnStore;
  const { dayCountSel } = opStore;

  useEffect(() => {
    visitListSel({});
    dayCountSel();
  }, [])

  return (
    <React.Fragment>
      <Title title="방문자 조회" />
      <SearchForm />
      <VisitorBoard />
      <VisitorList />
    </React.Fragment>
  );
}

export default inject('oprtnStore', 'opStore')(VisitorSelPage);
