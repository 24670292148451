import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Table, TableHead, TableRow, TableCell, FormControl, Button, TableBody } from '@material-ui/core';

import { CustomCheckbox } from '../common/input';
import { Pagination } from '../common';
import { codeTitle, pagingRowNum } from '../../lib/util';

function OperateStateList({ history, opStore }) {
  const {
    orderListSel,
    orderList: list,
    orderParmas: params,
   } = opStore;

  const handleClick = (key) => {
    history.push(`/receive/${key}`);
  }

  const handlePaging = (value, name) => {
    if (name === 'page') params.page = value;
    else if (name === 'size') params.size = value;

    orderListSel(params);
  }

  return (
    <React.Fragment>
      <Table className="bbsListTable">
        <caption>GA 관리 목록</caption>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '6%' }} />
          <col style={{ width: '14%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '*%' }} />
        </colgroup>
        <TableHead className="bbsListTableHead">
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">구분</TableCell>
            <TableCell align="center">상담필요</TableCell>
            <TableCell align="center">담당자의견</TableCell>
            <TableCell align="center">접수상태</TableCell>
            <TableCell align="center">회원구분</TableCell>
            <TableCell align="center">휴대폰번호</TableCell>
            <TableCell align="center">접수상태</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.content.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">{pagingRowNum(list.total, params.size, params.page, index)}</TableCell>
                <TableCell align="center">
                  {
                    codeTitle("orderType", item.orderType)
                  }
                </TableCell>
                <TableCell align="center">
                  <FormControl className="tableFormControl checkbox">
                    <CustomCheckbox disabled checked={item.paymentStatus === "AfterConsult" ? true : false} className="invert" aria-label="전체 선택" />
                  </FormControl>
                </TableCell>
                <TableCell align="center" style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>
                  {item.opinion ? item.opinion : "-"}
                </TableCell>
                <TableCell align="center">
                  {item.receiptStatus === "Accepted" ? item.adminId : '미접수'}
                </TableCell>
                <TableCell align="center">
                  {item.isUser === 'Y' ? '회원' : '비회원'}
                </TableCell>
                <TableCell align="center">{item.phone}</TableCell>
                <TableCell align="center">
                  <Button
                    fullWidth
                    style={{ width: 200 }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handleClick(item.orderKey)}
                  >
                    자세히
              </Button>
                </TableCell>
              </TableRow>
            ))
          }
          {
            list.content.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} align="center">조회결과가 없습니다.</TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
      <Pagination
        total={list.total}
        page={params.page}
        rowPage={params.size}
        handlePage={handlePaging}
      />
    </React.Fragment>
  );
}

export default inject('opStore')(withRouter(observer(OperateStateList)));
