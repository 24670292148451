import React, { useRef } from 'react';
import SockJsClient from 'react-stomp';
import { inject, observer } from 'mobx-react';
import audio from '../../static/audio/msgAudio.mp3'

function Websocket({ commonStore, accountStore }) {
  var audioFile = new Audio(audio);
  const $websocket = useRef(null);
  const { alertMsg } = commonStore;
  const { alertSountOnOff } = accountStore;

  const sendMessage = (msg) => {
    $websocket.sendMessage('/topic/order', msg);
  }

  const handleMessage = (msg) => {
    if (alertSountOnOff) audioFile.play();
    alertMsg(msg.content);
  }

  return (
    <div>
      <SockJsClient 
        url='http://quinors-env.eba-grz63hu5.ap-northeast-2.elasticbeanstalk.com/quinors/websocket' 
        topics={['/topic/order']}
        onMessage={(msg) => handleMessage(msg)}
        ref={$websocket}
      />
    </div>
  );
}

export default inject('commonStore', 'accountStore')(observer(Websocket));