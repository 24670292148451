import axios from 'axios';
import { TEST_TOCKE } from './message';
import { isLoadingBar } from './util';

const BASE_URL = 'http://quinors-env.eba-grz63hu5.ap-northeast-2.elasticbeanstalk.com';
const httpAxios = axios.create({
  baseURL: BASE_URL
});

/**
 * 공통 api 통신 모듈
 * @param {String} url : api url
 * @param {String} method: get, post, delete, put
 * @param {Object} params: api url요청과 함께 전송 될 URL 매개 변수입니다
 * @param {Object} data: api url요청에 body로 전송할 데이터입니다.
 * @param {boolean} loading: 로딩바 생성 유무
 */

export const client = async ({ 
  url, data, params, 
  loading = true, method = 'get', file = false, type
}, isAuth) => {

  if (loading) isLoadingBar(true);

  let headers = {};
  if (isAuth) {
    const token = localStorage.getItem('qadmtk');

    if (token) headers["Authorization"] = `Bearer ${token}`;
    // headers["Authorization"] = `Bearer ${TEST_TOCKE}`;
  }
  

  if (file) {
    headers["Content-type"] = "multipart/form-data";
  }

  return httpAxios({
    url: `${url}`,
    method,
    data,
    params,
    headers,
    responseType: type === 'excel' || type === 'file' ? 'arraybuffer' : undefined,
  })
    .then((response) => {
      isLoadingBar(false);
      return response;
    })
    .catch((error) => {
      isLoadingBar(false);
      return error.response;
    })
};