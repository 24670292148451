import Alert from './alert';
import Commons from './commons';
import Account from './account';
import Receive from './receive';
import Admin from './admin';
import CustomerManagement from './customerManagement';
import OprtnMgmt from './oprtnMgmt';
import Banner from './banner';
import Charge from './charge';
import Operate from './operate';

class RootStore {
  alertStore;
  commonStore;
  receiveStore;
  cmStore;
  accountStore;
  adminStore;
  oprtnStore;
  bannerStore;
  chargeStore;
  opStore;

  constructor() {
    this.alertStore = new Alert(this);
    this.commonStore = new Commons(this);
    this.receiveStore = new Receive(this);
    this.cmStore = new CustomerManagement(this);
    this.accountStore = new Account(this);
    this.adminStore = new Admin(this);
    this.oprtnStore = new OprtnMgmt(this);
    this.bannerStore = new Banner(this);
    this.chargeStore = new Charge(this);
    this.opStore = new Operate(this);
  }
}

export default RootStore;