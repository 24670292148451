import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { inject } from 'mobx-react';
import { ThemeProvider } from '@material-ui/styles';
import { Route, Switch, Redirect } from 'react-router-dom';

import theme from './static/theme';
import routes from './lib/router';
import { isLoadingBar } from './lib/util';

import './static/css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import DefaultAlert from './components/common/dialog/Alert/DefaultAlert';
import Websocket from './components/common/Websocket';
import SnackbarAlert from './components/common/dialog/Alert/SnackbarAlert';

const MyRouter = ({ layout: Layout, component: Component, strict = false, ...rest }) => {
  return (
    <Route
      strict
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

function App({ accountStore }) {
  const { meCall } = accountStore;

  useEffect(() => {
    meCall();
    isLoadingBar(false);

    const thisAlert = localStorage.getItem('alertSountOnOff');
    if (thisAlert) localStorage.setItem('alertSountOnOff', thisAlert);
    else localStorage.setItem('alertSountOnOff', true);
    
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DefaultAlert />
      <Websocket />
      <SnackbarAlert />
      <Switch>
        {
          routes.map((r, idx) => {
            return (
              <MyRouter
                exact={r.exact}
                path={r.path}
                layout={r.layout}
                component={r.component}
                strict={r.strict}
                key={idx}
              />
            )
          })
        }
        <Redirect path="/" to="/login" />
      </Switch>
    </ThemeProvider>
  );
}

export default inject('accountStore')(App);
