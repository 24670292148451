import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 400,
  },
  footer: {
    marginTop: 120,
    paddingTop: 17,
    paddingBottom: 17,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  copy: {
    fontSize: 11,
    lineHeight: 1.8,
    '& .divider': {
      height: 8,
    },
  },
}));

const LoginLayout = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <div className={classes.container}>{children}</div>
      <Box component="footer" className={classes.footer}>
        <Typography component="div" color="textSecondary" align="center" className={classes.copy}>
          <div>
            주식회사 신한로직스 02-2099-7117
            <span className="divider" />
            대표문의메일 admin@gmail.co.kr
          </div>
          <div>
            서울 강서구 마곡중앙8로 71 
            <span className="divider" />
            사업자등록번호 000-00-0000
          </div>
          Copyright &copy; 2019 LG CNS All Rights reserved.
        </Typography>
      </Box>
    </div>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.node
};

export default LoginLayout;