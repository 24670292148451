import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import Title from '../components/common/Title';
import SearchForm from '../components/DeliveryHistoryPage/SearchForm';
import DeliveryHistoryList from '../components/DeliveryHistoryPage/DeliveryHistoryList';

function DeliveryHistoryPage({ oprtnStore }) {
  const { deliveryListSel } = oprtnStore;
  useEffect(() => {
    deliveryListSel({});
  }, [])
  return (
    <React.Fragment>
      <Title title="배송내역 조회"/>
      <SearchForm />
      <DeliveryHistoryList />
    </React.Fragment>
  );
}

export default inject('oprtnStore')(DeliveryHistoryPage);