import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { Grid, TextField } from '@material-ui/core';
import ButtonSearchList from '../common/button/ButtonSearchList';

function SearchForm({ cmStore }) {
  const [search, setSearch] = useState("");

  const { 
    companySelCall
  } = cmStore;

  const handleChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  }
  const handleClick = () => {
    companySelCall({ q: search });
  }

  return (
    <Grid container className="listSearch">
      <Grid item>
        <Grid container direction="row" alignItems="center" className="schFormGroup">
          <Grid item>
            <Grid container direction="row">
              <Grid item>
                <TextField
                  placeholder="기업명, 사업자번호 입력"
                  className="searchKeyword"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> 
      <Grid item>
        <ButtonSearchList 
          className="btnSchList" 
          onClick={handleClick}
        />
      </Grid>
    </Grid>
  );
}

export default inject('cmStore')(SearchForm);