const white = '#fff';
const black = '#000';

export default {
  white,
  black,
  primary: {
    main: '#00aeef',
    dark: '#0092e7',
  },
  secondary: {
    main: '#5c76c4',
  },
  tertiary: {
    main: '#343d4d',
    dark: '#242b38',
  },
  background: {
    default: white,
  },
  text: {
    primary: '#333',
    secondary: '#666',
    disabled: '#999',
  },
  error: {
    //main: '#d40e12',
    main: '#d4320e',
  },
  divider: '#e5e5e5',

  bgColor: {
    100: '#f8f9fc',
    //100: 'pink',
    200: '#f1f2f5',
    300: '#f1f3f9',
    400: '#d5d5d5',
    disabled: '#f9f9f9',
    // gradient: 'linear-gradient(185deg, rgba(174,242,253,1) 0%, rgba(174,242,253,1) 15%, rgba(122,157,244,1) 100%)',
    gradient: 'linear-gradient(0deg, #A9C9FF 0%, #84d7ff 100%)',
    selected: '#fbfbf4',
  },
  border: {
    100: '#ccc',
    200: '#ddd',
    300: '#666',
    400: '#e8e9ef',
    500: '#999',
  },
  blueScale: {
    100: '#6b7bfd',
    200: '#dfe3fe',
  },
}