import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Table, TableBody, Typography, TableRow, TableCell, FormControl, TextField } from '@material-ui/core';

import { CustomCheckbox } from '../common/input';
import { codeTitle, text } from '../../lib/util';

function ReceiveInfo({ orderDet, handleParams }) {
  const [data, setData] = useState(orderDet);

  useEffect(() => {
    if (!orderDet) return;
    setData(orderDet);
  }, [orderDet]);


  const handleChange = (e, checked) => {
    const value = e.target.value;
    const name = e.target.name;

    setData((old) => ({
      ...old,
      [name]: value
    }));

    handleParams({[name]: value});
  };
  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">목록</Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            {/* <col span={6} style={{ width: '20%' }} /> */}
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">요청시간</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    placeholder=""
                    value={data.reservationTime ? moment(data.reservationTime).format('YYYY-MM-DD HH:mm') : moment(data.createDate).format('YYYY-MM-DD HH:mm')}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">접수시간</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    placeholder=""
                    value={
                      data.createDate 
                      ? moment(data.createDate).format('YYYY-MM-DD HH:mm')
                      : ""
                    }
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">
                상담
                <FormControl className="tableFormControl checkbox">
                  <CustomCheckbox 
                    name="paymentStatus"
                    checked={
                      data.paymentStatus === 'AfterConsult'
                    }
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                {/* <div className="tableFormControl">
                  <TextField
                    placeholder=""
                  />
                </div> */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">연락처</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    placeholder=""
                    name="phone"
                    value={data.user 
                      ? text(data.user.phone) 
                      : data.startTel 
                        ? data.startTel 
                        : data.endTel
                          ? data.endTel
                          : ''
                      }
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">회원구분</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    placeholder=""
                    value={text(data.user) ? '회원' : '비회원'}
                  />  
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">서비스구분</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    placeholder=""
                    value={codeTitle('orderType', data.orderType)}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">ID</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    placeholder=""
                    value={data.user ? data.user.userId : ''}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">이름</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    placeholder=""
                    value={data.user && text(data.user.name)}
                    name=""
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th"></TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
}

export default ReceiveInfo;