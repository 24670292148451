import React, { useState, useCallback, useEffect } from 'react';
import { Box, Table, TableBody, Typography, TableRow, TableCell, TextField } from '@material-ui/core';
import DaumPostcodeDialog from '../common/dialog/DaumPostcodeDialog';
import { text, codeTitle } from '../../lib/util';

const PlaceInfo = ({ orderDet, handleParams }) => {
  const [data, setData] = useState(orderDet);
  const [open, setOpen] = useState(false);
  const [addrType, setAddrType] = useState("");
  const [address, setAddress] = useState({ start: '', end: '' });

  useEffect(() => {
    if (!orderDet) return;
    setData(orderDet);

    setAddress({
      start: text(orderDet.startAddr),
      end: text(orderDet.endAddr),
    })
  }, [orderDet]);


  const handlePostcodeOnoff = useCallback((name) => {
    setAddrType(name);
    setOpen(!open);
  }, [open]);

  const handleChangeAddress = (name, addr, code) => {
    setAddress((old) => ({
      ...old,
      [name]: addr
    }));
    

    if (name === 'start') {
      setData((old) => ({
        ...old,
        "startPointCode": code,
        "startAddr": addr
      }))

      handleParams({
        "startPointCode": code,
        "startAddr": addr
      })
    } else {
      setData((old) => ({
        ...old,
        "endPointCode": code,
        "endAddr": addr
      }))

      handleParams({
        "startPointCode": code,
        "startAddr": addr
      })
    }
  };

  const handleChange = (e, checked) => {
    let value = e.target.value;
    let name = e.target.name;
    setData((old) => ({
      ...old,
      [name]: value
    }));

    handleParams({ [name]: value })
  };



  return (
    <React.Fragment>
      <DaumPostcodeDialog 
        open={open} 
        name={addrType}
        handleClose={handlePostcodeOnoff} 
        handleChange={handleChangeAddress}
      />
      <Typography component="h2" variant="h2">출발지</Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">주소</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    disabled
                    fullWidth
                    placeholder=""
                    value={address.start}
                    onClick={() => handlePostcodeOnoff('start')}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">상세주소</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="startAddrDtl"
                    value={text(data.startAddrDtl)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">보내는 사람</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="startNm"
                    onChange={handleChange}
                    value={text(data.startNm)}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">연락처</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="startTel"
                    value={text(data.startTel)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">층수</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    type="number"
                    value={data.startFloor}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">옵션</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={codeTitle("buildTypeCode", data.moveStartType)}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      
      {/* ######################### 도착 #########################*/}

      <Typography component="h2" variant="h2">도착지</Typography>
      <Box className="section">
        <Table className="dataTable horizontal">
          <caption></caption>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '*%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">주소</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    disabled
                    placeholder=""
                    value={address.end}
                    onClick={() => handlePostcodeOnoff('end')}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">상세주소</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="endAddrDtl"
                    value={text(data.endAddrDtl)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">받는 사람</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="endNm"
                    onChange={handleChange}
                    value={text(data.endNm)}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">연락처</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    name="endTel"
                    value={text(data.endTel)}
                    onChange={handleChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" variant="head" component="th">층수</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    type="number"
                    value={data.endFloor}
                  />
                </div>
              </TableCell>
              <TableCell scope="row" variant="head" component="th">옵션</TableCell>
              <TableCell>
                <div className="tableFormControl">
                  <TextField
                    fullWidth
                    placeholder=""
                    value={codeTitle("buildTypeCode", data.moveEndType)}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
};

export default PlaceInfo;