import React from 'react';
import { Title } from '../components/common';
import CompanyRegForm from '../components/CompanyRegPage/CompanyRegForm';

function CompanyRegPage(props) {
  return (
    <React.Fragment>
      <Title title="기업등록"/>
      <CompanyRegForm type="reg"/>
    </React.Fragment>
  );
}

export default CompanyRegPage;