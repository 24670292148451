import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { Divider } from '@material-ui/core';
import { Title } from '../components/common';
import { Situationboard, SearchForm, OperateStateList } from '../components/OperateStatePage';

function OperateStatePage({ opStore }) {
  const { dayCountSel, orderListSel } = opStore;
  useEffect(() => {
    dayCountSel();
    orderListSel();
  }, [])
  return (
    <React.Fragment>
      <Title title="운영현황" />
      <Situationboard />
      <Divider style={{margin: '20px 0'}}/>
      <SearchForm />
      <OperateStateList />
    </React.Fragment>
  );
}

export default inject('opStore')(OperateStatePage);
