import { makeObservable, action, observable } from 'mobx';
import { dayCountAPI, orderListAPI} from '../api/operate';
export default class Operate {
  root;
  orderList = {content: [], total: 0};
  orderParmas = {page: 1, size: 20}
  orderData = null;
  dayCount = null;

  constructor(root) {
    this.root = root;
    makeObservable(
      this, {
        orderList: observable,
        orderData: observable,
        dayCount: observable

      }
    )
  }

  dayCountSel = async () => {
    try {
      const resp = await dayCountAPI();
      const { status, data } = resp;
      if (status !== 200) throw resp;
      this.dayCount = data;
    } catch (error) {
      console.log('%c##### Error-dayCountSel: ', 'color: red', error);
    }
  }

  // 배송조회 
  orderListSel = async (params) => {
    const p = {
      ...this.orderParmas,
      ...params
    }
    try {
      const resp = await orderListAPI(p);
      const { status, data } = resp;
      if (status !== 200) throw resp;

      this.orderList = { content: data.content, total: data.totalElements };

    } catch (error) {
      console.log('%c##### Error-orderListSel: ', 'color: red', error);
    }
  }
}