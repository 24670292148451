import palette from './palette';
import { IconExpand, IconErrorMsg, IconInfoMsg, IconStepComplete, IconMsgNotice, IconResult } from '../images'


export default {
    MuiCssBaseline: {
        '@global': {
            img: {
                fontSize: 0,
                lineHeight: 0,
                verticalAlign: 'top',
            },
            em: {
                fontStyle: 'normal',
            },
            'ol, ul, li': {
                margin: 0,
                padding: 0,
                listStyle: 'none',
            },
            '.addrDisabled' : {
                '& .Mui-disabled': {
                    color: '#333 !important'
                }
            },
            '.section': {
                marginBottom: 40,
            },
            '.searchKeyword': {
                '& input': {
                    width: '220px !important',
                }
            },
            '.contsBox': {
                marginBottom: 40,
                '&.dense': {
                    marginBottom: 30,
                },
            },
            '.article': {
                marginBottom: 20,
            },
            '.pageMainButtons': {
                marginTop: 40,
                textAlign: 'center',
                '& button': {
                    margin: '0 5px',
                }
            },
            '.contentButtons': {
                marginTop: 40,
                marginBottom: 80,
                textAlign: 'center',
                '& button': {
                    margin: '0 5px',
                }
            },
            '.titleArea': {
                marginBottom: 22,
                '& h1, & h2, & h3, & h4': { marginBottom: 0 },
                '& .buttonArea': {
                    marginBottom: -5,
                    '& button': {marginLeft:10}
                },
                '&.depth2': {
                    marginBottom: 12,
                },
                '& .subPara': {
                    marginLeft: 10,
                }
            },
            '.noData': {
                paddingTop: 90,
                paddingBottom: 94,
                borderTop: `1px solid ${palette.border[300]}`,
                borderBottom: `1px solid ${palette.border[200]}`,
                '&:before': {
                    display: 'block',
                    width: 74,
                    height: 74,
                    margin: '0 auto 19px',
                    background: `url(${IconResult}) no-repeat center top`,
                    content: '""',
                },
            },
            '.txtEmphasis': {
                fontWeight: '700',
                color: palette.black,
            },
            '.formRow': {
                marginLeft: -5,
                marginRight: -5,
                '& .MuiFormControl-root': {
                    padding: '0 5px',
                },
                '& .formBtn, & .formsGap': {
                    padding: '0 5px',
                },
                '& .formLabel': {
                    marginRight: 10,
                },
            },
            '.listSearch': {
                marginRight: -5,
                marginBottom: 14,
                marginLeft: -5,
                '& .MuiFormControl-root, .formGap, .MuiFormGroup-root': {
                    marginRight: 6,
                    marginLeft: 6,
                },
                '& .formLabel': {
                    marginRight: 6,
                    marginLeft: 6,
                },
                '& .btnSchList, & .btnSchListGroup': {
                    marginLeft: 6,
                },
                '& .MuiNativeSelect-filled': {
                    width: 'auto',
                },
                '& .MuiFilledInput-root input': {
                    width: 'auto',
                    minWidth: 215,
                }
            },
            '.editorArea': {
                marginTop: 10,
            },
            '.verticalBtnsGroup': {
                margin: '-2px',
                '& button': {
                    margin: 2,
                },
            },
            '.bbsView': {
                marginTop: 15,
                padding: 30,
                border: `1px solid ${palette.border[100]}`
            },
            '.bbsViewConts': {
                maxHeight: 300,
                overflowY: 'auto',
            },
            '.code': {
                wordBreak: 'break-all',
            },
            '.divider': {
                display: 'inline-block',
                width: 1,
                height: 12,
                marginLeft: 10,
                marginRight: 10,
                backgroundColor: palette.border[100],
                verticalAlign: 'middle',
            },
            '.scrollBody': {
                width: '100%',
                // maxHeight: 560,
                // overflowX: 'auto',
                '& .w2000': {
                    // minWidth: 2100,
                    // paddingBottom: 1,
                },
                '& .w3000': {
                    minWidth: 3000,
                    paddingBottom: 1,
                }
            },
            '.primaryLineBox': {
                paddingTop: 50,
                paddingBottom: 55,
                paddingLeft: 20,
                paddingRight: 20,
                border: `1px solid ${palette.border[200]}`,
                borderTopWidth: 2,
                borderTopColor: palette.primary.main,
                textAlign: 'center',
                '&.dense': {
                    paddingTop: 35,
                    paddingBottom: 35,
                },
            },
            '.tableLineBox': {
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 20,
                paddingRight: 20,
                borderTop: '1px solid #666',
                borderBottom: '1px solid #ddd',
                '& .row': {
                    marginTop: 10,
                    marginBottom: 10,
                },
            },
            '.grayBox': {
                padding: '30px 20px',
                backgroundColor: palette.bgColor[100],
                textAlign: 'center',
            },
            '.completeMsgArea': {
                paddingTop: 40,
                paddingBottom: 60,
                backgroundColor: palette.bgColor[100],
                textAlign: 'center',
            },
            '.link': {
                fontSize: 13,
                color: palette.secondary.main,
                textDecoration: 'none',
                borderBottom: `1px solid ${palette.secondary.main}`,
            },
            '.textarea': {
                position: 'relative',
                '& .multiline': {
                    paddingBottom: 35,
                },
                '& .chkByte': {
                    position: 'absolute',
                    right: 20,
                    bottom: 20,
                    color: palette.text.secondary,
                    '& .byte': {
                        color: palette.primary.main,
                    },
                },
            },
            '.noticeBox': {
                marginTop: 60,
                padding: '30px 30px 40px',
                backgroundColor: palette.bgColor[100],
                '& .icoMsgNotice': {
                    marginBottom: 25,
                },
            },
            '.textGroup': {
                '& + .textGroup': {
                    marginTop: 30,
                },
                '& p + p': {
                    marginTop: 7,
                }
            },
            '.txtDotted': {
                position: 'relative',
                paddingLeft: 11,
                '&:before': {
                    display: 'block',
                    position: 'absolute',
                    left: 0,
                    top: 6,
                    width: 3,
                    height: 3,
                    borderRadius: '50%',
                    backgroundColor: palette.border[100],
                    content: '""',
                },
            },
            '.txtPlaceholder': {
                color: palette.text.disabled,
            },
            '.fixedContainer': {
                width: 780,
                margin: '0 auto',
                paddingTop: 95,
                '&:before': {
                    display: 'block',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: 6,
                    background: palette.bgColor.gradient,
                    content: '""',
                },
                '@media (max-width: 780px)' : {
                    position: 'relative',
                },
                '&.full': {
                    width: '100%',
                    minWidth: 780,
                },
                '&.secondary': {
                    paddingTop: 36,
                    '&:before': {
                        background: palette.tertiary.main,
                    },
                },
            },
            '.narrowFormArea': {
                width: 336,
                margin: '0 auto',
            },
            '.mainFormGroup': {
                '& .formArea + .formArea': { marginTop: 14 },
                '& input': {
                    height: 'auto',
                    padding: '16px 14px 15px',
                    /* border: 'none', */
                    fontSize: 16,
                    /* boxSizing: 'content-box', */
                    '&.MuiOutlinedInput-inputAdornedStart': { paddingLeft: 0 },
                    '& ~ .MuiOutlinedInput-notchedOutline': { borderRadius: 4 },
                },
                '& select': {
                    height: 50,
                    padding: '15px 38px 14px 14px',
                    borderRadius: 4,
                    fontSize: 16,
                    '&:focus': {
                        borderRadius: 4,
                    },
                },
                '& .MuiButton-sizeLarge:not(.MuiButtonGroup-grouped)': {
                    borderRadius: 4,
                }
            },
            '.btnFormSubmitArea': {
                position: 'relative',
                '&:before': {
                    display: 'inline-block',
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    width: 1,
                    height: 32,
                    marginTop: -16,
                    backgroundColor: palette.border[100],
                    content: '""',
                },
            },
            '.mainPageHead': {
                marginBottom: 40,
                fontSize: 38,
                textAlign: 'center',
            },
            '.mainPageSubHead': {
                marginBottom: 15,
                fontSize: 18,
                color: palette.black
            },
            '.agreeBox': {
                paddingTop: 8,
                paddingBottom: 8,
                borderTop: `1px solid ${palette.border[200]}`,
                borderBottom: `1px solid ${palette.border[200]}`,
                '& .agreeRow': {
                    marginTop: 3,
                    marginBottom: 3,
                },
                '& .MuiFormControlLabel-label': {
                    fontSize: 16,
                    color: '#000',
                },
                '& .btnViewAgree': {
                    marginRight: 3,
                    fontSize: 16,
                    color: palette.black
                },
                '& .msgError': {
                    marginRight: 13,
                },
                '& .agreeAd': {
                    marginTop: 13,
                    marginBottom: -8,
                    paddingTop: 20,
                    paddingBottom: 20,
                    '& .agreeAdContact': {
                        justifyContent: 'center',
                        '& label': {
                            marginLeft: 31,
                            marginRight: 40,
                        }
                    }
                },

                '&.noDetails': {
                    paddingTop: 10,
                    paddingBottom: 10,
                    '& .agreeRow': {
                        marginTop: 10,
                        marginBottom: 10,
                    },
                },
            },
            '.tableUtils': {
                marginTop: 20,
                '& .startIcon': {
                    marginRight: 6,
                    color: '#ccc',
                },
                '&.dense': {
                    marginTop: 15,
                },
            },
            '.tableRemarks': {
                marginTop: -30,
                marginBottom: 10,
                textAlign: 'right',
            },
            '.legendArea': {
                marginBottom: 15,
                lineHeight: 1,
                color: palette.text.secondary,
                '& .count': {
                    fontWeight: 700,
                    fontSize: 16,
                    lineHeight: 1,
                    color: '#000',
                },
                '& .divider': {
                    backgroundColor: palette.border[200],
                },
            },
            '.bbsIconContainer': {
                display: 'inline-flex',
                alignItems: 'center',
                marginLeft: 10,
                verticalAlign: 'middle',
                '& .icoBoard': {
                    marginLeft: 10,
                },
            },
            '.tooltipList': {
                maxHeight: 214,
                marginTop: 6,
                overflowY: 'auto',
                '& li': {
                    marginTop: 6,
                    '&:first-child': {
                        marginTop: 0,
                    },
                },
            },
        },
    },
    MuiTypography: {
        root: {
            '&.large': {
                fontSize: 20,
            },
            '&.tableRemarks': {
                marginTop: -30,
                marginBottom: 10,
                textAlign: 'right',
            },
            '&.icoMsgNotice': {
                display: 'block',
                minHeight: 24,
                paddingLeft: 34,
                background: `url(${IconMsgNotice}) no-repeat 0 0`,
                fontWeight: 700,
                fontSize: 16,
                color: palette.tertiary.main,
            },
            '&.dottedHead': {
                position: 'relative',
                marginBottom: 16,
                paddingLeft: 22,
                fontWeight: 700,
                fontSize: 16,
                lineHeight: 1,
                color: palette.text.secondary,
                '&:before, &:after': {
                    display: 'block',
                    position: 'absolute',
                    content: '""',
                },
                '&:before': {
                    left: 10,
                    top: 5,
                    width: 6,
                    height: 6,
                    borderRadius: 2,
                    backgroundColor: '#ccc'
                },
                '&:after': {
                    left: 12,
                    top: 7,
                    width: 2,
                    height: 2,
                    backgroundColor: '#fff',
                },
            },
            '&.txtPlaceholder': {
                color: palette.text.disabled,
            },
            '&.txtLead': {
                fontWeight: '700',
                fontSize: 18,
                color: palette.black,
                '&.large': {
                    fontSize: 20,
                }
            },
            '&.txtDesc': {
                fontSize: 16,
            },
            '&.txtJumbo': {
                fontSize: 38,
                lineHeight: 1.26,
            },
            '&.txtBold': {
                fontWeight: 700,
            },
            '&.txtDotted': {
                position: 'relative',
                paddingLeft: 9,
                '&:before': {
                display: 'block',
                position: 'absolute',
                left: 0,
                top: 9,
                width: 3,
                height: 3,
                borderRadius: '50%',
                backgroundColor: '#ccc',
                content: '""',
                },
            },
            '&.txtBlack': {
                color: '#000',
            },
            '&.hasIconBullet': {
                display: 'flex',
                '& .icon': {
                    flexShrink: 0,
                },
            },
        },
    },
    MuiPaper: {
        elevation1: {
            borderBottom: `1px solid ${palette.border[100]}`,
            boxShadow: '0 2px 1px rgba(0, 0, 0, 0.12)',
        },
        elevation2: {
            borderRadius: 0,
            backgroundColor: palette.bgColor[100],
            boxShadow: 'none',
        },
        elevation3: {
            padding: 20,
            borderRadius: '20px 0 20px 0',
            backgroundColor: palette.bgColor[100],
            boxShadow: 'none',
            '&.completeMsgArea': {
                padding: '50px 0',
                textAlign: 'center',
            }
        },
        elevation4: {
            boxShadow: '0 0 7px rgba(0, 0, 0, 0.15)'
        },
    },
    MuiTabs: {
        /* root: {
            position: 'relative',
            '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                height: 2,
                background: palette.primary.main,
            },
        }, */
        indicator: {
            top: 0,
        },
    },
    MuiTab: {
        root: {
            minWidth: 0,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 13,
            '@media (min-width: 600px)': {
                minWidth: 0,
            }
        },
        textColorPrimary: {
            height: 50,
            border: `1px solid ${palette.border[100]}`,
            borderRightWidth: 0,
            lineHeight: '1.4',
            '&:last-child': {
                borderRightWidth: 1,
            },
            '&$selected': {
                borderBottomColor: '#fff',
                fontWeight: '700',
            },
        },
        /* textColorPrimary: {
            height: 62,
            border: `1px solid ${palette.stroke}`,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            lineHeight: '1.4',
            '&:not($selected)&:last-child': {
                borderRightWidth: 1,
            },
            '&$selected': {
                border: `2px solid ${palette.primary.main}`,
                borderBottomWidth: 0,
                zIndex: 1,
            },
            '&$selected:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                height: 2,
                background: '#fff',
            },
            '&$selected + .MuiTab-textColorPrimary': {
                borderLeftWidth: 0,
            },
        }, */
    },
    MuiTable: {
        root: {
            tableLayout: 'fixed',
            position: 'relative',
            width: '100%',
            '&.alignCenter': {
                '& th, & td': {
                    paddingLeft: 8,
                    paddingRight: 8,
                    textAlign: 'center',
                }
            },
            '&::before': {
                display: 'block',
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: 1,
                background: '#666',
                content: '""',
            },
            '& caption': {
                width: 1,
                height: 1,
                overflow: 'hidden',
                margin: -1,
                padding: 0,
                clip: 'rect(0,0,0,0)',
            },
            '&.dataTable': {
                '& th': {
                    backgroundColor: palette.bgColor[100],
                },
                '& thead th': {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            },
            '&.dataTable.narrow': {
                '& th, & td': {
                    paddingLeft: 5,
                    paddingRight: 5,
                    '& .subject': {
                        marginLeft: 10,
                    }
                },
            },
            '&.bbsListTable': {
                '& th, & td': {
                    paddingLeft: 4,
                    paddingRight: 4,
                    borderLeft: 0,
                    borderRight: 0,
                },
                '& th': {
                    backgroundColor: '#fff',
                },
                '&.dense': {
                    '& tr:nth-child(even)': {
                        '& td': {
                            backgroundColor: '#f8fcff',
                        },
                    },
                    '& td': {
                        height: 'auto',
                        paddingTop: 2,
                        paddingBottom: 2,
                    },
                },
            }
        },
    },
    MuiTableHead: {
        root: {
            '& th': {
                backgroundColor: palette.bgColor[100],
                textAlign: 'center',
            }
        }
    },
    MuiTableCell: {
        root: {
            height: 42,
            padding: '12px 20px',
            borderRight: `1px solid ${palette.border[200]}`,
            borderLeft: `1px solid ${palette.border[200]}`,
            borderBottom: `1px solid ${palette.border[200]}`,
            fontSize: 13,
            lineHeight: 1.4,
            wordBreak: 'break-all',
            '&:first-child': {
                borderLeft: 'none',
            },
            '&:last-child': {
                borderRight: 'none',
            },
            '& .tableFormControl': {
                marginTop: -9,
                marginBottom: -9,
                '&.inline': {
                    display: 'inline-block',
                },
                '&.MuiFormControl-root': {
                    marginTop: -7,
                    marginBottom: -7,
                },
                '& .MuiFormControl-root': {
                    marginTop: 2,
                    marginBottom: 2,
                },
                '& .formBtn, & .formsGap': {
                    marginTop: 2,
                    marginBottom: 2,
                },
                /* '& .formLabel': {
                    marginTop: 2,
                    marginBottom: 2,
                }, */
                /* '&.verticalBtnsGroup': {
                    marginTop: -9,
                    marginBottom: -9,
                }, */
                '&.checkbox': {
                    marginTop: -10,
                    marginBottom: -10,
                }
            },
            '& .radioGroup': {
                marginTop: -3,
                marginBottom: -3,
                '&.vertical': {
                    marginTop: -12,
                    '& label': {
                        marginTop: 0,
                    },
                },
            },
            '& .noData': {
                marginTop: 78,
                marginBottom: 82,
                paddingTop: 0,
                paddingBottom: 0,
                borderTop: 'none',
                borderBottom: 'none',
            },
        },
        head: {
            lineHeight: 1.4,
            fontWeight: '700',
            color: '#000',
        },
        footer: {
            fontWeight: 700,
            fontSize: 13,
            color: '#000',
        },
        alignCenter: {
            paddingLeft: 8,
            paddingRight: 8,
        }
    },
    MuiTablePagination: {
        root: {
            marginTop: 28,
            overflow: 'visible',
            '& .pagination': {
                display: 'block',
                width: '100%',
                textAlign: 'center',
            },
            '& .pagination button': {
                minWidth: 0,
                padding: '0 4px',
                margin: '0 2px',
                '&.prevPage, &.nextPage': {
                    margin: 0,
                    padding: 0,
                },
                '&.prevPage': {
                    marginLeft: 4,
                },
                '&.nextPage': {
                    marginRight: 4,
                },
                '&.on': {
                    fontWeight: '700',
                }
            },
            '& .selectRowsPerPage': {
                position: 'absolute',
                left: 0,
                top: -5,
                border: `1px solid ${palette.border[100]}`,
                borderRadius: 3,
                '& select': {
                    height: 32,
                    padding: '0 32px 0 10px',
                },
            },
        },
        selectIcon: {
            top: 6,
            right: 5,
            fontSize: 20,
            color: '#000'
        },
        spacer: {
            display: 'none'
        },
        caption: {
            position: 'absolute',
            right: 0,
            top: 3,
        },
        toolbar: {
            minHeight: 0,
            padding: '0 100px !important',
        },
    },
    MuiListItem: {
        dense: {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    MuiListItemText: {
        root: {
            marginTop: 0,
            marginBottom: 0,
        }
    },
    MuiButton: {
        root: {
            textTransform: 'none',
            '&$disabled': {
                backgroundColor: palette.bgColor.disabled,
                color: palette.text.disabled,
            },
            '&.quickLink': {
                marginRight: 20,
                padding: 0,
                '& .icon': {
                    width: 18,
                    height: 18,
                    marginLeft: 10,
                    border: `1px solid ${palette.border[500]}`,
                    borderRadius: '50%',
                    color: palette.border[500],
                },
            },
            '&.blockLink': {
                display: 'block',
                width: '100%',
                height: 52,
                paddingLeft: 20,
                paddingRight: 56,
                borderRadius: 8,
                backgroundColor: palette.bgColor[100],
                textAlign: 'left',
                '& $endIcon': {
                    position: 'absolute',
                    right: 20,
                    top: '50%',
                    width: 18,
                    height: 18,
                    marginTop: -9,
                    marginLeft: 0,
                    marginRight: 0,
                    '& .icon': {
                        marginLeft: 0,
                        borderColor: '#000',
                        color: '#000',
                        opacity: 0.5,
                    },
                },
            },
            '& .icoLinkArrow': {
                display: 'block',
                width: 14,
                height: 14,
                borderRight: `2px solid ${palette.border[100]}`,
                borderBottom: `2px solid ${palette.border[100]}`,
                transform: 'rotate(-45deg)',
            },
        },
        contained: {
            minWidth: 120,
            height: 50,
            paddingTop: 7,
            paddingBottom: 7,
            borderRadius: 2,
            fontWeight: '700',
            fontSize: 15,
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
            }
        },
        containedPrimary: {
            border: `1px solid ${palette.tertiary.main}`,
            backgroundColor: palette.tertiary.main,
            color: palette.white,
            '&:hover': {
                backgroundColor: palette.tertiary.dark,
            },
        },
        containedSecondary: {
            border: `1px solid ${palette.border[100]}`,
            backgroundColor: palette.background.default,
            color: palette.text.secondary,
            '&:hover': {
                backgroundColor: palette.bgColor[100],
            },
        },
        outlined: {
            height: 32,
            paddingTop: 0,
            paddingBottom: 0,
            borderColor: palette.border[100],
            borderRadius: 2,
            fontSize: 13,
            color: palette.text.primary,
            '&.btnSchList': {
                width: 66,
                height: 34,
                paddingLeft: 12,
                paddingRight: 11,
                border: `2px solid ${palette.border[400]}`,
                borderRadius: 4,
                backgroundColor: '#fff',
                color: palette.text.secondary,
                '&:hover': {
                    backgroundColor: palette.bgColor[100],
                },
            },
            '&$disabled': {
                borderColor: palette.border[200],
            }
        },
        outlinedPrimary: {
            borderColor: palette.border[100],
            backgroundColor: palette.bgColor[200],
            color: palette.text.primary,
            '&:hover': {
                borderColor: palette.border[100],
                backgroundColor: palette.bgColor[200],
            }
        },
        outlinedSizeLarge: {
            minWidth: 120,
            height: 50,
            paddingLeft: 8,
            paddingRight: 8,
            fontWeight: '700',
            fontSize: 15,
            color: palette.text.secondary,
        },
        outlinedSizeSmall: {
            minWidth: 51,
            height: 32,
            padding: '0 10px',
        },
        text: {
            '&.link': {
                minWidth: 0,
                padding: 0,
                border: 'none',
                borderRadius: 0,
                backgroundColor: 'transparent',
                fontSize: 13,
                lineHeight: 1.4,
                color: palette.secondary.main,
                textDecoration: 'none',
                //borderBottom: `1px solid ${palette.secondary.main}`,
                cursor: 'pointer',
                '& $startIcon': {
                    marginRight: 10,
                    marginLeft: 0,
                },
            },
        },
    },
    MuiButtonGroup: {
        root: {
            '&.rounded': {
                '& button:first-child': {
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                },
                '& button:last-child': {
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                },
            },
            '&.btnSchListGroup': {
                '& button': {
                    minWidth: 66,
                    height: 34,
                    color: palette.text.secondary,
                    '&.on': {
                        borderColor: palette.primary.main,
                        color: palette.primary.main,
                        zIndex: 1,
                    }
                }
            }
        },
        groupedOutlined: {

        },
        groupedOutlinedPrimary: {
            '&:not(:first-child)': {
                borderLeftColor: 'transparent',
                '&::before': {
                    display: 'block',
                    position: 'absolute',
                    left: 0,
                    top: 8,
                    bottom: 8,
                    width: 1,
                    backgroundColor: palette.border[100],
                    content: '""',
                },
            },
            '&:hover': {
                borderColor: palette.border[100]
            }
        },
    },
    MuiLink: {
        root: {
            fontSize: 13,
            color: palette.secondary.main,
        },
        underlineAlways: {
            textDecoration: 'none',
            borderBottom: `1px solid ${palette.secondary.main}`,
        },
        underlineNone: {
            color: palette.text.primary
        }
    },
    MuiTextField: {
        root: {
            
        },
    },
    MuiNativeSelect: {
        root: {
            '&$disabled': {
                borderColor: palette.border[200],
                backgroundColor: palette.bgColor.disabled,
                color: palette.text.disabled,
                '& + $icon': {
                    color: palette.text.disabled,
                },
                '&:hover, &:focus': {
                    background: palette.bgColor.disabled,
                    borderColor: palette.border[200],
                }
            },
        },
        select: {
            height: 21,
            paddingLeft: 10,
            borderRadius: 4,
            backgroundColor: '#fff',
            color: palette.black,
        },
        filled: {
            height: 19,
            paddingRight: 29,
            border: `1px solid ${palette.bgColor[100]}`,
            backgroundColor: palette.bgColor[100],
            fontWeight: '700',
            '&:hover': {
                borderColor: palette.tertiary.main,
                backgroundColor: palette.bgColor[100],
            },
            '&:focus': {
                borderColor: palette.tertiary.main,
                borderRadius: 4,
                backgroundColor: palette.bgColor[100],
            },
            '&$disabled': {
                borderColor: palette.bgColor.disabled,
                '&:hover': {
                    borderColor: palette.bgColor.disabled,
                },
                '&:focus': {
                    borderColor: palette.bgColor.disabled,
                },
            },
        },
        outlined: {
            height: 32,
            padding: '0 32px 0 13px',
            border: `1px solid ${palette.border[100]}`,
            borderRadius: 3,
            boxSizing: 'border-box',
            '&:hover': {
                borderColor: palette.tertiary.main,
            },
            '&:focus': {
                borderColor: palette.tertiary.main,
                backgroundColor: '#fff',
            },
        },
        icon: {
            color: palette.black
        },
        iconFilled: {
            right: 2,
        },
    },
    MuiInputBase: {
        root: {
            'label + &': {
                marginTop: 20,
            },
            '&.pickDate': {
                '&:not(.fullWidth)':{
                    width: 132,
                    '& input': {
                        width: 132,
                    },
                },
                '& button': {
                    minWidth: 0,
                    marginRight: 2,
                },
            },
        },
        input: {
            fontSize: 13, 
        },
    },
    MuiFilledInput: {
        root: {
            height: 34,
            border: `1px solid ${palette.bgColor[100]}`,
            borderRadius: 4,
            backgroundColor: palette.bgColor[100],
            '&:hover': {
                borderColor: palette.tertiary.main,
                backgroundColor: palette.bgColor[100],
            },
            '&$focused': {
                borderColor: palette.tertiary.main,
                backgroundColor: palette.bgColor[100],
            },
            '&$disabled': {
                backgroundColor: palette.bgColor.disabled,
                '& input': {
                    color: palette.text.disabled,
                },
                '&:hover': {
                    borderColor: palette.bgColor.disabled,
                },
            },
        },
        input: {
            height: '100%',
            paddingTop: 0,
            paddingBottom: 0,
            boxSizing: 'border-box',
            fontWeight: '700',
            color: palette.black,
            '&::-webkit-input-placeholder': {
                fontWeight: '400',
            },
            '&::-ms-clear': {
                display: 'none',
            },
        },
        adornedEnd: {
            paddingRight: 0,
        },
        inputAdornedEnd: {
            paddingRight: 35,
        }
    },
    MuiOutlinedInput: {
        root: {
            '&$focused $notchedOutline': {
                borderWidth: 1,
                borderColor: palette.tertiary.main,
            },
            '&$error $notchedOutline': {
                borderWidth: 1,
            },
            '&$disabled': {
                background: palette.bgColor.disabled,
                color: palette.text.disabled,
                '& $notchedOutline': {
                    borderColor: palette.border[200],
                },
            },
        },
        multiline: {
            padding: '8px 15px 9px',
        },
        input: {
            height: 32,
            padding: '8px 15px 9px',
            borderColor: palette.border[100],
            borderRadius: 3,
            boxSizing: 'border-box',
            '&:not(:disabled)': {
                backgroundColor: '#fff',
            },
            '&::-webkit-input-placeholder': {
                fontWeight: '400',
                color: '#ccc',
                opacity: 1,
            },
            '&::-ms-clear': {
                display: 'none',
            },
        },
        notchedOutline: {
            top: 0,
            borderColor: palette.border[100],
            borderRadius: 3,
            '& legend': {
                display: 'none',
            }
        },
        adornedEnd: {
            paddingRight: 0,
        },
        inputAdornedEnd: {
            paddingRight: 35,
        },
    },
    MuiInputLabel: {
        shrink: {
            transform: 'scale(1)',
        },
        formControl: {
            position: 'initial',
        }
    },
    MuiInputAdornment: {
        positionEnd: {
            position: 'absolute',
            right: 0,
            '& .clearInput': {
                marginRight: 10,
            }
        }
    },
    MuiFormLabel: {
        root: {
            display: 'block',
            transform: 'scale(1) !important',
            color: palette.text.primary,
            '&$focused': {
                color: palette.tertiary.main,
            },
            '& + .MuiInputBase-root': {
                marginTop: 0,
            },
            '&.horizontalLabel': {
                position: 'absolute',
                top: 14,
                left: -20,
                fontSize: 16,
                textAlign: 'right',
                lineHeight: 1.5,
                transform: 'translate(-100%, 0) scale(1) !important',
                '&.multiLine': {
                    top: 2,
                },
            },
        },
    },
    MuiFormHelperText: {
        root: {
            color: palette.tertiary.main,
        },
        contained: {
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 24,
            background: `url(${IconInfoMsg}) no-repeat 0 1px`,
            fontWeight: '400',
            fontSize: 13,
            lineHeight: '18px',
            '&$error': {
                backgroundImage: `url(${IconErrorMsg})`,
            },
        }
    },
    MuiFormControlLabel: {
        root: {
            marginTop: -9,
            marginBottom: -9,
            marginRight: 0,
            marginLeft: -8,
            '&.allAgree': {
                marginBottom: 4,
                '& .MuiFormControlLabel-label': {
                    fontWeight: '700',
                    fontSize: 16,
                    color: '#000',
                },
            },
        },
    },

    MuiDialog: {
        root: {
            '& .pageMainButtons': {
                marginTop: 0,
            },
            '& .closeButton': {
                position: 'absolute',
                top: 20,
                right: 20,
            },
            '& .MuiTabs-root': {
                marginBottom: 24,
            }
        },
        paper: {
            width: 620,
            padding: '31px 30px 50px',
            borderRadius: 4,
            '&.lg': {
                width: 800,
            },
            '&.xl': {
                width: 910,
            },
            '&.xxl': {
                width: 1070,
            },
        },
    },
    MuiDialogTitle: {
        root: {
            padding: 0,
            borderBottom: `1px solid ${palette.border[200]}`,
            fontWeight: '700',
            fontSize: 24,
            color: '#000',
            '& h2': {
                marginBottom: 23,
                fontSize: 'inherit',
            },
            '& .dialogTitleArea': {
                marginBottom: 23,
                '& h2': {
                    marginBottom: 0,
                    fontSize: 'inherit',
                },
            },
        },
    },
    MuiDialogContent: {
        root: {
            maxHeight: 480,
            padding: '30px 0 20px',
            backgroundColor: '#fff',
            '&.dialogContentType2': {
                marginTop: -1,
                paddingTop: 0,
                paddingRight: 1,
                '& .dialogContDivider': {
                    paddingTop: 30,
                    borderTop: '1px solid #000',
                }
            },
            '& .w2000': {
                minWidth: 2000,
                paddingBottom: 1,
            },
            '& .w3000': {
                minWidth: 3000,
                paddingBottom: 1,
            },
            '& .completeMsgArea': {
                paddingTop: 60,
                '& .icoComplete': {
                    marginBottom: 26,
                }
            },
        }
    },
    MuiDialogActions: {
        root: {
            display: 'block',
            padding: '20px 0 0',
            '& .subActions': {
                marginBottom: 40,
                textAlign: 'right',
                '&.gap': {
                    marginTop: 10,
                },
            },
        },
    },
    MuiStep: {
        horizontal: {
            paddingLeft: 6,
            paddingRight: 6,
        },
    },
    MuiStepLabel: {
        label: {
            '&$alternativeLabel': {
                width: 1,
                height: 1,
                overflow: 'hidden',
                margin: -1,
                marginTop: -1,
                padding: 0,
                clip: 'rect(0,0,0,0)',
            }
        },
    },
    MuiStepIcon: {
        root: {
            width: 26,
            height: 26,
            padding: 2,
            borderRadius: '50%',
            backgroundColor: palette.blueScale[200],
            color: '#fff',
            '&$active': {
                backgroundColor: palette.blueScale[100],
                color: palette.blueScale[100],
                '& .MuiStepIcon-text': {
                    fill: '#fff',
                }
            },
            '&$completed': {
                background: `${palette.blueScale[100]} url(${IconStepComplete}) no-repeat center`,
                color: palette.blueScale[100],
                '& path': {
                    display: 'none',
                }
            }
        },
        text: {
            fill: palette.blueScale[100],
            fontWeight: '700',
        },
    },
    MuiStepConnector: {
        lineHorizontal: {
            borderTopWidth: 2,
            borderColor: palette.blueScale[200],
        },
        alternativeLabel: {
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        }
    },

    MuiExpansionPanel: {
        root: {
            '&:before': {
                display: 'none',
            },
            //borderBottom: `1px solid ${palette.border[200]}`,
            '&$expanded': {
                margin: 0,
            },
        },
    },
    MuiExpansionPanelSummary: {
        root: {
            minHeight: 60,
            marginTop: -1,
            paddingTop: 11,
            paddingBottom: 11,
            paddingLeft: 20,
            paddingRight: 20,
            borderTop: `1px solid ${palette.border[200]}`,
            borderBottom: `1px solid ${palette.border[200]}`,
            backgroundColor: palette.bgColor[100],
            '& .expansionPanelTitle': {
                color: palette.tertiary.main,
            },
            '&$expanded': {
                minHeight: 60,
                borderColor: palette.primary.main,
                backgroundColor: '#fff',
                '& .expansionPanelTitle': {
                    color: palette.primary.main,
                },
            },
        },
        content: {
            margin: 0,
            '&$expanded': {
                margin: 0,
            },
        },
        expandIcon: {
            marginLeft: 10,
            marginRight: 0,
            padding: 0,
            '& .icoExpand': {
                display: 'inline-block',
                width: 18,
                height: 10,
                background: `url(${IconExpand}) no-repeat 0 0`,
            },
            '&$expanded': {
                '& .icoExpand': {
                    backgroundPositionY: -20,
                },
            },
        }
    },
    MuiExpansionPanelDetails: {
        root: {
            display: 'block',
            minHeight: 60,
            paddingTop: 21,
            paddingBottom: 27,
            paddingLeft: 20,
            paddingRight: 20,
            borderBottom: `1px solid ${palette.border[200]}`,
            backgroundColor: '#fff',
        },
    },
    MuiGrid: {
        container: {
            '&.formRow': {
                width: 'calc(100% + 5px)',
            },
            '&.MuiGrid-spacing-xs-10': {
                '&.listSearch': {
                    marginBottom: 9,
                    '& > $item': {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
        },
        item: {
            '&.grow': {
                flex: '1 1 0',
            },
        },
    },
    MuiFormGroup: {
        root: {
            '&.columnGroup': {
                '& label': {
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
        },
        row: {
            '&.columnGroup': {
                '& label': {
                    marginTop: -9,
                    marginBottom: -8,
                },
            },
        },
    },

    MuiChip: {
        root: {
            height: 24,
            borderRadius: 12,
            fontSize: 13,
            '&.chipLabel1': {
                backgroundColor: '#f0f2ff',
                color: '#7a97f4',
            },
            '&.chipLabel2': {
                backgroundColor: '#ecfaff',
                color: '#1fb6ff',
            },
            '&.chipLabel3': {
                backgroundColor: '#fff4ee',
                color: '#ff975c',
            },
            '&.chipLabel4': {
                backgroundColor: '#fff1f1',
                color: '#fe5050',
            },
            '&.chipLabel5': {
                backgroundColor: '#efefef',
                color: '#666',
            },
        },
        sizeSmall: {
            height: 20,
            borderRadius: 10,
        },
        outlined: {
            backgroundColor: '#fff !important',
            '&.chipLabel1': {
                borderColor: '#7a97f4',
            },
            '&.chipLabel2': {
                borderColor: '#1fb6ff',
            },
            '&.chipLabel3': {
                borderColor: '#ff975c',
            },
            '&.chipLabel4': {
                borderColor: '#fe5050',
            },
            '&.chipLabel5': {
                borderColor: '#666',
            },
        },
        label: {
            paddingLeft: 10,
            paddingRight: 10,
        },
        labelSmall: {
            paddingLeft: 7,
            paddingRight: 7,
        },
    },
    MuiSwitch: {
        root: {
            width: 28,
            height: 16,
            padding: 0,
        },
        switchBase: {
            padding: 2,
            color: '#fff',
            '&$checked': {
                transform: 'translateX(75%)',
            },
        },
        thumb: {
            width: 12,
            height: 12,
        },
        track: {
            borderRadius: 8,
            backgroundColor: '#ccc',
            opacity: 1,
        },
        colorPrimary: {
            '&$checked': {
                color: '#fff',
                '& + $track': {
                    opacity: 1,
                },
            },
        },
    },
}