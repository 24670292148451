import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { IconReg, IconSet, IconDownload, IconSearch2, IconMyInfo } from '../../../static/images';

const BtnAdd = withStyles(theme => ({
  root: {
    paddingLeft: 13,
    paddingRight: 13,
    borderRadius: 4,
    '& .icon': {
      height: 22,
      paddingLeft: 18,
      background: `url(${IconReg}) no-repeat left center`,
    },
    '&.btnSet .icon': {
      paddingLeft: 19,
      backgroundImage: `url(${IconSet})`
    },
    '&.btnDownload .icon': {
      paddingLeft: 21,
      backgroundImage: `url(${IconDownload})`
    },
    '&.btnSearch .icon': {
      backgroundImage: `url(${IconSearch2})`
    },
    '&.btnMyInfo .icon': {
      paddingLeft: 20,
      height: 16,
      backgroundImage: `url(${IconMyInfo})`
    },
    '&.square': {
      borderRadius: 2,
    },
  },
}))(Button);

const ButtonAddIcon = props => {
  const { children, className, ...others } = props;
  return (
    <BtnAdd variant="outlined" className={className} {...others}><span className="icon"></span>{children}</BtnAdd>
  );
};

export default ButtonAddIcon;