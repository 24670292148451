import { client } from '../lib/client';

// 회원조회
export const memberSel = (params) => (
  client({
    url: '/v1/admin/user/list',
    params
  }, true)
);

// 회원정보 상세
export const memberDetSel = (id) => (
  client({
    url: `/v1/admin/user/${id}`,
  }, true)
);

// 회원서비스 이용내역
export const memberUseAPI = (params, id) => (
  client({
    url: `/v1/admin/user/${id}/use`,
    params,
  }, true)
);

// 기업검색
export const companySearchAPI = (params) => (
  client({
    url: '/v1/admin/company/search',
    params,
  }, true)
);

// 기업상세
export const companyDetAPI = (id) => (
  client({
    url: `/v1/admin/company/${id}`,
  }, true)
);

// 기업담당자등록
export const companyUserRegAPI = (data) => (
  client({
    url: `/v1/admin/company/user`,
    method: 'post',
    data
  }, true)
)

// 기업조회
export const companySel = (params) => (
  client({
    url: 'v1/admin/company/list',
    params,
  }, true)
);

// 기업등록 API
export const companyReg = (data) => (
  client({
    url: '/v1/admin/company',
    method: 'post',
    data
  }, true)
);

// 기업담당자 조회
export const companyManagerSel = (id, params) => (
  client({
    url: `/v1/admin/company/manager/list/${id}`,
    params
  }, true)
);

// 기업담당자 정보
export const compnayUserDetAPI = (id) => (
  client({
    url: `/v1/admin/company/user/${id}`,
  }, true)
);

// 기업담당자 삭제
export const compnayUserDelAPI = (id) => (
  client({
    url: `/v1/admin/company/user/${id}`,
    method: 'delete'
  }, true)
);

// 기업담당자 수정
export const compnayUserModAPI = (data) => (
  client({
    url: `/v1/admin/company/user`,
    method: 'post',
    data
  }, true)
);