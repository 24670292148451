import React from 'react';
import { 
  Grid, TextField, Box, Typography, Button,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { ButtonSearchList } from '../../button';

function CompanyList({ cmStore, commonStore, handleClose }) {
  const params = {};
  const { setCompanyName } = commonStore;
  const { companySearch: data, companySearchSel, handleCompanyChoice } = cmStore;

  const handleChoice = (cp) => {
    setCompanyName(cp)
    handleClose();
  };

  const handleChange = (e) => {
    params["q"] = e.target.value;
  }

  const onSubmit = () => {
    companySearchSel(params);
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="기업명, 사업자번호 입력"
            className="searchKeyword"
            onChange={handleChange}
          />
          <Box height={10}/>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <ButtonSearchList 
              className="btnSchList" 
              onClick={onSubmit}
            />
          </Grid>
          <Box height={10} />
          <Grid item xs={12}>
            <div 
              style={{
                border: '1px solid #0f0f0f0f',
              }}
            >
              {
                !data || data.length === 0 
                  ? (
                      <Grid style={{ padding: '10px 0' }} container direction="row" justify="center" alignItems="center">
                        <Typography variant="subtitle2">검색결과가 없습니다.</Typography>
                      </Grid>
                  )
                  : (
                    data.map((item, index) => (
                      <Grid key={index} style={{ padding: '10px 0' }} container direction="row" justify="space-around" alignItems="center">
                        <Typography variant="subtitle2"><b>{item.companyName}</b></Typography>
                        <Button 
                          style={{ height: 30, minWidth: 30 }} 
                          color="primary" 
                          variant="contained"
                          onClick={() => handleChoice(item)}
                        >선택</Button>
                      </Grid>
                    ))
                  )
              }
              
              
            </div>
          </Grid>
          
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default inject("cmStore", "commonStore")(observer(CompanyList));