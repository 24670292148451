import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';

import ButtonAddIcon from '../common/button/ButtonAddIcon';

function Title({history}) {

  const handleClick = (path) => {
    history.push(path);
  }
  return (
    <Grid container justify="space-between" alignItems="center" className="titleArea">
      <Grid item><Typography variant="h1">접수정보</Typography></Grid>
      <Grid item className="buttonArea">
        {/* <ButtonAddIcon onClick={() => handleClick('/companyreg')}>기업등록</ButtonAddIcon> */}
        <ButtonAddIcon onClick={() => handleClick('/companymanagerreg')}>기업담당자 등록</ButtonAddIcon>
      </Grid>
    </Grid>
  );
}

export default withRouter(Title);