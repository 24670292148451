import moment from 'moment';
import _ from 'lodash';

let codeList = {};

export const setCodeList = (codes) => codeList = codes;
// lnb 상세 페이지 마크 표시
export const lnbNaviMark = (path, domain) => {
  return domain.indexOf(path) !== -1;
}

/**
 * 날짜 그룹 버튼 날짜 세팅
 * @param value 날짜 type {
 *  1M: 1개월, 2M: 2개월, 3M: 3개월, 6M: 6가월, 1y: 1년
 * }
 */
export const dateGroupSet = (value) => {
  let result = new Date();
  const temp = value.split('');
  const date = temp[0];
  const type = temp[1];
  if (!temp || temp.length === 0) return result;

  result = new Date(moment().subtract(Number(date), type).format())
  return result;
};

//금액 콤마
export const numberComma = (num) => {
  if (num) {
    return String(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '0';
  }
};

/**
 * 공통 로딩바
 * @param {[boolean]} is [ true: 로딩바 on, false: 로딩바 off ]
 */
export const isLoadingBar = (is) => {
  const isShow = is ? '' : 'display:none !important';
  if (typeof document === 'undefined' || document === null) return;
  const dom = document.getElementById('progress');
  if (dom) {
    dom.style.cssText = isShow;
    // dom.style.cssText = 'display:none !important';
    // dom.setAttribute('style', 'display:none !important');
  }
};

/**
 * 이메일 형식 체크
 */
export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

/**
 * 휴대번호 형식 체크
 */

export const validatePhone = phone => {
  var re = /^0[0-9]{1,2}[0-9]{3,4}[0-9]{4}$/;
  return re.test(phone);
};

/**
 * 신용카드 번호 4-4-4-4 패턴 체크
 */
export const validateCard = (card) => {
  var re = /^[0-9]{4}[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}$/;
  return re.test(card);
}

/**
 * 테이블 목록 번호
 */
export const pagingRowNum = (total, size, page, idx) => {
  return total - (page > 1 ? ((page - 1) * size) + idx : idx);
}

/**
 * 코드 값 
 */
export const codeTitle = (key, code) => {
  const object = _.find(codeList[key], function(item) {
    return item.code === code;
  });

  if (!object) return "";
  return object.title;
}

export const valueCheck = (params) => {
  if (params === null || typeof params == 'undefined' || params === '' || params === undefined) {
    return true;
  } else {
    return false;
  }
};

export const text = (value) => {
  if (!value || valueCheck(value)) return ''
  else return value;
}

export const EmailRegExp = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const PwdRegExp = (pwd) => {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()_+])[A-Za-z\d~!@#$%^&*()_+]{4,16}$/.test(pwd);
}

export const PhoneRegExp = (phone) => {
  return /^0[0-9]{1,2}[0-9]{3,4}[0-9]{4}$/.test(phone) || /^0[0-9]{1,2}-[0-9]{3,4}-[0-9]{4}$/.test(phone);
}